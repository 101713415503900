import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import Root from "./store/root";

declare global {
  interface Window { FB: any; }
}
interface WindowWithFB extends Window {
  FB: {
    init(params: {
      appId: string;
      cookie: boolean;
      xfbml: boolean;
      version: string;
    }): void;
  };
  fbAsyncInit(): void;
}

async function initFacebookSdk() {
  const REACT_APP_FACEBOOK_APP_ID = "314930319788683"; // Set your Facebook App ID here

  // wait for facebook sdk to initialize before starting the react app
  await new Promise<void>((resolve) => {
    (window as unknown as WindowWithFB).fbAsyncInit = function () {
      window.FB.init({
        appId: REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v11.0'
      });

      // resolve the promise when facebook sdk has loaded
      resolve();
    };

    // load facebook sdk script
        // load facebook sdk script
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s) as HTMLScriptElement;
          if (d.getElementById(id)) {
            return;
          }
          js.id = id;
          js.src = `https://connect.facebook.net/en_US/sdk.js`; // Use en_US or adjust as needed
          fjs.parentNode!.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');

      });
      
}

initFacebookSdk().then(() => {
  ReactDOM.render(
    <Root>
      <App />
    </Root>,
    document.getElementById("root")
  );
});
