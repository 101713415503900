import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import client from "../../../applo";
import { GET_CVS } from "../../../query";
import { getCVTemplate } from "../../../services/profile-service";
import { useMobile } from "../../../store/providers/mobile-to-desktop-change.provider";
import '../../../index.css'; // Import your global CSS
import "./CVOnboarding.css"; // Import the external CSS file for this component

const { Title, Paragraph } = Typography;

const CVOnboarding: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CVS, { client: client });
  const isMobile = useMobile();
  const [images, setImages] = useState<any[]>([]);

  const fetchImages = async () => {
    setImages([
      { path: 'cvtemp1.jpeg', id: 1 },
      { path: 'cvtemp2.jpeg', id: 2 },
      { path: 'cvtemp3.jpeg', id: 3 },
      { path: 'cvtemp4.jpeg', id: 4 },
      { path: 'cvtemp5.jpeg', id: 5 },
    ]);
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const onChooseTemplate = (id: number) => () => {
    if (!id) return;
    navigate(`/cv-gen/${id}`);
  };

  const galleryRef = useRef<HTMLDivElement>(null);

  const scrollToLeft = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollToRight = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="main">
      <Row justify="center" style={{ marginTop: '30px',padding:'0 30px' }}>
        <Col span={24}>
          <div className="carousel-container">
            <LeftOutlined className="arrow left-arrow" onClick={scrollToLeft} />
            <div className="gallery-wrap" ref={galleryRef}>
              {images.map((img, index) => (
                <div className="card" key={img.id}>
                  <a href={`/#/cv-gen/${img.id}`}>
                    <img
                      key={index}
                      className="carousel-image"
                      src={getCVTemplate(img.path)}
                      alt={img.label ?? "CV template"}
                      draggable={false}
                      style={{ width: isMobile ? '90%' : '300px' }}
                    />
                  </a>
                  <Button
                    className="button"
                    type="primary"
                    onClick={onChooseTemplate(img.id)}
                  >
                    Select this Template
                  </Button>
                </div>
              ))}
            </div>
            <RightOutlined className="arrow right-arrow" onClick={scrollToRight} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CVOnboarding;
