import React, { FC, useState, useEffect } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined, GlobalOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { languages } from "../constants/languages"; // Adjust the import according to your project structure

interface LanguageSelectorProps {
  onLanguageChange?: (lng: string) => void;
}

const LanguageSelector: FC<LanguageSelectorProps> = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('lanCode');
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleMenuClick = (e: any) => {
    const selectedLanguage = e.key;
    onLanguageChange?.(selectedLanguage);
    localStorage.setItem('lanCode', selectedLanguage); // Update localStorage key
    setOpen(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {languages.map((lang) => (
        <Menu.Item key={lang.code} icon={<span>{lang.icon}</span>}>
          {lang.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} onVisibleChange={(flag) => setOpen(flag)} visible={open}>
      <Button icon={<GlobalOutlined />} >
        {languages.find(lang => lang.code === localStorage.getItem('lanCode'))?.text}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default LanguageSelector;
