import React, { useEffect, useState } from 'react';
import './DownloadCV.css';
import CVTemplate1 from './cvTemplate1';
import CVTemplate2 from './CVTemplate2';
import CVTemplate3 from './CVTemplate3';
import CVTemplate4 from './CvTemplate4';

const A4_HEIGHT = 1122; // A4 height in pixels for 96 DPI

const DownloadCV: React.FC<{ styleID?: number; scale?: number; isModal?: boolean }> = ({ styleID, scale = 1, isModal = false }) => {
  const [savedData, setSavedData] = useState<any>({});
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    const cvData = JSON.parse(localStorage.getItem('cvData') || '{}');
    setSavedData(cvData);

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      setSavedData(updatedData);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (savedData) {
      calculatePages(savedData);
    }
  }, [savedData]);

  const calculatePages = (data: any) => {
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    container.style.width = `${210 * scale}mm`; // Scaled A4 width
    container.style.height = 'auto';
    container.style.transform = `scale(${scale})`;
    container.style.transformOrigin = 'top left';
    container.style.visibility = 'hidden';
    document.body.appendChild(container);

    container.innerHTML = renderCV(styleID, data) as any;

    const totalHeight = container.offsetHeight;
    const numberOfPages = Math.ceil(totalHeight / (A4_HEIGHT * scale));

    const pagesArray = [];
    for (let i = 0; i < numberOfPages; i++) {
      pagesArray.push(renderCV(styleID, data, i));
    }

    setPages(pagesArray);
    document.body.removeChild(container);
  };

  const renderCV = (id: any, data: any, pageIndex: number = 0) => {
    const styles = {
      pageBreakBefore: pageIndex > 0 ? 'always' : 'auto',
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
    };
    switch (id) {
      case 1:
        return <CVTemplate1 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 2:
        return <CVTemplate2 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 3:
        return <CVTemplate3 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 4:
        return <CVTemplate4 savedData={data} pageIndex={pageIndex} styles={styles} />;
      default:
        return <CVTemplate1 savedData={data} pageIndex={pageIndex} styles={styles} />;
    }
  };

  return (
    <div id="cv-preview" className={isModal ? "cv-modal-preview" : "cv-preview-container"}>
      {pages.map((page, index) => (
        <div key={index} className="cv-page" style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}>
          {page}
        </div>
      ))}
    </div>
  );
};

export default DownloadCV;
