import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { REGISTER_USER_MUTATION } from "../../query";
import { Typography, Form, Input, Button, notification } from "antd";
import { GoogleOutlined, FacebookOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { FULL_NAME_REGEX, PASSWORD_REGEX } from "../../constants/regex";

import client from "../../applo";
import translateText from "../../constants/translator";
import "./RegisterPage.css"; // Import the CSS file

import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Credentials, ErrorHelps, InputField } from "../../utils/auth-types";

const { Title } = Typography;

type RegisterProps = {
  viaCV?: boolean;
  defaults?: any;
  details?: any;
};

const RegisterPage: FC<RegisterProps> = ({ viaCV, defaults, details }) => {
  const [registerUser] = useMutation(REGISTER_USER_MUTATION, { client });
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const langCode = localStorage.getItem("lanCode") || "en";
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        "Register",
        "Display Name",
        "Please enter your display name",
        "Only letters are allowed and need to be at least 4 characters long",
        "Email",
        "Please enter your email",
        "Please enter a valid email",
        "Enter your Email",
        "Password",
        "Please enter your password",
        "Password need to be at least 8 characters long with a number and a special character",
        "Enter your password",
        "Confirm Password",
        "Please confirm your password",
        "Both passwords didn't match",
        "Enter your password again",
        "Sign up with Google",
        "Sign up with Facebook",
        "Back to login",
        "Success",
        "You've successfully registered.",
        "Error",
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };

    fetchTranslations();
  }, [langCode]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onFinish = async (values: any) => {
    try {
      await createUserWithEmailAndPassword(auth, values.email, values.password).then(
        (userCredential) => {
          const user = userCredential.user;
          localStorage.setItem("user", JSON.stringify(user));
          notification.success({
            message: translatedTexts[19],
            description: translatedTexts[20],
          });
          navigate("/login");
        }
      );
    } catch (error: any) {
      notification.error({
        message: translatedTexts[21],
        description: error.message,
      });
    }
  };

  return (
    <div className="register-container2">
      <div className="register-content">
        <Title level={2} className="register-title">
          {translatedTexts[0]}
        </Title>
        <Form layout="vertical" onFinish={onFinish} className="register-form">
          <Form.Item
            name="displayName"
            label={translatedTexts[1]}
            rules={[
              { required: true, message: translatedTexts[2] },
              { pattern: FULL_NAME_REGEX, message: translatedTexts[3] },
            ]}
            className="register-form-item"
          >
            <Input placeholder={translatedTexts[7]} />
          </Form.Item>
          <Form.Item
            name="email"
            label={translatedTexts[4]}
            rules={[
              { required: true, message: translatedTexts[5] },
              { type: "email", message: translatedTexts[6] },
            ]}
            className="register-form-item"
          >
            <Input placeholder={translatedTexts[7]} />
          </Form.Item>
          <Form.Item
            name="password"
            label={translatedTexts[8]}
            rules={[
              { required: true, message: translatedTexts[9] },
              { pattern: PASSWORD_REGEX, message: translatedTexts[10] },
            ]}
            className="register-form-item password-input"
          >
            <Input
              type={showPassword ? "text" : "password"}
              placeholder={translatedTexts[11]}
              required
            />
            <span className="toggle-password2" onClick={togglePasswordVisibility}>
              {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={translatedTexts[12]}
            dependencies={["password"]}
            rules={[
              { required: true, message: translatedTexts[13] },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(translatedTexts[14]));
                },
              }),
            ]}
            className="register-form-item password-input"
          >
            <Input
              type={showConfirmPassword ? "text" : "password"}
              placeholder={translatedTexts[15]}
              required
            />
            <span className="toggle-password2" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          </Form.Item>
        
            <Button
              style={{
                backgroundColor: "#1F6DB4",
              }}
              type="default"
              htmlType="submit"
              className="register-button2"
            >
              {translatedTexts[0]}
            </Button>
     
        </Form>
        <div className="buttonList">
        <Button
          type="default"
          block
          icon={<GoogleOutlined />}
          className="social-button2 google"
        >
          {translatedTexts[16]}
        </Button>
        <Button
          type="default"
          block
          icon={<FacebookOutlined />}
          className="social-button2 facebook"
        >
          {translatedTexts[17]}
        </Button>
        </div>
        <div className="back-to-login">
          <Link to="/login">
            <Typography>{translatedTexts[18]}</Typography>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;




export const getInputs = (state: Credentials | null) => {
  const inputs: InputField[] = [
    {
      name: "displayName",
      validator: (name: any) => FULL_NAME_REGEX.test(name),
      props: {
        placeholder: "Enter your Display Name",
        label: "Display Name",
        required: true,
      },
    },
    {
      name: "email",
      validator: (email: any) => true,
      props: {
        placeholder: "Enter your Email",
        label: "Email",
        required: true,
      },
    },
    {
      name: "password",
      validator: (password: any) => PASSWORD_REGEX.test(password),
      props: {
        placeholder: "Enter your password",
        type: "password",
        required: true,
        label: "Password",
      },
    },
    {
      name: "confirmPassword",
      props: {
        placeholder: "Enter your password again",
        type: "password",
        required: true,
        label: "Confirm Password",
      },
    },
  ];

  return inputs;
};

export const errorHelps: ErrorHelps = {
  full_name: {
    en: "Only Letters are allowed and need to be at least 4 characters long",
  },
  type: {
    en: "Invalid Type",
  },
  number: {
    en: "Entered Number is Invalid",
  },
  password: {
    en: "Password need to be at least 8 characters long with a Number and a special character",
  },
  confirm_password: {
    en: "Both Passwords didn't match",
  },
};