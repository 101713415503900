import React, { FC, useEffect, useState } from "react";
import { Layout, Menu, Button, Avatar, Dropdown, Tooltip, Drawer } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MenuOutlined } from '@ant-design/icons';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase"; // Adjust the path as necessary
import { useSnackbar } from "../../../store/providers/snackbar.provider";
import LanguageSelector from "../../../components/language-selector";
import getLinks from "./nav-links";
import translateText from "../../../constants/translator";
import "./NavBar.css"; // Import the CSS file

const { Header } = Layout;

const NavBar: FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 879);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { addSnack } = useSnackbar();
    const [user, setUser] = useState<any>(null);
    const [language, setLanguage] = useState(localStorage.getItem('lanCode') || 'es');
    const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});

    const handleTranslate = async () => {
        const textsToTranslate = ['profile', 'logout', 'login'];
        const translations = await translateText(textsToTranslate, language);
        const formattedTranslations = {
            profile: formatText(translations[0]),
            logout: formatText(translations[1]),
            login: formatText(translations[2]),
        };
        setTranslatedTexts(formattedTranslations);
    };

    const formatText = (text: string) => {
        return text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    useEffect(() => {
        handleTranslate();
    }, [language]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 879);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [window.innerWidth]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });
        return () => unsubscribe();
    }, []);

    const fullLinks = getLinks('candidate').slice(0, 5); // Assuming '5' is your MAX_NAVBAR_LINKS_VISIBLE
    const postAdLink = user ? (user.role === "employer" ? "/post-ad/post" : "/profile/candidate/edit") : "#";

    const userMenu = (
        <Menu>
            <Menu.Item key="profile">
                <Link to="/profile">{translatedTexts.profile}</Link>
            </Menu.Item>
            <Menu.Item key="logout" onClick={() => {
                auth.signOut().then(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            }}>
                {translatedTexts.logout}
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="header">
            <div className="navbar-container">
                <div className="logo">
                    <Link to="/">
                        <img src="/assets/logo.jpg" alt="jobswomenlogo" />
                    </Link>
                </div>
                <nav>
                    {isMobile ? (
                        <>
                            <Button icon={<MenuOutlined />} onClick={() => setDrawerVisible(true)} />
                            <Drawer
                                title="Menu"
                                placement="right"
                                closable={true}
                                onClose={() => setDrawerVisible(false)}
                                visible={drawerVisible}
                            >
                                <Menu mode="vertical" selectedKeys={[location.pathname]}>
                                    {fullLinks.map(link => (
                                        <Menu.Item key={link.link}>
                                            <Link to={link.link}>{formatText(link.name ?? "")}</Link>
                                        </Menu.Item>
                                    ))}
                                    <Menu.Item key="language-selector">
                                        <LanguageSelector onLanguageChange={setLanguage} />
                                    </Menu.Item>
                                    {user ? (
                                        <Menu.Item key="user-menu">
                                            <Dropdown overlay={userMenu} trigger={['click']}>
                                                <Tooltip title={user.email}>
                                                    <Avatar style={{ cursor: 'pointer' }} />
                                                </Tooltip>
                                            </Dropdown>
                                        </Menu.Item>
                                    ) : null}
                                </Menu>
                            </Drawer>
                        </>
                    ) : (
                        <Menu mode="horizontal" className="menu-horizontal" selectedKeys={[location.pathname]}>
                            {fullLinks.map(link => (
                                <Menu.Item key={link.link}>
                                    <Link to={link.link}>{formatText(link.name ?? "")}</Link>
                                </Menu.Item>
                            ))}
                            <Menu.Item key="language-selector">
                                <LanguageSelector onLanguageChange={setLanguage} />
                            </Menu.Item>
                            {user ? (
                                <Menu.Item key="user-menu">
                                    <Dropdown overlay={userMenu} trigger={['click']}>
                                        <Tooltip title={user.email}>
                                            <Avatar style={{ cursor: 'pointer' }} />
                                        </Tooltip>
                                    </Dropdown>
                                </Menu.Item>
                            ) : (
                                <Menu.Item key="/login">
                                    <Link to="/login">{translatedTexts.login}</Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    )}
                </nav>
            </div>
        </Header>
    );
};

export default NavBar;
