export const languages = [
  { text: "English", code: "en", icon: "En" },
  { text :"Hindi", code: "hi", icon: "Hi" },
  { text:"arabic", code: "ar", icon: "Ar" },
  { text:"Urdu", code: "ur", icon: "Ur" },
  {
    text:"chinese",
    code: "zh",
    icon: "Zh",

  },
  {
    text:"German",
    code: "de",
    icon: "De",
  },
  {
    text:"Italian",
    code: "it",
    icon: "It",
  },
  {
    text:"Spanish",
    code: "es",
    icon: "Es",
  },{
    text:"Bengali",
    code: "bn",
    icon: "Bn",
  },{
    text:"Portuguese",
    code: "pt",
    icon: "Pt",
  },
  {
    text:"Lahnda",
    code: "lah",
    icon: "Lah",
  },
  {
    text:"Russian",
    code: "ru",
    icon: "Ru",
  }
] as const;

export type Lang = typeof languages[number];

export type Language = {
  [code in Lang["code"]]?: string;
};
