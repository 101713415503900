import React from 'react';
import { Card, Divider } from 'antd';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, LinkOutlined, LinkedinOutlined, GithubOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import './CVTemplate1.css';

interface CVTemplate1Props {
  savedData?: {
    credentials?: {
      firstName?: string;
      lastName?: string;
      title?: string;
      email?: string;
      phone?: string;
      address?: string;
      linkedIn?: string;
      github?: string;
      website?: string;
      drivingLicense?: string;
      nationality?: string;
      avatar?: string;
      city?: string;
      county?: string;
      postcode?: string;
      [key: string]: any;
    };
    workExperience?: {
      jobTitle: string;
      employer: string;
      startDate: string;
      endDate: string;
      description: string;
      currentlyWorkHere: boolean;
      city?: string;
      county?: string;
    }[];
    educationHistory?: {
      institution: string;
      qualification: string;
      fieldOfStudy?: string;
      honours?: string;
      city?: string;
      startDate: string;
      endDate?: string;
      currentlyStudyingHere?: boolean;
      description?: string;
    }[];
    skills?: string[];
    summary?: string;
    picture?: string | null;
    references?: boolean;
    languages?: {
      name: string;
      level: string;
      additionalInfo?: string;
    }[];
  };
  pageIndex: number;
  styles?: any;
}

const capitalize = (str: string | undefined) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const getStarRating = (level: string) => {
  const stars:any = {
    Beginner: 1,
    Intermediate: 3,
    Advanced: 4,
    Fluent: 5,
    Native: 5,
  };

  const rating = stars[level] || 0;
  return (
    <>
      {[...Array(5)].map((_, index) => (
        index < rating ? <StarFilled key={index} /> : <StarOutlined key={index} />
      ))}
    </>
  );
};

const CVTemplate1: React.FC<CVTemplate1Props> = ({ savedData, pageIndex, styles }) => {
  const fullName = `${capitalize(savedData?.credentials?.firstName ?? '')} ${capitalize(savedData?.credentials?.lastName ?? '')}`;
  const avatarUrl = savedData?.credentials?.avatar;

  return (
    <div className="cv-template1" style={styles}>
      <div className="cv-header">
        {avatarUrl && <img src={avatarUrl} alt="Profile" className="cv-picture" />}
        <div className="header-text">
          <h1>{fullName}</h1>
          <h2>{savedData?.credentials?.title}</h2>
        </div>
      </div>
      <div className="cv-content">
        <div className="cv-sidebar">
          <div className="contact-section">
            <h2>Contact</h2>
            {savedData?.credentials?.email && (
              <div className="contact-item">
                <MailOutlined />
                <p>{savedData.credentials.email}</p>
              </div>
            )}
            {savedData?.credentials?.phone && (
              <div className="contact-item">
                <PhoneOutlined />
                <p>{savedData.credentials.phone}</p>
              </div>
            )}
            {(savedData?.credentials?.address || savedData?.credentials?.city || savedData?.credentials?.county || savedData?.credentials?.postcode) && (
              <div className="contact-item">
                <EnvironmentOutlined />
                <span>
                  {[savedData?.credentials?.address, savedData?.credentials?.city, savedData?.credentials?.county, savedData?.credentials?.postcode]
                    .filter(Boolean)
                    .join(', ')}
                </span>
              </div>
            )}
            {savedData?.credentials?.website && (
              <div className="contact-item">
                <LinkOutlined />
                <p>{savedData.credentials.website}</p>
              </div>
            )}
            {savedData?.credentials?.linkedIn && (
              <div className="contact-item">
                <LinkedinOutlined />
                <p>{savedData.credentials.linkedIn}</p>
              </div>
            )}
            {savedData?.credentials?.github && (
              <div className="contact-item">
                <GithubOutlined />
                <p>{savedData.credentials.github}</p>
              </div>
            )}
            {Object.keys(savedData?.credentials || {}).filter(key => !['firstName', 'lastName', 'title', 'email', 'phone', 'address', 'city', 'county', 'postcode', 'avatar', 'website', 'linkedIn', 'github'].includes(key)).map((field) => (
              <div key={field} className="contact-item">
                <LinkOutlined />
                <p>{savedData?.credentials?.[field]}</p>
              </div>
            ))}
          </div>
          <Divider />
          <div className="skills-section">
            <h2>Skills</h2>
            <ul>
              {savedData?.skills?.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
          <Divider />
          <div className="languages-section">
            <h2>Languages</h2>
            {savedData?.languages?.map((language, index) => (
              <div key={index} className="language-item">
                <strong>{language.name}</strong>
                <div className="language-stars">{getStarRating(language.level)}</div>
                {language.additionalInfo && <p>{language.additionalInfo}</p>}
              </div>
            ))}
          </div>
          <Divider />
          <div className="education-section">
            <h2>Education Background</h2>
            {savedData?.educationHistory?.map((education, index) => (
              <div key={index} className="education-item">
                <h3>
                  {education.qualification} 
                  ` - {education.fieldOfStudy} 
                  {education.honours && ` (${education.honours})`}
                </h3>
                <p>{education.institution} ,{education.city}</p>
              
                
                <p>{education.startDate} - {education.currentlyStudyingHere ? 'Present' : education.endDate}</p>
                <div className="education-description" dangerouslySetInnerHTML={{ __html: education.description ?? "" }} />
              </div>
            ))}
          </div>
        </div>
        <div className="cv-main-content">
          <div className="about-section">
            <h2>About Me</h2>
            <div className="about-description" dangerouslySetInnerHTML={{ __html: savedData?.summary ?? "" }} />
          </div>
          <Divider />
          <div className="experience-section">
            <h2>Professional Experience</h2>
            {savedData?.workExperience?.map((work, index) => (
              <div key={index} className="experience-item">
                <h3>{work.jobTitle}</h3>
                <p>{work.employer}</p>
                <p> 
                  {work.city && work.county  ? work.city+',' +work.county : ''}
                </p>
                <p>{work.startDate} - {work.currentlyWorkHere ? 'Present' : work.endDate}</p>
                <div className="experience-description" dangerouslySetInnerHTML={{ __html: work.description }} />
              </div>
            ))}
          </div>
          <Divider />
          <div className="achievements-section">
            <h2>References</h2>
            <p>{savedData?.references ? 'Available upon request' : 'Not available'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVTemplate1;
