import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
const firebaseConfig = {
  apiKey: "AIzaSyAZS3_cvXVp3uYC9WxXD8aaU9W4p1uSkIo",
  authDomain: "ukweb-3e8c3.firebaseapp.com",
  databaseURL: "https://ukweb-3e8c3-default-rtdb.firebaseio.com",
  projectId: "ukweb-3e8c3",
  storageBucket: "ukweb-3e8c3.appspot.com",
  messagingSenderId: "115324582989",
  appId: "1:115324582989:web:afea82d27831d7c6adb004",
  measurementId: "G-ZG7EBQPFVX"
};

const app1 = initializeApp(firebaseConfig);
const auth = getAuth(app1);
const db = getFirestore(app1);
const storage = getStorage(app1);
const functions = getFunctions(app1);

export { auth, db,storage,functions };
export default app1; // Export the app instance
