import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, InputNumber, Input, notification, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import translateText from '../../constants/translator';

const RegistrationFees: React.FC = () => {
  const [fees, setFees] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredFees, setFilteredFees] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchFees();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add Registration Fee',
      'Edit Registration Fee',
      'Description',
      'Amount ($)',
      'Action',
      'Fee deleted successfully',
      'Fee updated successfully',
      'Fee added successfully',
      'An error occurred while saving the fee',
      'Please input the description!',
      'Please input the amount!',
      'Save',
      'Update',
      'Search...',
      'Rows per page:',
      'Edit',
      'Delete'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addRegistrationFee',
      'editRegistrationFee',
      'description',
      'amount',
      'action',
      'feeDeleted',
      'feeUpdated',
      'feeAdded',
      'errorSavingFee',
      'descriptionRequired',
      'amountRequired',
      'save',
      'update',
      'searchPlaceholder',
      'rowsPerPage',
      'edit',
      'delete'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchFees = async () => {
    const snapshot = await getDocs(collection(db, "registrationFees"));
    const list = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setFees(list);
    setFilteredFees(list);
  };

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (item: any) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleDelete = async (itemId: string) => {
    await deleteDoc(doc(db, "registrationFees", itemId));
    notification.success({ message: translatedTexts.feeDeleted });
    fetchFees();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const existingItem = fees.find(item => item.description === values.description && item.id !== (editingItem ? editingItem.id : null));
      if (existingItem) {
        notification.error({ message: translatedTexts.duplicateDescription, description: translatedTexts.duplicateDescriptionMessage });
        return;
      }

      if (editingItem) {
        await updateDoc(doc(db, "registrationFees", editingItem.id), values);
        notification.success({ message: translatedTexts.feeUpdated });
      } else {
        await addDoc(collection(db, "registrationFees"), values);
        notification.success({ message: translatedTexts.feeAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchFees();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSavingFee });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = fees.filter((item) =>
      item.description.toLowerCase().includes(value) ||
      item.amount.toString().includes(value)
    );

    setFilteredFees(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addRegistrationFee}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredFees}
        columns={[
          { title: translatedTexts.description, dataIndex: 'description', key: 'description' },
          { title: translatedTexts.amount, dataIndex: 'amount', key: 'amount', render: (amount) => `$${amount}` },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, item) => (
              <>
                <Button onClick={() => handleEdit(item)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(item.id)}>
                  {translatedTexts.delete}
                </Button>
              </>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingItem ? translatedTexts.editRegistrationFee : translatedTexts.addRegistrationFee}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okText={editingItem ? translatedTexts.update : translatedTexts.save}
        okButtonProps={{ style: { backgroundColor: '#0070B0', borderColor: '#0070B0' } }}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="description" label={translatedTexts.description} rules={[{ required: true, message: translatedTexts.descriptionRequired }]}>
            <Input />
          </Form.Item>
          <Form.Item name="amount" label={translatedTexts.amount} rules={[{ required: true, message: translatedTexts.amountRequired }]}>
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RegistrationFees;
