import React, { FC } from "react";
import { Layout, Divider } from "antd";
import NavBar from "./common/nav-bar";
import Footer from "./common/footer";
import "./mainLayout.css";
const { Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
}

const MainLayout: FC<LayoutProps> = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: 'white' }}>
      <NavBar />
    <div className="main-content">
          {children}
        </div>
        <Divider style={{ width: '80%', marginTop: '40px' }} />
        <Footer />
    
    </Layout>
  );
};

export default MainLayout;
