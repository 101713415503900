import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Typography, Checkbox, Card, message } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles for ReactQuill
import './Step2.css';
import RightPanel from './ExpertTips';

const { Title, Paragraph } = Typography;

interface Step2Props {
  handleBack: () => void;
  navigateSubSteps: (step: number) => void;
  currentSubStep: number;
  results: string[]; // Initial search results
}

const Step2: React.FC<Step2Props> = ({ handleBack, navigateSubSteps, currentSubStep, results }) => {
  const [form] = Form.useForm();
  const [currentWork, setCurrentWork] = useState<any>({});
  const [editorContent, setEditorContent] = useState('');
  const [workExperience, setWorkExperience] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any>(results);

  useEffect(() => {
    const storedData = localStorage.getItem('cvData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const experienceArray = Array.isArray(parsedData.workExperience) ? parsedData.workExperience : [];
      setWorkExperience(experienceArray);
      setSearchResults(results);
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      const experienceArray = Array.isArray(updatedData.workExperience) ? updatedData.workExperience : [];
      setWorkExperience(experienceArray);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [results]);

  const updateLocalStorage = (key: string, value: any) => {
    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {}, workExperience: [] };
    if (key === 'workExperience' && !Array.isArray(value)) {
      value = [value]; // Ensure value is an array
    }
    cvData[key] = value;
    localStorage.setItem('cvData', JSON.stringify(cvData));
    window.dispatchEvent(new Event('storage'));
  };

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const updatedCurrentWork = { ...currentWork, ...allValues };
    setCurrentWork(updatedCurrentWork);

    // Update work experience array with the new current work object
    const updatedWorkExperience = [...workExperience];
    const index = updatedWorkExperience.findIndex(
      (work) => work.jobTitle === currentWork.jobTitle && work.employer === currentWork.employer
    );
    if (index !== -1) {
      updatedWorkExperience[index] = updatedCurrentWork;
    } else {
      updatedWorkExperience.push(updatedCurrentWork);
    }

    setWorkExperience(updatedWorkExperience);
    updateLocalStorage('workExperience', updatedWorkExperience);
  };

  const handleDateChange = (e: any, fieldName: string) => {
    const formattedDate = e.target.value;
    form.setFieldsValue({ [fieldName]: formattedDate });
    const updatedCurrentWork = { ...currentWork, [fieldName]: formattedDate };
    setCurrentWork(updatedCurrentWork);

    // Update work experience array with the new current work object
    const updatedWorkExperience = [...workExperience];
    const index = updatedWorkExperience.findIndex(
      (work) => work.jobTitle === currentWork.jobTitle && work.employer === currentWork.employer
    );
    if (index !== -1) {
      updatedWorkExperience[index] = updatedCurrentWork;
    } else {
      updatedWorkExperience.push(updatedCurrentWork);
    }

    setWorkExperience(updatedWorkExperience);
    updateLocalStorage('workExperience', updatedWorkExperience);
  };

  const handleCurrentlyWorkHereChange = (e: any) => {
    form.setFieldsValue({ endDate: null });
    handleFieldChange({ currentlyWorkHere: e.target.checked }, { ...form.getFieldsValue(), currentlyWorkHere: e.target.checked });
  };

  const handleWorkSubmit = (values: any) => {
    if (!values.jobTitle || !values.employer || !values.city || !values.county || !values.startDate || !editorContent.trim()) {
      message.error('Please fill in all required fields and add a description.');
      return;
    }

    const newWorkExperience = {
      ...values,
      description: `<ul>${editorContent}</ul>`,
      startDate: values.startDate,
      endDate: values.endDate,
    };
    const updatedWorkExperience = [...workExperience, newWorkExperience];
    setWorkExperience(updatedWorkExperience);
    updateLocalStorage('workExperience', updatedWorkExperience);

    setCurrentWork({});
    form.resetFields();
    setEditorContent('');
    navigateSubSteps(2); // Navigate to summary step after submitting work
  };

  const editWorkExperience = (index: number) => {
    const work = workExperience[index];
    if (work) {
      form.setFieldsValue({
        ...work,
        startDate: work.startDate,
        endDate: work.currentlyWorkHere ? null : work.endDate,
      });
      setEditorContent(work.description ? work.description.replace(/<\/?ul>/g, '') : ''); // Remove <ul> tags for editing
      setCurrentWork(work);
      navigateSubSteps(0); // Navigate to the first sub-step
    }
  };

  const deleteWorkExperience = (index: number) => {
    const updatedWorkExperience = workExperience.filter((_, i) => i !== index);
    setWorkExperience(updatedWorkExperience);
    updateLocalStorage('workExperience', updatedWorkExperience);
  };

  const handleSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    const filteredResults = results.filter((result) => result.toLowerCase().includes(query));
    setSearchResults(filteredResults);
  };

  const addResponsibilityToEditor = (responsibility: string) => {
    setEditorContent((prevContent) => `${prevContent}<li>${responsibility}</li>`);
  };

  return (
    <>
      {currentSubStep === 0 && (
        <>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleBack} className="back-button">
            Go Back
          </Button>
          <RightPanel step={1}/>
          <Form form={form} layout="vertical" className="custom-form" onFinish={handleWorkSubmit} onValuesChange={handleFieldChange}>
            <Title level={4}>Tell us about your work history</Title>
            <Paragraph>Start with your most recent job and work backwards</Paragraph>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="jobTitle" label="Job title" className="form-item">
                  <Input
                    placeholder="e.g. Job title"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="employer" label="Employer" className="form-item">
                  <Input
                    placeholder="e.g. Employer"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="city" label="City/Town" className="form-item">
                  <Input
                    placeholder="e.g. City/Town"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="county" label="County" className="form-item">
                  <Input
                    placeholder="e.g. County"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="startDate" label="Start date" className="form-item">
                  <Input
                    type="month"
                    placeholder="e.g. Start date"
                    className="custom-input"
                    onChange={(e: any) => handleDateChange(e, 'startDate')}
                    value={currentWork.startDate ? currentWork.startDate : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="endDate" label="End date" className="form-item">
                  <Input
                    type="month"
                    placeholder="e.g. End date"
                    className="custom-input"
                    onChange={(e: any) => handleDateChange(e, 'endDate')}
                    value={currentWork.endDate ? currentWork.endDate : ''}
                    disabled={form.getFieldValue('currentlyWorkHere')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="currentlyWorkHere" valuePropName="checked" className="form-item">
                  <Checkbox onChange={handleCurrentlyWorkHereChange}>I currently work here</Checkbox>
                </Form.Item>
              </Col>
            </Row>

          </Form>
        </>
      )}
      {currentSubStep === 1 && (
        <>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigateSubSteps(0)} className="back-button">
            Go Back
          </Button>
          <RightPanel step ={1} />
          <Row gutter={16}>
            <Col span={24}>
              <Title level={4}>Describe your experience</Title>
              {currentWork?.jobTitle && currentWork?.employer && currentWork?.city && currentWork?.county && currentWork?.startDate && (currentWork?.endDate || currentWork?.currentlyWorkHere) && (
                <>
                  <Paragraph>{currentWork?.jobTitle} | {currentWork?.employer}</Paragraph>
                  <Paragraph>{currentWork?.city}, {currentWork?.county}, {currentWork?.startDate} - {currentWork?.currentlyWorkHere ? 'Present' : currentWork?.endDate}</Paragraph>
                </>
              )}
            </Col>
            <Col span={12}>
              <ReactQuill 
                value={editorContent} 
                onChange={(value) => {
                  setEditorContent(value);
                  const updatedCurrentWork = { ...currentWork, description: `<ul>${value}</ul>` };
                  setCurrentWork(updatedCurrentWork);

                  // Update work experience array with the new current work object
                  const updatedWorkExperience = [...workExperience];
                  const index = updatedWorkExperience.findIndex(
                    (work) => work.jobTitle === currentWork.jobTitle && work.employer === currentWork.employer
                  );
                  if (index !== -1) {
                    updatedWorkExperience[index] = updatedCurrentWork;
                  } else {
                    updatedWorkExperience.push(updatedCurrentWork);
                  }

                  setWorkExperience(updatedWorkExperience);
                  updateLocalStorage('workExperience', updatedCurrentWork);
                }} 
                className="react-quill" 
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link']}
                modules={{
                  toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['link'],
                  ]
                }}
              />
            </Col>
            <Col span={12} className="search-panel">
              <div className="search-header">
                <Title level={5}>Search by job title or keyword</Title>
                <div className="search-input">
                  <Input
                    size="middle"
                    placeholder="Ex: Cashier"
                    onChange={handleSearch}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <Button icon={<SearchOutlined />} />
                </div>
                <Paragraph className="search-tip">Check your spelling or try the popular pre-written examples below. We did not recognize www as a job title.</Paragraph>
              </div>
              <div className="search-results">
                {searchResults?.map((result: any, index: any) => (
                  <div key={index} className="search-result-item">
                    <Button
                      className="search-result-button"
                      icon={<PlusOutlined style={{ color: "white", marginRight: 8, backgroundColor: '#0070B8' }} />}
                      onClick={() => addResponsibilityToEditor(result)}
                    >
                      {result}
                    </Button>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          
        </>
      )}
      {currentSubStep === 2 && (
        <>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigateSubSteps(1)} className="back-button">
            Go Back
          </Button>
          <RightPanel step={1} />
          <Title level={4}>Work history summary</Title>
          <Row gutter={16}>
            {workExperience.map((work: any, index: any) => (
              <Col span={24} key={index} className="work-summary-col">
                <Card className="work-summary-card">
                  <div className="work-summary-content">
                    <div className="work-summary-header">
                      <div className="work-summary-info">
                        <span className="work-summary-index">{index + 1}</span>
                        <span className="work-summary-title">{work.jobTitle}, {work.employer}</span>
                        <span className="work-summary-location">{work.city}, {work.county} | {work.startDate} - {work.currentlyWorkHere ? 'Present' : work.endDate}</span>
                      </div>
                      <div className="work-summary-actions">
                        <Button icon={<EditOutlined />} onClick={() => editWorkExperience(index)} />
                        <Button icon={<DeleteOutlined />} onClick={() => deleteWorkExperience(index)} />
                      </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: work.description }}></div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Button type="dashed" onClick={() => {
            navigateSubSteps(0);
            form.resetFields();
            setCurrentWork({});
          }}>+ Add another position</Button>
        </>
      )}
    </>
  );
};

export default Step2;
