import React from 'react';
import { Layout } from 'antd';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const { Content } = Layout;

const AdminPanel: React.FC = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/admin-login" />;
  }

  return (
    <Layout style={{ minHeight: '100vh', width:'100%', backgroundColor:'white' }}>
      <Content style={{  background: '#fff', flex: 1 }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AdminPanel;
