import React, { Suspense, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CircularProgress, CssBaseline } from "@mui/material";

// routes
import RouteList from "./routes/route-list";
import RouteElement from "./routes/route-element";
import AdminLogin from "./routes/admin";
import CookieWarn from "./components/warnings/cookie";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { I18nextProvider } from "react-i18next";
import i18n from "./constants/i18n";
import "./index.css";
import { AuthProvider } from "./hooks/useAuth";
function App() {
  const location = useLocation();

  useEffect(() => {
    document.body.scrollIntoView();
  }, [location.pathname, location.search]);
    console.log(RouteList);
  return (
<AuthProvider>
      <CssBaseline />
      <CookieWarn />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            {RouteList.map((item) => (
              <Route
                {...item.props}
                key={item.path}
                path={item.path}
                element={
                  <RouteElement element={item.element} layout={item.layout} />
                }
              >
                {item.innerRoutes &&
                  item.innerRoutes.map((innerItem) => (
                    <Route
                      path={innerItem.path}
                      element={innerItem.element}
                      {...item.props}
                      key={item.path}
                    />
                  ))}
              </Route>
            ))}
          </Routes>
        </Suspense>
      </I18nextProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
}

export default App;
