import React, { FC, useState, useEffect, useCallback } from "react";
import { Button, Row, Col, Typography, Input } from "antd";
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Step4.css";
import RightPanel from "./ExpertTips";

const { Title, Paragraph } = Typography;

interface Step4Props {
  handleBack: () => void;
  searchResults: string[];
  handleSearch: (e: any) => void;
}

// Custom Module for Quill to automatically format new lines as bullets
const CustomQuillModules = {
  toolbar: [
    [{ 'list': 'bullet' }],
    ['bold', 'italic', 'underline'],
  ],
};

const Step4: FC<Step4Props> = ({ handleBack, searchResults, handleSearch }) => {
  const [skills, setSkills] = useState<string[]>([]);
  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    const storedData = localStorage.getItem("cvData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const skillsArray = Array.isArray(parsedData.skills) ? parsedData.skills : [];
      setSkills(skillsArray);
      setEditorContent(skillsArray.map((skill: any) => `<p>• ${skill}</p>`).join(''));
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem("cvData") || "{}");
      const skillsArray = Array.isArray(updatedData.skills) ? updatedData.skills : [];
      setSkills(skillsArray);
      setEditorContent(skillsArray.map((skill: any) => `<p>• ${skill}</p>`).join(''));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const updateLocalStorage = useCallback((key: string, value: any) => {
    const storedData = localStorage.getItem("cvData");
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {}, skills: [] };
    cvData[key] = value;
    localStorage.setItem("cvData", JSON.stringify(cvData));
    window.dispatchEvent(new Event("storage"));
  }, []);

  const addSkill = useCallback((skill: string) => {
    if (!skills.includes(skill)) {
      const updatedSkills = [...skills, skill];
      setSkills(updatedSkills);
      updateLocalStorage("skills", updatedSkills);
      setEditorContent(updatedSkills.map(skill => `<p>• ${skill}</p>`).join(''));
    }
  }, [skills, updateLocalStorage]);

  const addSkillToEditor = useCallback((skill: string) => {
    setEditorContent((prevContent) => {
      const updatedContent = `${prevContent}<p>• ${skill}</p>`;
      addSkill(skill);
      return updatedContent;
    });
  }, [addSkill]);

  const handleEditorChange = useCallback((content: string) => {
    setEditorContent(content);
    const updatedSkills = content
      .split(/<p>•\s*|\n•\s*/i)
      .filter(Boolean)
      .map(skill => skill.replace('</p>', '').trim());
    
    if (updatedSkills.join(',') !== skills.join(',')) {
      setSkills(updatedSkills);
      updateLocalStorage("skills", updatedSkills);
    }
  }, [skills, updateLocalStorage]);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setEditorContent((prevContent) => `${prevContent}<p>• </p>`);
    }
  };

  return (
    <>
      <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleBack} className="back-button">
        Go Back
      </Button>
      <RightPanel step={3} />
      <div className="page-title-wrap">
        <Title level={4} className="page-title">Highlight relevant skills for the job you want</Title>
        <Paragraph className="sub-title">Start with our expert recommendations by job title or pull the skills required from the job description</Paragraph>
      </div>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <ReactQuill
            value={editorContent}
            onChange={handleEditorChange}
            onKeyPress={handleKeyPress}
            modules={CustomQuillModules}
            formats={['bold', 'italic', 'underline', 'list', 'bullet']}
          />
        </Col>
        <Col span={24} md={12}>
          <Title level={5}>Search by job title or keyword</Title>
          <div className="search-container">
            <Input size="middle" placeholder="Ex: Cashier" onChange={handleSearch} className="search-input" />
            <Button icon={<SearchOutlined />} />
          </div>
          <div className="search-results-container">
            {searchResults.map((result: string, index: number) => (
              <div key={index} className="search-result-item">
                <Button
                  className="search-result-button"
                  icon={<PlusOutlined className="plus-icon" />}
                  onClick={() => addSkillToEditor(result)}
                >
                  {result}
                </Button>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Step4;
