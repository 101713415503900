import { Button, Col, Grid, Layout, Row, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_JOBS } from "../../../query";
import client from "../../../applo";
import translateText from "../../../constants/translator";
import "./CVGenerate.css"; // Import the CSS file

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const CVGenerate: FC = () => {
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const isDesktop = screens.lg;

  const [jobData, setJobData] = useState<any>([]);
  const { loading, error, data } = useQuery(GET_JOBS, { client });

  const [language, setLanguage] = useState(localStorage.getItem('lanCode') || 'es');
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'The CV that gets the job',
      'Why is it essential to have a good CV?',
      "A good CV is not just a formality; it's your competitive edge. It's often the first thing a potential employer sees, and a well-crafted one can effectively showcase your skills, experience, and qualifications, giving you a significant advantage over other applicants. Your professional story, background, and accomplishments are all rolled into one, making it an essential part of the job application process. So, let's focus on creating a standout CV that will set you apart!",
      'Create your CV',
      'How to create a CV within 3 steps',
      'Step 01',
      'Choose the sample template of your interest which represents you well among the other candidates by clicking "View All Templates" button.',
      'Step 02',
      'Feed your personal, educational, professional, technical, extracurricular and other relevant information to the selected format.',
      'Step 03',
      'Double check your details and click "Generate CV" button to automatically create a full sample CV.'
    ];
    const translations = await translateText(textsToTranslate, language);
    setTranslatedTexts(translations);
  };

  useEffect(() => {
    handleTranslate();
  }, [language]);

  useEffect(() => {
    setJobData(data?.jobs?.slice(0, 3) ?? []);
  }, [data]);

  return (
    <>
      <Row justify="start" align="top" className="row">
        <Col xs={24} md={8} lg={8} className={isMobile ? "col-centered" : "col-left margin-lft"}>
          <Paragraph className="paragraph-bold">
            {translatedTexts[1]}
          </Paragraph>
          <Paragraph className="paragraph-justify">
            {translatedTexts[2]}
          </Paragraph>
        </Col>
        <Col xs={24} md={12} lg={12} className="wrappercol-centered">
          <Row justify="start" align="middle" style={{ width: '100%' }}>
            <Title style={
              {
                marginLeft: isMobile ? 0 : 38,
              }
            } level={2} className="title-margin">
              {translatedTexts[0]}
            </Title>
            <Col xs={24} md={12} className={`wrappercol-centered ${isMobile ? "col-centered-mobile" : ""}`}>
              <img src="/assets/mainCv.jpeg" className="image-full" />
              <Button style={{
                marginLeft: isMobile ? 0 : 38,
              }} size="large" type="primary" href="/#/cv-gen/onboarding" className="button-primary">
                {translatedTexts[3]}
              </Button>
            </Col>
            <Col xs={24} md={12} lg={12} className={`col-centered ${isMobile ? "col-centered-mobile" : ""}`}>
              <img src="/assets/cvGirl.png" className="image-full" />
            </Col>
          </Row>
        </Col>
      </Row>

      <Title level={2} className="section-title">
        {translatedTexts[4]}
      </Title>

      <Row gutter={[16, 16]} justify="center" className="row">
        <Col xs={24} md={24} lg={10} className={isMobile ? "col-centered" : "col-left margin-lft"}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: 20, width:'100%' }}>
              <Title level={3} className="section-title">
                {translatedTexts[5]}
              </Title>
              <Paragraph className="paragraph-center">
                {translatedTexts[6]}
              </Paragraph>
            </div>
            <div style={{ marginBottom: 20, width: '100%' }}>
              <Title level={3} className="section-title">
                {translatedTexts[7]}
              </Title>
              <Paragraph className="paragraph-center">
                {translatedTexts[8]}
              </Paragraph>
            </div>
            <div style={{ width:  '100%' }}>
              <Title level={3} className="section-title">
                {translatedTexts[9]}
              </Title>
              <Paragraph className="paragraph-center">
                {translatedTexts[10]}
              </Paragraph>
            </div>
          </div>
        </Col>

        <Col xs={24} md={24} lg={12} className="col-centered margin-rgt">
          <img
            src="/assets/admire.jpeg"
            className="img-responsive"
          />
        </Col>
      </Row>
    </>
  );
};

export default CVGenerate;
