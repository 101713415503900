import React, { ChangeEvent, FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Form, Input, Button, Typography, Row, Col } from "antd";
import { EMAIL_REGEX } from "../../constants/regex";
import { Credentials, ErrorHelps, InputField } from "../../utils/auth-types";
import { Errors } from "../../utils/errors";
import translateText from "../../constants/translator";
import * as authService from "../../services/auth-service";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import "./ForgotPasswordPage.css"; // Import the CSS file

const { Content } = Layout;
const { Title, Text } = Typography;

interface ForgotPasswordPageProps {}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = () => {
  const [credentials, setCredentials] = useState<Credentials>({});
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const langCode = localStorage.getItem('lanCode') || 'en';
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        "Reset Password",
        "Enter your email",
        "Email",
        "You've successfully reset your password",
        "Back To",
        "Login",
        "Entered Email is Invalid"
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };

    fetchTranslations();
  }, [langCode]);

  const updateCredentials = (name: string, validate: InputField["validator"]) => 
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCredentials((prev) => ({
        ...prev,
        [name]: validate?.(value) ? value : Errors.INPUT_ERROR,
      }));
    };

  const submit = async () => {
    try {
      const response = await authService.forgotPassword(credentials);
      if (response.success) {
        navigate(`/reset-password?number=${credentials.number}`);
        addSnack?.({
          severity: "success",
          message: response.msg,
        });
      }
    } catch (error: any) {
      addSnack?.({
        severity: "error",
        message: error.message,
      });
    }
  };

  return (
    <Layout className="forgot-password-container">
      <Content className="forgot-password-content">
        <Row justify="center">
          <Col xs={24} md={12}>
            <Title level={2} className="forgot-password-title">
              {translatedTexts[0]}
            </Title>
            <Form layout="vertical" onFinish={submit}>
              {inputs.map((input) => {
                const hasError = credentials?.[input.name] === Errors.INPUT_ERROR;
                return (
                  <Form.Item
                    key={input.name}
                    label={translatedTexts[inputs.indexOf(input) * 2 + 2]}
                    validateStatus={hasError ? "error" : ""}
                    help={hasError ? translatedTexts[6] : ""}
                    rules={[{ required: true, message: translatedTexts[6] }]}
                    className="forgot-password-form-item"
                  >
                    <Input
                      placeholder={input.props?.placeholder}
                      onChange={updateCredentials(input.name, input.validator)}
                      className="forgot-password-input"
                    />
                  </Form.Item>
                );
              })}
              <Form.Item>
                <Button 
                  type="default" 
                  htmlType="submit" 
                  block 
                  className="forgot-password-button"
                >
                  {translatedTexts[0]}
                </Button>
              </Form.Item>
            </Form>
            <Row justify="center" className="back-to-login-row">
              <Text>{translatedTexts[4]}</Text>
              <Link to="/login" className="back-to-login-link">
                <Text>{translatedTexts[5]}</Text>
              </Link>
            </Row>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ForgotPasswordPage;

export const inputs: InputField[] = [
  {
    name: "email",
    validator: (email) => EMAIL_REGEX.test(email),
    props: {
      placeholder: "Enter your email",
      label: "Email",
      required: true,
    },
  },
];

const errorHelps: ErrorHelps = {
  number: {
    en: "Entered Email is Invalid",
  },
};
