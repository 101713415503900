import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, notification, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import translateText from '../../constants/translator';
import './ManageLocations.css';

const ManageLocations: React.FC = () => {
  const [locations, setLocations] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredLocations, setFilteredLocations] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchLocations();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add Location',
      'Edit Location',
      'Name',
      'Action',
      'Duplicate Name',
      'A location with this name already exists.',
      'Location deleted successfully',
      'Location updated successfully',
      'Location added successfully',
      'An error occurred while saving the Location',
      'Please input the name!',
      'Update',
      'Save',
      'Edit',
      'Delete',
      'Search...',
      'Rows per page:'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addLocation',
      'editLocation',
      'name',
      'action',
      'duplicateName',
      'duplicateDescription',
      'itemDeleted',
      'itemUpdated',
      'itemAdded',
      'errorSaving',
      'nameRequired',
      'update',
      'save',
      'edit',
      'delete',
      'searchPlaceholder',
      'rowsPerPage'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchLocations = async () => {
    const snapshot = await getDocs(collection(db, "locations"));
    const list = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setLocations(list);
    setFilteredLocations(list);
  };

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (item: any) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleDelete = async (itemId: string) => {
    await deleteDoc(doc(db, "locations", itemId));
    notification.success({ message: translatedTexts.itemDeleted });
    fetchLocations();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      // Check for duplicate names
      const q = query(collection(db, "locations"), where("name", "==", values.name));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty && (!editingItem || querySnapshot.docs[0].id !== editingItem.id)) {
        notification.error({ message: translatedTexts.duplicateName, description: translatedTexts.duplicateDescription });
        return;
      }

      if (editingItem) {
        await updateDoc(doc(db, "locations", editingItem.id), values);
        notification.success({ message: translatedTexts.itemUpdated });
      } else {
        await addDoc(collection(db, "locations"), values);
        notification.success({ message: translatedTexts.itemAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchLocations();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSaving });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = locations.filter((item) =>
      item.name.toLowerCase().includes(value)
    );

    setFilteredLocations(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addLocation}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredLocations}
        columns={[
          { title: translatedTexts.name, dataIndex: 'name', key: 'name' },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, item) => (
              <div className="action-buttons">
                <Button onClick={() => handleEdit(item)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(item.id)}>
                  {translatedTexts.delete}
                </Button>
              </div>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingItem ? translatedTexts.editLocation : translatedTexts.addLocation}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okText={editingItem ? translatedTexts.update : translatedTexts.save}
        okType="default"
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label={translatedTexts.name} rules={[{ required: true, message: translatedTexts.nameRequired }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageLocations;
