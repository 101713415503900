import React, { useEffect, useState } from 'react';
import { Layout, Form, Input, Button, Row, Col, Typography, notification } from 'antd';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase'; // Your Firebase configuration file
import translateText from "../../constants/translator";
import { SendOutlined } from '@ant-design/icons';
import './ContactUs.css'; // Import the CSS file

const { Title } = Typography;
const { TextArea } = Input;

const ContactUs: React.FC = () => {
  const [translatedTexts, setTranslatedTexts] = useState({
    contactUs: '',
    companyName: '',
    contactName: '',
    email: '',
    phone: '',
    jobDetails: '',
    sendNow: '',
    success: '',
    successMessage: '',
    error: '',
    errorMessage: '',
    validEmail: '',
    pleaseEnter: '',
  });

  useEffect(() => {
    const fetchTranslations = async () => {
      const langCode = localStorage.getItem('lanCode') || 'en';
      const translations = await translateText([
        'Contact Us',
        'Company Name',
        'Contact Name',
        'Email',
        'Phone',
        'Job Details',
        'Send Now',
        'Success',
        'Your job post has been submitted successfully!',
        'Error',
        'There was an error submitting your job post. Please try again.',
        'Please enter a valid email',
        'Please enter your'
      ], langCode);
      setTranslatedTexts({
        contactUs: translations[0],
        companyName: translations[1],
        contactName: translations[2],
        email: translations[3],
        phone: translations[4],
        jobDetails: translations[5],
        sendNow: translations[6],
        success: translations[7],
        successMessage: translations[8],
        error: translations[9],
        errorMessage: translations[10],
        validEmail: translations[11],
        pleaseEnter: translations[12],
      });
    };

    fetchTranslations();
  }, []);

  const onFinish = async (values: any) => {
    try {
      await addDoc(collection(db, "jobPosts"), values);
      notification.success({
        message: translatedTexts.success,
        description: translatedTexts.successMessage,
      });
    } catch (error) {
      console.error('Error adding document: ', error);
      notification.error({
        message: translatedTexts.error,
        description: translatedTexts.errorMessage,
      });
    }
  };

  return (
    <Row justify="center" gutter={[32, 32]} className="contact-us-container">
      <Col xs={24} md={14}>
        <Title level={2} className="contact-us-title">{translatedTexts.contactUs}</Title>
        <Form
          name="contact"
          layout="vertical"
          onFinish={onFinish}
          className="contact-us-form"
        >
          <Form.Item
            name="companyName"
            label={translatedTexts.companyName}
            rules={[{ required: true, message: `${translatedTexts.pleaseEnter} ${translatedTexts.companyName.toLowerCase()}` }]}
          >
            <Input size="large" className="contact-us-input" />
          </Form.Item>
          <Form.Item
            name="contactName"
            label={translatedTexts.contactName}
            rules={[{ required: true, message: `${translatedTexts.pleaseEnter} ${translatedTexts.contactName.toLowerCase()}` }]}
          >
            <Input size="large" className="contact-us-input" />
          </Form.Item>
          <Form.Item
            name="email"
            label={translatedTexts.email}
            rules={[
              { required: true, message: `${translatedTexts.pleaseEnter} ${translatedTexts.email.toLowerCase()}` },
              { type: 'email', message: translatedTexts.validEmail }
            ]}
          >
            <Input size="large" className="contact-us-input" />
          </Form.Item>
          <Form.Item
            name="phone"
            label={translatedTexts.phone}
            rules={[{ required: true, message: `${translatedTexts.pleaseEnter} ${translatedTexts.phone.toLowerCase()}` }]}
          >
            <Input size="large" className="contact-us-input" />
          </Form.Item>
          <Form.Item
            name="jobDetails"
            label={translatedTexts.jobDetails}
            rules={[{ required: true, message: `${translatedTexts.pleaseEnter} ${translatedTexts.jobDetails.toLowerCase()}` }]}
          >
            <TextArea rows={4} className="contact-us-textarea" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="contact-us-button"
            >
              <SendOutlined className="contact-us-button-icon" />
              {translatedTexts.sendNow}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col xs={24} md={10}>
        <img src="/assets/contactus.webp" alt="Contact Us" className="contact-us-image" />
      </Col>
    </Row>
  );
};

export default ContactUs;
