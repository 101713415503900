import React, { FC, useState, useEffect } from "react";
import { Layout, Button, Row, Col, Modal, Card, Form, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore"; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import DownloadCV from "./download";
import Sidebar from "./sidebar";
import RightSidePreview from "./RightSidePreview"; // Import the new component
import { auth, db } from "../../../firebase";
import './HomePage.css';  // Import the custom CSS file

const { Content } = Layout;

const HomePage: FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [workSubStep, setWorkSubStep] = useState<number>(0);
  const [educationSubStep, setEducationSubStep] = useState<number>(0);
  const [summary, setSummary] = useState<string>("");
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState<any>({});
  const [fieldsStatus, setFieldsStatus] = useState<{ [key: string]: boolean }>({});
  const [additionalFields, setAdditionalFields] = useState<{ [key: string]: string }>({});
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [workExperience, setWorkExperience] = useState<any[]>([]);
  const [educationHistory, setEducationHistory] = useState<any[]>([]);
  const [languages, setLanguages] = useState<{ name: string; level: string; additionalInfo: string }[]>([]);
  const [references, setReferences] = useState<any>(false);
  const [currentWork, setCurrentWork] = useState<any>(null);
  const [currentEducation, setCurrentEducation] = useState<any>(null);
  const [editorContent, setEditorContent] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>(null);
  const [editingWorkIndex, setEditingWorkIndex] = useState<number | null>(null);
  const [editingEducationIndex, setEditingEducationIndex] = useState<number | null>(null);
  const [isPlanModalVisible, setIsPlanModalVisible] = useState<boolean>(false);
  const [isInitialModalVisible, setIsInitialModalVisible] = useState<boolean>(true);
  const [plans, setPlans] = useState<any[]>([]);
  const { style_id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const collectionRef = collection(db, "cvSearchResults");
        const snapshot = await getDocs(collectionRef);
        const data: any = {};
        snapshot.forEach((doc) => {
          data[doc.id] = doc.data().items;
        });
        setSearchResults(data);
      } catch (error) {
        console.error("Error fetching search results: ", error);
      }
    };

    fetchSearchResults();
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      } else {
        loadPreviousData();
      }
    });
  }, [navigate]);

  useEffect(() => {
    const fetchPlans = async () => {
      const plansCollection = collection(db, "cvPrices");
      const plansSnapshot = await getDocs(plansCollection);
      const plansData = plansSnapshot.docs.map(doc => doc.data());
      setPlans(plansData);
    };

    fetchPlans();
  }, []);

  const loadPreviousData = () => {
    try {
      const data = JSON.parse(localStorage.getItem('cvData') || '{}');
      if (data) {
        setPreviewData(data);
        setWorkExperience(data.workExperience || []);
        setEducationHistory(data.educationHistory || []);
        setSummary(data.summary || '');
        setReferences(data.references || false);
        setLanguages(data.languages || []);
      }
    } catch (error) {
      console.error("Error loading previous data: ", error);
    }
  };

  useEffect(() => {
    const updatePreviewData = () => {
      const cvData = {
        credentials: previewData.credentials,
        workExperience,
        educationHistory,
        summary,
        picture: previewData.avatar ? previewData.avatar[0]?.thumbUrl : null,
        references,
        languages,
      };
      setPreviewData(cvData);
    };

    updatePreviewData();
  }, [previewData.credentials, workExperience, educationHistory, summary, references, languages, previewData.avatar]);

  const handleNext = () => {
    form.validateFields().then(values => {
      setPreviewData((prevData: any) => ({ ...prevData, ...values }));
      if (currentStep === 0) {
        if (workExperience.length === 0) {
          setCurrentStep(currentStep + 1);
          setWorkSubStep(0);
        } else {
          setCurrentStep(currentStep + 1);
          setWorkSubStep(2);
        }
      } else if (currentStep === 1) {
        if (workSubStep === 0) {
          setCurrentWork({
            ...values,
            startDate: values.startDate ? values.startDate.format('MMM YYYY') : '',
            endDate: values.endDate ? values.endDate.format('MMM YYYY') : '',
          });
          setWorkSubStep(workSubStep + 1);
        } else if (workSubStep === 1) {
          const updatedWorkExperience = {
            ...currentWork,
            description: editorContent
          };

          if (editingWorkIndex !== null) {
            const updatedExperience = [...workExperience];
            updatedExperience[editingWorkIndex] = updatedWorkExperience;
            setWorkExperience(updatedExperience);
            setEditingWorkIndex(null);
          } else {
            setWorkExperience([...workExperience, updatedWorkExperience]);
          }

          setCurrentWork(null);
          setEditorContent("");
          setWorkSubStep(workSubStep + 1);
        } else if (workSubStep === 2) {
          handleWorkSubmit(values);
          setWorkSubStep(0);
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 2) {
        if (educationSubStep === 0) {
          setCurrentEducation({
            ...values,
            startDate: values.startDate ? values.startDate.format('MMM YYYY') : '',
            endDate: values.endDate ? values.endDate.format('MMM YYYY') : '',
          });
          const updatedEducationHistory = {
            ...currentEducation,
            description: editorContent
          };
          if (editingEducationIndex !== null) {
            const updatedEducation = [...educationHistory];
            updatedEducation[editingEducationIndex] = updatedEducationHistory;
            setEducationHistory(updatedEducation);
            setEditingEducationIndex(null);
          } else {
            setEducationHistory([...educationHistory, updatedEducationHistory]);
          }
          setCurrentEducation(null);
          setEditorContent("");
          setEducationSubStep(educationSubStep + 1);
        } else if (educationSubStep === 1) {
          handleEducationSubmit(values);
          setEducationSubStep(0);
          setCurrentStep(currentStep + 1);
        }
      } else if (currentStep === 3) {
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }).catch(info => {
      console.log('Validation Failed:', info);
    });
  };

  const handleBack = () => {
    const savedData = JSON.parse(localStorage.getItem('cvData') || '{}');
    if (savedData) {
      form.setFieldsValue(savedData);
    }
    if (currentStep === 1) {
      if (workSubStep > 0) {
        setWorkSubStep(workSubStep - 1);
      } else {
        setCurrentStep(currentStep - 1);
      }
    } else if (currentStep === 2) {
      if (workExperience.length > 0) {
        setCurrentStep(1);
        setWorkSubStep(2);
      } else {
        setCurrentStep(1);
        setWorkSubStep(0);
      }
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const field = Object.keys(changedValues)[0];
    setFieldsStatus((prevStatus) => ({ ...prevStatus, [field]: !!allValues[field] }));
    setPreviewData((prevData: any) => ({ ...prevData, credentials: { ...prevData.credentials, ...allValues } }));
    localStorage.setItem('cvData', JSON.stringify({ ...previewData, credentials: { ...previewData.credentials, ...allValues } }));
  };

  const handleAddField = (fieldName: string) => {
    setAdditionalFields((prevFields) => ({ ...prevFields, [fieldName]: '' }));
  };

  const handleRemoveField = (fieldName: string) => {
    setAdditionalFields((prevFields) => {
      const newFields = { ...prevFields };
      delete newFields[fieldName];
      return newFields;
    });
  };

  const handleAdditionalFieldChange = (fieldName: string, value: string) => {
    setAdditionalFields((prevFields) => ({ ...prevFields, [fieldName]: value }));
  };

  const showPreview = () => {
    const cvData = {
      credentials: previewData.credentials,
      workExperience,
      educationHistory,
      summary,
      languages,
      references,
    };
    setPreviewData(cvData);
    setIsPreviewVisible(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewVisible(false);
  };

  const handleWorkSubmit = (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate ? values.startDate.format('MMM YYYY') : '',
      endDate: values.endDate ? values.endDate.format('MMM YYYY') : '',
    };

    if (editingWorkIndex !== null) {
      const updatedWorkExperience = [...workExperience];
      updatedWorkExperience[editingWorkIndex] = formattedValues;
      setWorkExperience(updatedWorkExperience);
      setEditingWorkIndex(null);
    } else {
      setWorkExperience([...workExperience, formattedValues]);
    }
    setCurrentWork(formattedValues);
    form.resetFields();
  };

  const handleEducationSubmit = (values: any) => {
    const formattedValues = {
      ...values,
      startDate: values.startDate ? values.startDate.format('MMM YYYY') : '',
      endDate: values.endDate ? values.endDate.format('MMM YYYY') : '',
    };

    if (editingEducationIndex !== null) {
      const updatedEducationHistory = [...educationHistory];
      updatedEducationHistory[editingEducationIndex] = formattedValues;
      setEducationHistory(updatedEducationHistory);
      setEditingEducationIndex(null);
    } else {
      setEducationHistory([...educationHistory, formattedValues]);
    }
    setCurrentEducation(formattedValues);
    form.resetFields();
  };

  const handleCurrentlyWorkHereChange = (e: any) => {
    if (e.target.checked) {
      form.setFieldsValue({ endDate: null });
      setFieldsStatus((prevStatus) => ({ ...prevStatus, endDate: false }));
    }
  };

  const handleCurrentlyStudyingHereChange = (e: any) => {
    if (e.target.checked) {
      form.setFieldsValue({ endDate: null });
      setFieldsStatus((prevStatus) => ({ ...prevStatus, endDate: false }));
    }
  };

  const addResponsibilityToEditor = (text: string) => {
    setEditorContent((prevContent) => `${prevContent}<p>• ${text}</p>`);
    if (currentStep === 1) {
      setCurrentWork((prevWork: any) => ({
        ...prevWork,
        description: `${prevWork?.description || ''}<p>• ${text}</p>`
      }));
    } else if (currentStep === 2) {
      setCurrentEducation((prevEducation: any) => ({
        ...prevEducation,
        description: `${prevEducation?.description || ''}<p>• ${text}</p>`
      }));
    }
    setSearchResults(searchResults.filter((result: string) => result !== text));
  };

  const handleSearch = (e: any) => {
    const searchTerm = e.target.value;
    let results: any = [];
    if (currentStep === 1) {
      results = searchResults?.workHistory?.filter((result: string) => result.toLowerCase().includes(searchTerm.toLowerCase())) || [];
    } else if (currentStep === 2) {
      results = searchResults?.education?.filter((result: string) => result.toLowerCase().includes(searchTerm.toLowerCase())) || [];
    } else if (currentStep === 3) {
      results = searchResults?.skills?.filter((result: string) => result.toLowerCase().includes(searchTerm.toLowerCase())) || [];
    } else if (currentStep === 4) {
      results = searchResults?.summary?.filter((result: string) => result.toLowerCase().includes(searchTerm.toLowerCase())) || [];
    }
    setSearchResults(results);
  };

  const handleTemplateChange = () => {
    const hasData = !!Object.keys(previewData).length;

    if (hasData) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. Do you want to save them before changing the template?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          saveData();
        },
        onCancel: () => {
          clearData();
        }
      });
    } else {
      navigate('/cv-gen/onboarding');
    }
  };

  const saveData = () => {
    try {
      const data = {
        credentials: previewData.credentials,
        workExperience,
        educationHistory,
        summary,
        languages,
        references,
      };
      localStorage.setItem('cvData', JSON.stringify(data));

      notification.success({ message: 'Data saved successfully' });
      navigate('/cv-gen/onboarding');
    } catch (error) {
      console.error("Error saving data: ", error);
      notification.error({ message: 'Error saving data', description: 'An error occurred while saving your data. Please try again.' });
    }
  };

  const clearData = () => {
    localStorage.removeItem('cvData');
    setPreviewData({});
    setWorkExperience([]);
    setEducationHistory([]);
    setSummary("");
  
  };

  const handleSelectPlan = (plan: any) => {
    setIsPlanModalVisible(false);
    navigate("/payment", { state: { plan } }); // Navigate to payment page with selected plan
  };

  const handleInitialModalChoice = (choice: string) => {
    if (choice === "new") {
      clearData();  // Clears the existing CV data if user chooses to create a new CV
    }
    setIsInitialModalVisible(false);  // Closes the initial modal after making a choice
  };

  const steps = [
    {
      title: "CV heading",
      content: <Step1 />,
    },
    {
      title: "Work history",
      content: (
        <Step2
          currentSubStep={workSubStep}
          handleBack={() => {
            setCurrentStep(currentStep - 1);
          }}
          navigateSubSteps={(subStep: number) => {
            setWorkSubStep(subStep);
          }}
          results={searchResults?.workHistory}
        />
      ),
    },
    {
      title: "Education",
      content: (
        <Step3
          educationSubStep={educationSubStep}
          handleBack={handleBack}
          navigateSubSteps={(subStep: number) => {
            setEducationSubStep(subStep);
          }}
          results={searchResults?.education}
        />
      ),
    },
    {
      title: "Skills",
      content: (
        <Step4
          handleBack={handleBack}
          searchResults={searchResults?.skills}
          handleSearch={handleSearch}
        />
      ),
    },
    {
      title: "Professional Summary",
      content: (
        <Step5
          handleBack={handleBack}
          handleNext={handleNext}
          summary={summary}
          setSummary={setSummary}
          searchResults={searchResults?.summary}
          handleSearch={handleSearch}
          addSummaryPart={(part) => setSummary((prev) => `${prev}<p>${part}</p>`)}
          handleFieldChange={handleFieldChange}
          fieldsStatus={fieldsStatus}
        />
      ),
    },
    {
      title: "Finalize",
      content: (
        <Step6
      
          handleBack={handleBack}
          languages={languages}
          setLanguages={setLanguages}
          references={references}
          setReferences={setReferences}
        />
      ),
    }
  ];

  const getCurrentStepContent = () => {
    const step = steps[currentStep];
    return step && step.content;
  };

  const handleDownload = async () => {
    const input = document.getElementById('cv-modal-preview');
    if (input) {
      const canvas = await html2canvas(input, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save("cv.pdf");
    }
  };

  return (
    <Layout className="homepage-layout">
      <Content className="homepage-content">
        <Row gutter={16} className="w-full">
          <Col xs={24} sm={8} md={6}>
            <Sidebar currentStep={currentStep} steps={steps} />
          </Col>
          <Col xs={24} sm={16} md={!(currentStep === 4 || currentStep === 3 || (currentStep === 1 && workSubStep === 1)) ? 10 : 18}>
            {getCurrentStepContent()}
            <div className="form-footer">
              <Button 
                type="default" 
                className="bg-white text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white" 
                onClick={showPreview}
              >
                Preview
              </Button>
              {currentStep < steps.length - 1 &&
                ((workSubStep === 2 && currentStep === 1) || (educationSubStep === 1 && currentStep === 2) || (currentStep !== 1 && currentStep !== 2)) && (
                  <Button 
                    type="default" 
                    className="bg-blue-500 text-white" 
                    onClick={handleNext}
                  >
                    {currentStep === 1 && workSubStep === 2 ? "Next: Education" : currentStep === 2 && educationSubStep === 1 ? "Next: Skills" : `Next: ${steps[currentStep + 1].title}`}
                  </Button>
                )}
              {currentStep === 1 && (workSubStep === 0 || workSubStep === 1) && (
                <Button 
                  type="default" 
                  className="bg-blue-500 text-white" 
                  onClick={handleNext}
                >
                  {workSubStep === 0 ? "Next: Work history" : "Next"}
                </Button>
              )}
              {currentStep === 2 && (educationSubStep === 0) && (
                <Button 
                  type="default" 
                  className="bg-blue-500 text-white" 
                  onClick={handleNext}
                >
                  {educationSubStep === 0 ? "Next: Education" : "Next: Education Summary"}
                </Button>
              )}
              {currentStep === 5 && (
                <Button 
                  type="default" 
                  className="bg-blue-500 text-white" 
                  onClick={() => setIsPlanModalVisible(true)}
                >
                  Generate CV
                </Button>
              )}
            </div>
          </Col>
          {
            !(currentStep === 4 || currentStep === 3 || (currentStep === 1 && workSubStep === 1)) &&
            <Col  xs={24} sm={24} md={8} className="mt-4 md:mt-0">
              <div className="preview-container">
                <RightSidePreview styleID={parseInt(style_id ?? '1')} /> {/* Use the RightSidePreview component */}
              </div>
              <div className="change-template-container">
                <Button type="link" onClick={handleTemplateChange} className="full-width-button">
                  Change Template
                </Button>
              </div>
            </Col>
          }
        </Row>

        {/* Initial Modal for CV choice */}
        <Modal
          visible={isInitialModalVisible}
          onCancel={() => setIsInitialModalVisible(false)}
          footer={null}
          centered
          title="Create New CV or Use Existing?"
        >
          <Button
            type="default"
            block
            onClick={() => handleInitialModalChoice("new")}
            className="mb-4"
          >
            Create New CV
          </Button>
          <Button type="default" block onClick={() => handleInitialModalChoice("existing")}>
            Use Existing CV
          </Button>
        </Modal>

        {/* Plan Selection Modal */}
        <Modal
          visible={isPlanModalVisible}
          onCancel={() => setIsPlanModalVisible(false)}
          footer={null}
          centered
          title="Choose a CV Plan"
        >
          <Row gutter={16}>
            {plans.map((plan, index) => (
              <Col span={8} key={index}>
                <Card
                  title={`CV Count: ${plan.cvCount}`}
                  bordered={false}
                  hoverable
                  onClick={() => handleSelectPlan(plan)}
                  className="mb-4"
                >
                  <p>Price: ${plan.price}</p>
                  <Button type="default">Choose Plan</Button>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal>

        <Modal
          visible={isPreviewVisible}
          onCancel={handlePreviewClose}
          footer={null}
          width="auto"
          centered
          bodyStyle={{
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div id="cv-modal-preview" className="cv-modal-preview">
            <DownloadCV styleID={parseInt(style_id ?? '1')} />
          </div>
          <div className="modal-footer-buttons">
            <Button type="default" className="bg-white text-blue-500 border-blue-500 hover:bg-blue-500 hover:text-white" onClick={handlePreviewClose}>
              Close
            </Button>
            <Button type="default" className="bg-blue-500 text-white" onClick={handleDownload}>
              <i className="fas fa-print"></i> Print CV
            </Button>
          </div>
        </Modal>
      </Content>
    </Layout>
  );
};

export default HomePage;
