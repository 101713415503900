import React from 'react';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';
import './CVTemplate2.css';

interface CVTemplate2Props {
  savedData?: {
    credentials?: {
      firstName?: string;
      lastName?: string;
      title?: string;
      email?: string;
      phone?: string;
      address?: string;
      avatar?: string;
      city?: string;
      county?: string;
      postcode?: string;
    };
    workExperience?: {
      jobTitle: string;
      employer: string;
      startDate: string;
      endDate: string;
      description: string;
      currentlyWorkHere: boolean;
      city?: string;
      county?: string;
    }[];
    educationHistory?: {
      institution: string;
      qualification: string;
      fieldOfStudy?: string;
      honours?: string;
      city?: string;
      startDate: string;
      endDate?: string;
      currentlyStudyingHere?: boolean;
      description?: string;
    }[];
    skills?: string[];
    summary?: string;
    picture?: string | null;
    references?: boolean;
    languages?: {
      name: string;
      level: string;
    }[];
  };
  pageIndex: number;
  styles?: any;
}

const capitalize = (str: string | undefined) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const CVTemplate2: React.FC<CVTemplate2Props> = ({ savedData, styles }) => {
  const fullName = `${capitalize(savedData?.credentials?.firstName ?? '')} ${capitalize(savedData?.credentials?.lastName ?? '')}`;
  const avatarUrl = savedData?.credentials?.avatar;

  return (
    <div className="cv-template2" style={styles}>
      <div className="cv-sidebar">
        {avatarUrl && <img src={avatarUrl} alt="Profile" className="cv-picture" />}
        <div className="contact-info">
          {savedData?.credentials?.phone && (
            <p><PhoneOutlined /> {savedData.credentials.phone}</p>
          )}
          {savedData?.credentials?.email && (
            <p><MailOutlined /> {savedData.credentials.email}</p>
          )}
          {savedData?.credentials?.address && (
            <p><EnvironmentOutlined /> {savedData.credentials.address}, {savedData.credentials.city}, {savedData.credentials.county}, {savedData.credentials.postcode}</p>
          )}
        </div>

        <div className="cv-section">
          <h2 className="cv-section-title">EDUCATION</h2>
          <div className="cv-section-content">
            {savedData?.educationHistory?.map((education, index) => (
              <div key={index}>
                <h3>{education.qualification}</h3>
                <p>{education.institution}</p>
                <p>{education.startDate} - {education.endDate}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="cv-section">
          <h2 className="cv-section-title">EXPERTISE</h2>
          <div className="cv-section-content">
            <ul>
              {savedData?.skills?.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className="cv-section">
          <h2 className="cv-section-title">LANGUAGE</h2>
          <div className="cv-section-content">
            <ul>
              {savedData?.languages?.map((language, index) => (
                <li key={index}>{language.name}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="cv-main">
        <div className="cv-header2">
          <h1 className="cv-name1">{fullName}</h1>
          <h2 className="cv-title2">{savedData?.credentials?.title}</h2>
        </div>

        <div className="cv-section">
          <h2 className="cv-section-title">Experience</h2>
          <div className="cv-section-content">
            {savedData?.workExperience?.map((work, index) => (
              <div key={index} className="cv-experience-item">
                <h3>{work.jobTitle}</h3>
                <p>{work.employer}</p>
                <p>{work.startDate} - {work.currentlyWorkHere ? 'Present' : work.endDate}</p>
                <p>{work.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="cv-section">
          <h2 className="cv-section-title">Reference</h2>
          <div className="cv-section-content">
            <p>{savedData?.references ? 'Available upon request' : 'Not available'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CVTemplate2;
