import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, notification, Select, InputNumber, Upload, Checkbox, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { UploadOutlined } from '@ant-design/icons';
import { db, storage } from '../../firebase';
import translateText from '../../constants/translator';

const { Option } = Select;
const { TextArea } = Input;

const ManageJobs: React.FC = () => {
  const [jobs, setJobs] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingJob, setEditingJob] = useState<any>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [jobTypes, setJobTypes] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchJobs();
    fetchJobTypes();
    fetchLocations();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add Job',
      'Edit Job',
      'Job Title',
      'Company',
      'Location',
      'Vacancies',
      'Job Type',
      'Salary',
      'Salary Frequency',
      'Company Description',
      'Job Description',
      'Tags',
      'New',
      'Featured',
      'Urgent',
      'Opportunity',
      'Action',
      'Job deleted successfully',
      'Job updated successfully',
      'Job added successfully',
      'An error occurred while saving the job',
      'Please input the job title!',
      'Please input the company name!',
      'Please input the job location!',
      'Please input the number of vacancies!',
      'Please select the job type!',
      'Please input the job salary!',
      'Please select the salary frequency!',
      'Please input the company description!',
      'Please input the job description!',
      'Update',
      'Save',
      'Click to upload',
      'Full-time',
      'Part-time',
      'Contract',
      'Temporary',
      'Search...',
      'Rows per page:',
      'Edit',
      'Delete'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addJob',
      'editJob',
      'jobTitle',
      'company',
      'location',
      'vacancies',
      'jobType',
      'salary',
      'salaryFrequency',
      'companyDescription',
      'jobDescription',
      'tags',
      'new',
      'featured',
      'urgent',
      'opportunity',
      'action',
      'jobDeleted',
      'jobUpdated',
      'jobAdded',
      'errorSavingJob',
      'jobTitleRequired',
      'companyNameRequired',
      'jobLocationRequired',
      'vacanciesRequired',
      'jobTypeRequired',
      'jobSalaryRequired',
      'salaryFrequencyRequired',
      'companyDescriptionRequired',
      'jobDescriptionRequired',
      'update',
      'save',
      'clickToUpload',
      'fullTime',
      'partTime',
      'contract',
      'temporary',
      'searchPlaceholder',
      'rowsPerPage',
      'edit',
      'delete'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchJobs = async () => {
    const jobsSnapshot = await getDocs(collection(db, 'confirmedjobs'));
    const jobsList = jobsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobs(jobsList);
    setFilteredJobs(jobsList);
  };

  const fetchJobTypes = async () => {
    const jobTypesSnapshot = await getDocs(collection(db, 'jobTypes'));
    const jobTypesList = jobTypesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const jobTypeNames = jobTypesList.map((jobType: any) => jobType.name);
    const translatedJobTypeNames = await translateText(jobTypeNames, langCode);

    const translatedJobTypesList = jobTypesList.map((jobType, index) => ({
      ...jobType,
      name: translatedJobTypeNames[index],
    }));

    setJobTypes(translatedJobTypesList);
  };

  const fetchLocations = async () => {
    const locationsSnapshot = await getDocs(collection(db, 'locations'));
    const locationsList = locationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const locationNames = locationsList.map((location: any) => location.name);
    const translatedLocationNames = await translateText(locationNames, langCode);

    const translatedLocationsList = locationsList.map((location, index) => ({
      ...location,
      name: translatedLocationNames[index],
    }));

    setLocations(translatedLocationsList);
  };

  const handleAdd = () => {
    setEditingJob(null);
    setFileList([]);
    setIsModalVisible(true);
  };

  const handleEdit = (job: any) => {
    setEditingJob(job);
    setFileList(job.companyImage ? [{ uid: '-1', name: 'image.png', status: 'done', url: job.companyImage }] : []);
    setIsModalVisible(true);
    form.setFieldsValue(job);
  };

  const handleDelete = async (jobId: string) => {
    await deleteDoc(doc(db, 'confirmedjobs', jobId));
    notification.success({ message: translatedTexts.jobDeleted });
    fetchJobs();
  };

  const handleUpload = async () => {
    if (fileList.length > 0) {
      const file = fileList[0];
      const storageRef = ref(storage, `companyImages/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file.originFileObj);
      return await getDownloadURL(snapshot.ref);
    }
    return null;
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setUploading(true);

      let imageUrl = editingJob ? editingJob.companyImage : null;
      if (fileList.length > 0 && fileList[0].originFileObj) {
        imageUrl = await handleUpload();
      }

      const jobData = {
        ...values,
        companyImage: imageUrl,
      };

      if (editingJob) {
        await updateDoc(doc(db, 'confirmedjobs', editingJob.id), jobData);
        notification.success({ message: translatedTexts.jobUpdated });
      } else {
        await addDoc(collection(db, 'confirmedjobs'), jobData);
        notification.success({ message: translatedTexts.jobAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchJobs();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSavingJob });
    } finally {
      setUploading(false);
    }
  };

  const handleFileChange = ({ file, fileList }: any) => {
    if (fileList.length > 1) {
      fileList = [fileList[fileList.length - 1]];
    }
    setFileList(fileList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = jobs.filter((job) =>
      job.jobTitle.toLowerCase().includes(value) ||
      job.company.toLowerCase().includes(value) ||
      job.jobLocation.toLowerCase().includes(value) ||
      job.jobType.toLowerCase().includes(value)
    );

    setFilteredJobs(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addJob}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredJobs}
        columns={[
          { title: translatedTexts.jobTitle, dataIndex: 'jobTitle', key: 'jobTitle' },
          { title: translatedTexts.company, dataIndex: 'company', key: 'company' },
          { title: translatedTexts.location, dataIndex: 'jobLocation', key: 'jobLocation' },
          { title: translatedTexts.vacancies, dataIndex: 'vacancies', key: 'vacancies' },
          { title: translatedTexts.jobType, dataIndex: 'jobType', key: 'jobType' },
          { title: translatedTexts.salary, dataIndex: 'jobSalary', key: 'jobSalary' },
          { title: translatedTexts.salaryFrequency, dataIndex: 'salaryFrequency', key: 'salaryFrequency' },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, job) => (
              <>
                <Button onClick={() => handleEdit(job)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(job.id)}>
                  {translatedTexts.delete}
                </Button>
              </>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingJob ? translatedTexts.editJob : translatedTexts.addJob}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        confirmLoading={uploading}
        okText={editingJob ? translatedTexts.update : translatedTexts.save}
        okType='default'
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobTitle" label={translatedTexts.jobTitle} rules={[{ required: true, message: translatedTexts.jobTitleRequired }]}>
                <Select disabled={jobTypes.length === 0}>
                  {jobTypes.map(jobType => (
                    <Option key={jobType.id} value={jobType.name}>{jobType.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="company" label={translatedTexts.company} rules={[{ required: true, message: translatedTexts.companyNameRequired }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobLocation" label={translatedTexts.location} rules={[{ required: true, message: translatedTexts.jobLocationRequired }]}>
                <Select disabled={locations.length === 0}>
                  {locations.map(location => (
                    <Option key={location.id} value={location.name}>{location.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="vacancies" label={translatedTexts.vacancies} rules={[{ required: true, message: translatedTexts.vacanciesRequired }]}>
                <InputNumber min={1} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobType" label={translatedTexts.jobType} rules={[{ required: true, message: translatedTexts.jobTypeRequired }]}>
                <Select>
                  <Option value="Full-time">{translatedTexts.fullTime}</Option>
                  <Option value="Part-time">{translatedTexts.partTime}</Option>
                  <Option value="Contract">{translatedTexts.contract}</Option>
                  <Option value="Temporary">{translatedTexts.temporary}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="jobSalary" label={translatedTexts.salary} rules={[{ required: true, message: translatedTexts.jobSalaryRequired }]}>
                    <InputNumber min={0} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="salaryFrequency" label={translatedTexts.salaryFrequency} rules={[{ required: true, message: translatedTexts.salaryFrequencyRequired }]}>
                    <Select>
                      <Option value="hourly">{translatedTexts.hourly}</Option>
                      <Option value="monthly">{translatedTexts.monthly}</Option>
                      <Option value="yearly">{translatedTexts.yearly}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="companyDescription" label={translatedTexts.companyDescription} rules={[{ required: true, message: translatedTexts.companyDescriptionRequired }]}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="jobDescription" label={translatedTexts.jobDescription} rules={[{ required: true, message: translatedTexts.jobDescriptionRequired }]}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={translatedTexts.companyImage}>
            <Upload
              fileList={fileList}
              beforeUpload={() => false}
              onChange={handleFileChange}
              listType="picture"
            >
              <Button icon={<UploadOutlined />} style={{ backgroundColor: '#0070B0', color: '#fff' }}>{translatedTexts.clickToUpload}</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="tags" label={translatedTexts.tags}>
            <Checkbox.Group>
              <Checkbox value="newJob">{translatedTexts.new}</Checkbox>
              <Checkbox value="featured">{translatedTexts.featured}</Checkbox>
              <Checkbox value="urgent">{translatedTexts.urgent}</Checkbox>
              <Checkbox value="opportunity">{translatedTexts.opportunity}</Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageJobs;
