import React, { FC, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { Layout, Row, Col, Card, Typography, Button, Grid, Spin } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { GET_JOBS } from "../../query";
import client from "../../applo";
import FindJob from "./find-job/[query]";
import CVGenerate from "./cv-gen";
import NewCVGenerate from "./cv-gen/newJobsCv";
import { dumyDataforPosting } from "../../components/find-job/sections/industry.data";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import translateText from "../../constants/translator";
import { Link } from "react-router-dom";
import { getCVTemplate } from "../../services/profile-service";

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const NewJobs: FC = () => {
  const { addSnack } = useSnackbar();
  const { loading, error, data } = useQuery(GET_JOBS, { client });

  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const isDesktop = screens.lg;
  const [load, setLoad] = useState(false);
  const [language, setLanguage] = useState('es');
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Why is it essential to have a good CV?',
      'How to Apply for a Job',
      'Choose a template for your CV',
      'You can always change your template later',
      'Build your CV'
    ];
    const translations = await translateText(textsToTranslate, language);
    setTranslatedTexts(translations);
  };

  useEffect(() => {
    handleTranslate();
  }, [language]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(false);
    }, 20000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data]);

  const [images, setImages] = useState<any[]>([]);

  const fetchImages = async () => {
    setImages([
      { path: 'cvtemp1.jpeg', id: 1 },
      { path: 'cvtemp2.jpeg', id: 2 },
      { path: 'cvtemp3.jpeg', id: 3 },
      { path: 'cvtemp4.jpeg', id: 4 },
      { path: 'cvtemp5.jpeg', id: 5 },
    ]);
  };

  useEffect(() => {
    fetchImages();
  }, []);

 

  useEffect(() => {
    if (error) {
      // addSnack?.({
      //   severity: "error",
      //   message: error?.message ?? "Unknown error",
      // });
    }
  }, [error, addSnack]);

  return (
    <>
    <FindJob />
      <CVGenerate />
     
    </>
  );
};

export default NewJobs;
