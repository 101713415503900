import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Checkbox, Select, Collapse, Typography, Card, message } from 'antd';
import { ArrowLeftOutlined, EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import './Step6.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

interface Language {
  name: string;
  level: string;
  additionalInfo: string;
}

interface Step6Props {

  handleBack: () => void;
  languages: Language[];
  setLanguages: React.Dispatch<React.SetStateAction<Language[]>>;
  references: boolean;
  setReferences: React.Dispatch<React.SetStateAction<boolean>>;
}

const Step6: React.FC<Step6Props> = ({

  handleBack,
  languages,
  setLanguages,
  references,
  setReferences
}) => {
  const [newLanguage, setNewLanguage] = useState<Language>({ name: '', level: '', additionalInfo: '' });

  useEffect(() => {
    const storedData = localStorage.getItem('cvData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setLanguages(parsedData.languages || []);
      setReferences(parsedData.references || false);
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      setLanguages(updatedData.languages || []);
      setReferences(updatedData.references || false);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [setLanguages, setReferences]);

  const updateLocalStorage = (key: string, value: any) => {
    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { languages: [], references: false };
    cvData[key] = value;
    localStorage.setItem('cvData', JSON.stringify(cvData));
    window.dispatchEvent(new Event('storage'));
  };

  const addLanguage = () => {
    if (newLanguage.name && newLanguage.level) {
      const duplicate = languages.find(lang => lang.name === newLanguage.name);
      if (duplicate) {
        message.error('This language is already added.');
        return;
      }
      const updatedLanguages = [...languages, newLanguage];
      setLanguages(updatedLanguages);
      updateLocalStorage('languages', updatedLanguages);
      setNewLanguage({ name: '', level: '', additionalInfo: '' });
    } else {
      message.error('Please fill in all fields.');
    }
  };

  const editLanguage = (index: number) => {
    const language = languages[index];
    setNewLanguage(language);
    deleteLanguage(index);
  };

  const deleteLanguage = (index: number) => {
    const updatedLanguages = languages.filter((_, i) => i !== index);
    setLanguages(updatedLanguages);
    updateLocalStorage('languages', updatedLanguages);
  };

  const handleReferencesChange = (e: any) => {
    const checked = e.target.checked;
    setReferences(checked);
    updateLocalStorage('references', checked);
  };

  return (
    <div className="step6-container">
      <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleBack} className="back-button">
        Go Back
      </Button>

      <div className="step6-header">
        <Title level={2} style={{ color: '#1F2937' }}>Additional Information</Title>
        <Paragraph style={{ color: '#4B5563' }}>Include any extra qualifications or details that might enhance your CV.</Paragraph>
      </div>

      <Row gutter={[16, 16]} className="step6-content">
        <Col xs={24}>
          <Collapse accordion>
            <Panel header={<Title level={4} style={{ marginBottom: 0 }}>Languages</Title>} key="1">
              <div className="languages-panel">
                {languages.length > 0 ? (
                  languages.map((language, index) => (
                    <Card key={index} className="language-card">
                      <div className="language-info">
                        <Title level={5}>{language.name}</Title>
                        <Paragraph>{language.level}</Paragraph>
                        {language.additionalInfo && <Paragraph>{language.additionalInfo}</Paragraph>}
                      </div>
                      <div className="language-actions">
                        <Button icon={<EditOutlined />} onClick={() => editLanguage(index)} style={{ marginRight: 8 }} />
                        <Button icon={<DeleteOutlined />} onClick={() => deleteLanguage(index)} danger />
                      </div>
                    </Card>
                  ))
                ) : (
                  <Paragraph>No languages added yet.</Paragraph>
                )}
                <Form layout="vertical" onFinish={addLanguage} className="add-language-form">
                  <Form.Item label="Language" required>
                    <Select
                      value={newLanguage.name}
                      onChange={(value) => setNewLanguage({ ...newLanguage, name: value })}
                      placeholder="Select Language"
                    >
                      <Option value="English">English</Option>
                      <Option value="Spanish">Spanish</Option>
                      <Option value="French">French</Option>
                      <Option value="German">German</Option>
                      <Option value="Chinese">Chinese</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Level" required>
                    <Select
                      value={newLanguage.level}
                      onChange={(value) => setNewLanguage({ ...newLanguage, level: value })}
                      placeholder="Select Level"
                    >
                      <Option value="Beginner">Beginner</Option>
                      <Option value="Intermediate">Intermediate</Option>
                      <Option value="Advanced">Advanced</Option>
                      <Option value="Fluent">Fluent</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Additional Information">
                    <Input.TextArea
                      value={newLanguage.additionalInfo}
                      onChange={(e) => setNewLanguage({ ...newLanguage, additionalInfo: e.target.value })}
                      placeholder="Any additional details"
                    />
                  </Form.Item>
                  <Button type="default" htmlType="submit" block>Add Language</Button>
                </Form>
              </div>
            </Panel>

            <Panel header={<Title level={4} style={{ marginBottom: 0 }}>References</Title>} key="2">
              <div className="references-panel">
                <Checkbox checked={references} onChange={handleReferencesChange}>
                  Available upon request
                </Checkbox>
              </div>
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export default Step6;
