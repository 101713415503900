import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, InputNumber } from 'antd';
import { collection, getDocs, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';


const ManageSalaryGuide = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<any>(null);

  useEffect(() => {
    fetchSalaryGuideData();
  }, []);

  const fetchSalaryGuideData = async () => {
    const querySnapshot = await getDocs(collection(db, 'salaryGuide'));
    const salaryData:any = querySnapshot.docs.map(doc => ({ ...doc.data(), key: doc.id }));
    setDataSource(salaryData);
  };

  const showModal = (record:any) => {
    setEditingRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const handleOk = async (values:any) => {
    if (editingRecord) {
      const recordRef = doc(db, 'salaryGuide', editingRecord.key);
      await updateDoc(recordRef, values);
      setDataSource(dataSource.map((item: { key: any; }) => (item.key === editingRecord.key ? { ...item, ...values } : item)));
    } else {
      const docRef = await addDoc(collection(db, 'salaryGuide'), values);
      setDataSource([...dataSource, { ...values, key: docRef.id }]);
    }
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const columns:any = [
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      fixed: 'left',
      render: (text: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined, record: any) => (
        <a onClick={() => showModal(record)}>{text}</a>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Job',
      dataIndex: 'job',
      key: 'job',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Monthly Value',
      dataIndex: 'monthValue',
      key: 'monthValue',
    },
    {
      title: 'Yearly Value',
      dataIndex: 'yearValue',
      key: 'yearValue',
    },
    {
      title: 'Translate Key',
      dataIndex: 'translate_key',
      key: 'translate_key',
      fixed: 'right',
    },
  ];

  return (
    <div>
      <Button type="primary" onClick={() => showModal(null)}>Add New Record</Button>
      <Table
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: 1300 }}
        pagination={false}
      />

      <Modal
        title={editingRecord ? "Edit Record" : "Add New Record"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={editingRecord || {}}
          onFinish={handleOk}
        >
          <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please input the content!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="currency" label="Currency" rules={[{ required: true, message: 'Please input the currency!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="job" label="Job" rules={[{ required: true, message: 'Please input the job!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="location" label="Location" rules={[{ required: true, message: 'Please input the location!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="monthValue" label="Monthly Value" rules={[{ required: true, message: 'Please input the monthly value!' }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="yearValue" label="Yearly Value" rules={[{ required: true, message: 'Please input the yearly value!' }]}>
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="translate_key" label="Translate Key" rules={[{ required: true, message: 'Please input the translate key!' }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingRecord ? "Update" : "Create"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageSalaryGuide;
