import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Layout, Card, Typography, AutoComplete, Input, Button, Space, Menu, Dropdown, Row, Col, Pagination } from "antd";
import { DownOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase"; // Import your Firebase config
import translateText from "../../constants/translator";
import "./FindUsers.css"; // Import the CSS file

const { Content } = Layout;
const { Title, Paragraph } = Typography;

type Props = {
  type: any;
  navigateLink: (query: string) => string;
  query?: string;
  url: string;
};

const SalaryInformationComponent = ({ item, index, isMobile, isDesktop, translatedTexts }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<string>(item.month ? translatedTexts[2] : translatedTexts[1]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (selectedItem: string) => {
    setSelectedValue(selectedItem);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      <Menu.Item key={translatedTexts[1]}>{translatedTexts[1]}</Menu.Item>
      <Menu.Item key={translatedTexts[2]}>{translatedTexts[2]}</Menu.Item>
      <Menu.Item key={translatedTexts[3]}>{translatedTexts[3]}</Menu.Item>
    </Menu>
  );

  const formatNumber = (num: number) => {
    return new Intl.NumberFormat().format(num);
  };

  return (
    <Card key={index} className="salary-information-card">
      <Title level={4} className="salary-information-title">
        {translatedTexts[item.translate_key]}
      </Title>
      <Paragraph className="salary-information-paragraph">
        {item.content}
      </Paragraph>
      <div className="salary-information-box">
        <div className="salary-information-text">
          {translatedTexts[4]}
        </div>
        <div className="salary-information-details">
          <Typography className="salary-information-value">
            {item.currency}
            {selectedValue === translatedTexts[2] ? formatNumber(item.monthValue) :
              selectedValue === translatedTexts[3] ? formatNumber(item.yearValue) :
              formatNumber(parseInt((parseInt(item.monthValue, 10) / (24 * 30)).toFixed(2)))}
          </Typography>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button type="text" onClick={handleClick} className="salary-information-dropdown">
              {selectedValue} <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
      <Paragraph className="salary-information-topic">
        {item.topic}
      </Paragraph>
    </Card>
  );
};

const FindUsers: FC<Props> = ({ type, query, navigateLink, url }) => {
  const [showFilter, setShowFilter] = useState(false);
  const [newQuery, setNewQuery] = useState(query ?? "");
  const [params] = useSearchParams();
  const isMobile = window.innerWidth <= 600;
  const page = params.get("page") ?? "1";
  const [newPage, setNewPage] = useState(1);
  const [jobData, setJobData] = useState<any>([]);
  const isDesktop = window.innerWidth >= 992;
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);

  useEffect(() => {
    fetchData();
    const langCode = localStorage.getItem('lanCode') || 'en';
    const fetchTranslations = async () => {
      const translations = await translateText([
        'Search job',
        'Per hour',
        'Per month',
        'Per year',
        'Average base salary',
        'New Jobs',
        'Salary Guide'
      ], langCode);
      setTranslatedTexts(translations);
    };
    fetchTranslations();
  }, []);

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "salaryGuide"));
    const data = querySnapshot.docs.map((doc) => doc.data());
    setJobData(data);
  };

  const onChange = (value: string) => {
    setNewQuery(value);
    if (value.length === 0) {
      fetchData();
    } else {
      setJobData(jobData.filter((item: any) => item.location.includes(value) || item.job?.includes(value)));
    }
    setNewPage(1);
  };

  const handleSearch = (value: string) => {
    setNewQuery(value);
    if (value.length > 0) {
      setJobData(jobData.filter((item: any) => item.location.includes(value) || item.job?.includes(value)));
    } else {
      fetchData();
    }
    setNewPage(1);
  };

  const redirectToPage = (page: number) => {
    setNewPage(page);
    const itemsPerPage = 3;
    const start = (page - 1) * itemsPerPage;
    const end = Math.min(start + itemsPerPage, jobData.length);
    setJobData(jobData.slice(start, end));
  };

  return (
    <Layout className="find-users-layout">
      <Content>
        <Row justify="space-between" align="middle" className="find-users-row">
          <Col>
            <Space>
              <Button type="link" href="/#/new-jobs" className="find-users-button">
                {translatedTexts[5]}
              </Button>
              <Button type="link" href="/#/salary-guide" className="find-users-button">
                {translatedTexts[6]}
              </Button>
            </Space>
          </Col>
          <Col span={isDesktop ? 18 : 10}>
            <AutoComplete
              value={newQuery}
              options={jobData.map((item: any) => ({ value: item.job }))}
              onChange={onChange}
              onSearch={handleSearch}
              placeholder={translatedTexts[0]}
              className="find-users-autocomplete"
            >
              <Input
                suffix={
                  newQuery ? (
                    <CloseOutlined
                      onClick={() => {
                        setNewQuery("");
                        fetchData();
                      }}
                      className="find-users-clear-icon"
                    />
                  ) : (
                    <SearchOutlined className="find-users-search-icon" />
                  )
                }
                allowClear
              />
            </AutoComplete>
          </Col>
        </Row>
        <Space direction="vertical" size="large" className="find-users-space">
          {jobData.length > 0 && jobData.map((item: any, index: any) => (
            <SalaryInformationComponent
              key={index}
              item={item}
              index={index}
              isMobile={isMobile}
              isDesktop={isDesktop}
              translatedTexts={translatedTexts}
            />
          ))}
        </Space>
        <Pagination
          total={jobData.length}
          pageSize={3}
          current={newPage}
          onChange={redirectToPage}
          className="find-users-pagination"
        />
      </Content>
    </Layout>
  );
};

export default FindUsers;
