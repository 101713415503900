import React, { useEffect, useState, useRef } from 'react';
import './RightSidePreview.css';

import CVTemplate2 from './CVTemplate2';
import CVTemplate3 from './CVTemplate3';
import CVTemplate1 from './cvTemplate1';
import CVTemplate4 from './CvTemplate4';

const RightSidePreview: React.FC<{ styleID?: number }> = ({ styleID }) => {
  const [savedData, setSavedData] = useState<any>({});
  const [scale, setScale] = useState<number>(1);
  const previewRef = useRef<HTMLDivElement>(null);
  const cvRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cvData = JSON.parse(localStorage.getItem('cvData') || '{}');
    setSavedData(cvData);

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      setSavedData(updatedData);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const adjustScale = () => {
      if (previewRef.current && cvRef.current) {
        const previewWidth = previewRef.current.clientWidth;
        const previewHeight = previewRef.current.clientHeight;
        const cvWidth = cvRef.current.scrollWidth;
        const cvHeight = cvRef.current.scrollHeight;

        const widthScale = previewWidth / cvWidth;
        const heightScale = previewHeight / cvHeight;
        const newScale = Math.min(widthScale, heightScale);

        setScale(newScale);
      }
    };

    adjustScale();

    window.addEventListener('resize', adjustScale);
    return () => {
      window.removeEventListener('resize', adjustScale);
    };
  }, [savedData]);

  const renderCV = (id: any, data: any, pageIndex: number) => {
    const styles = {
      transform: `scale(${scale})`,
      width: `${100 / scale}%`, // This ensures the content takes the full width after scaling
      height: `${100 / scale}%`, // This ensures the content takes the full height after scaling
    };

    switch (id) {
      case 1:
        return <CVTemplate1 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 2:
        return <CVTemplate2 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 3:
        return <CVTemplate3 savedData={data} pageIndex={pageIndex} styles={styles} />;
      case 4:
        return <CVTemplate4 savedData={data} pageIndex={pageIndex} styles={styles} />;
      default:
        return <CVTemplate1 savedData={data} pageIndex={pageIndex} styles={styles} />;
    }
  };

  return (
    <div className="right-side-preview">
      <div className="cv-preview-inner" ref={previewRef}>
  
          {renderCV(styleID, savedData, 0)}
      
      </div>
    </div>
  );
};

export default RightSidePreview;
