import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_JOBS } from "../../../query";
import client from "../../../applo";
import { Layout, Row, Col, Typography, Button, Space, Grid } from "antd";
import { CheckCircleOutlined } from '@ant-design/icons';
import JobCard from "../../../components/job-cards/horizontal";
import translateText from "../../../constants/translator";
import { useSnackbar } from "../../../store/providers/snackbar.provider";
import "./CVGenerate1.css"; // Import the CSS file

const { Content } = Layout;
const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const CVGenerate: FC = () => {
  const { addSnack } = useSnackbar();
  const screens = useBreakpoint();
  const isMobile = screens.xs;
  const isTablet = screens.sm;
  const isDesktop = screens.lg;
  const [jobData, setJobData] = useState<any[]>([]);
  const { loading, error, data } = useQuery(GET_JOBS, { client });
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);

  const handleTranslate = async () => {
    const language = localStorage.getItem('lanCode') || 'es';
    const textsToTranslate = [
      'How To Apply For A Dream Job Within 3 Steps',
      'Select Your Dream Job Now',
      'Create Your Great CV',
      'Send Your CV',
      'Apply Now'
    ];
    const translations = await translateText(textsToTranslate, language);
    setTranslatedTexts(translations);
  };

  useEffect(() => {
    handleTranslate();
  }, []);

  useEffect(() => {
    if (data?.jobs) {
      setJobData(data.jobs.slice(0, 3));
    }
  }, [data]);

  return (
  <div className="cvgenerate-content">
        <div className={`cvgenerate-title-container1 ${isMobile ? 'cvgenerate-mobile-padding' : ''}`}>
          <Title style={{
            color:'white',
           
          }} level={2} className="cvgenerate-title">
            {translatedTexts[0]}
          </Title>
        </div>

        <Row justify="space-between" align="middle" className={`cvgenerate-row ${isDesktop ? 'cvgenerate-desktop' : isMobile ? 'cvgenerate-mobile' : ''}`}>
          <Col xs={24} md={10} className="cvgenerate-image-col">
            <img src="/assets/girlnew.png" className="cvgenerate-image" />
          </Col>
          <Col xs={24} md={8} className="cvgenerate-text-col">
            <Space direction="vertical" size="large">
              {translatedTexts.slice(1, 4).map((text, index) => (
                <Space align="center" key={index}>
                  <CheckCircleOutlined className="cvgenerate-icon" />
                  <Paragraph className={`cvgenerate-paragraph ${isMobile ? 'cvgenerate-paragraph-mobile' : ''}`}>
                    {text}
                  </Paragraph>
                </Space>
              ))}
            </Space>
            <div className="cvgenerate-button-container">
              <Button type="primary" href="/#/cv-gen/onboarding" className="cvgenerate-button">
                {translatedTexts[4]}
              </Button>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]} justify="center" className={`cvgenerate-job-row ${isDesktop ? 'cvgenerate-job-desktop' : isMobile ? 'cvgenerate-job-mobile' : ''}`}>
          {jobData.map((card: any) => (
            <Col key={card.id} xs={24} sm={12} md={8} lg={6} xl={6} className="cvgenerate-job-col">
              <JobCard card={card} />
            </Col>
          ))}
        </Row>
        </div>
  );
};

export default CVGenerate;
