import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, Typography, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import './AdminLoginPage.css';
import { useAuth } from '../../hooks/useAuth';

const { Title } = Typography;

const AdminLoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, login } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/admin/jobs');
    }
  }, [navigate, isLoggedIn]);

  const handleLogin = (values: { email: string, password: string }) => {
    setLoading(true);
    if (login(values.email, values.password)) {
      navigate('/admin/jobs');
    } else {
      notification.error({ message: 'Login Failed', description: 'Invalid email or password' });
    }
    setLoading(false);
  };

  return (
    <div className="admin-login-container">
      <Card className="admin-login-card">
        <Title level={2} className="admin-login-title" style={{ color: 'white' }}>Admin Login</Title>
        <Form onFinish={handleLogin} className="admin-login-form">
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
            <Input placeholder="Email" className="admin-login-input" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password placeholder="Password" className="admin-login-input" />
          </Form.Item>
          <Form.Item>
            <Button type="default" htmlType="submit" loading={loading} className="admin-login-button">Login</Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AdminLoginPage;
