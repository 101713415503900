import React, { FC, useEffect, useState } from "react";
import { Layout, Row, Col, Typography, Button, Modal, Form, Input, DatePicker, Select, notification } from "antd";
import translateText from "../../constants/translator";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import "./HelpPage.css"; // Import the CSS file

const { Content } = Layout;
const { Title, Paragraph, Link } = Typography;

const HelpPage: FC = () => {
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const [modalTexts, setModalTexts] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        'How Do I Sign Up?',
        'You need to register with us using your Facebook or Gmail account by connecting either one. You can create your account with jobswomen.com by filling in your name and email with a memorable password.',
        "If you would like to reset your password, click 'Forgot Your Password?' at the bottom of the login box, enter the email address associated with your account and click Submit.",
        'Why do we want to register in our database?',
        'Rest assured, we will soon refer to the CV we send you regarding the available job vacancies in the foreign market. These vacancies are from reliable sources. With that, we hope to provide you with the most relevant job.',
        'We charge $15 only for registration, which secures your CV in our database and keeps you updated about sending it to the relevant recruitment companies and sponsors who require your experience.',
        'How long do you keep my CV?',
        'If your CV changes, send us the latest version only. One referral of your CV to us is only valid for six months. Our team directs your CV to suitable jobs for six months.',
        'How do we charge for a CV?',
        "We don't charge for the job, and there is a payment for sending the CV to the companies where your experience is required. We charge $5 only for creating one CV at a time through our CV generator.",
        'How do I know if my CV is successful?',
        'Your CV that our team sends to an organization that contacts the company directly. We have nothing to do with that agency, and you should carry out the final stage. However, we will send your CV to the most suitable job vacancies available in the market that are relevant to your experience. We do not take any responsibility if the recruit companies, any other third-party companies or agencies contact you for sponsorship or request any monetary funds to carry out job vacancies ahead; you will be solely responsible.',
        'Hire the workers you need',
        'If you are looking to hire workers or are currently facing a staff shortage in your company, kindly share the required details with us. We will advertise job opportunities on our website.',
        'Register Now',
        'Click Here'
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };

    const fetchModalTranslations = async () => {
      const modalTextsToTranslate = [
        'Full Name',
        'E-mail',
        'Address',
        'Phone number',
        'City',
        'Gender',
        'Date of Birth',
        'Register User',
        'Please enter your',
        'Invalid email address',
        'Male',
        'Female',
        'Other',
        'Save'
      ];
      const modalTranslations = await translateText(modalTextsToTranslate, langCode);
      setModalTexts(modalTranslations);
    };

    fetchTranslations();
    fetchModalTranslations();
  }, [langCode]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      await addDoc(collection(db, "registeredUsers"), values);
      notification.success({
        message: modalTexts[7],
        description: 'Your registration was successful.',
      });
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error adding document: ', error);
      notification.error({
        message: 'Error',
        description: 'There was an error submitting your registration. Please try again.',
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="help-container">
        {translatedTexts.length > 0 && (
          <>
            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[0]}</Title>
                <Paragraph className="help-page-paragraph">
                  {translatedTexts[1]} <Link href="https://www.jobswomen.com" target="_blank">jobswomen.com</Link> {translatedTexts[2]}
                </Paragraph>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/signup.png" alt="Sign Up" className="help-page-image" />
              </Col>
            </Row>

            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[3]}</Title>
                <Paragraph className="help-page-paragraph">{translatedTexts[4]}</Paragraph>
                <Paragraph className="help-page-paragraph">{translatedTexts[5]}</Paragraph>
                <div className="help-page-register">
                  <Paragraph className="help-page-paragraph">{translatedTexts[14]}</Paragraph>
                  <Button type="default" className="animated-button" onClick={showModal}>
                    {translatedTexts[15]}
                    <span className="animated-arrow">&#8594;</span>
                  </Button>
                </div>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/database.png" alt="Database" className="help-page-image" />
              </Col>
            </Row>

            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[6]}</Title>
                <Paragraph className="help-page-paragraph">{translatedTexts[7]}</Paragraph>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/cv.png" alt="CV" className="help-page-image" />
              </Col>
            </Row>

            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[8]}</Title>
                <Paragraph className="help-page-paragraph">{translatedTexts[9]}</Paragraph>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/charge.png" alt="Charge" className="help-page-image" />
              </Col>
            </Row>

            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[10]}</Title>
                <Paragraph className="help-page-paragraph">{translatedTexts[11]}</Paragraph>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/success.png" alt="Success" className="help-page-image" />
              </Col>
            </Row>

            <Row className="help-page-row" gutter={[16, 32]} align="top">
              <Col xs={24} md={10} lg={10}>
                <Title level={2} className="help-page-title">{translatedTexts[12]}</Title>
                <Paragraph className="help-page-paragraph">{translatedTexts[13]}</Paragraph>
              </Col>
              <Col md={4} lg={4}></Col>
              <Col xs={24} md={10} lg={10}>
                <img src="/assets/hire.png" alt="Hire" className="help-page-image" />
              </Col>
            </Row>
          </>
        )}
      

      <Modal
        title={modalTexts[7]}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={modalTexts[13]}
        okButtonProps={{ className: 'animated-button' }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="fullName"
                label={modalTexts[0]}
                rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[0]?.toLowerCase()}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={modalTexts[1]}
                rules={[
                  { required: true, message: `${modalTexts[8]} ${modalTexts[1]?.toLowerCase()}` },
                  { type: 'email', message: modalTexts[9] }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="address"
                label={modalTexts[2]}
                rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[2]?.toLowerCase()}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={modalTexts[3]}
                rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[3]?.toLowerCase()}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="city"
                label={modalTexts[4]}
                rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[4]?.toLowerCase()}` }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="gender"
                label={modalTexts[5]}
                rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[5]?.toLowerCase()}` }]}
              >
                <Select>
                  <Select.Option value="male">{modalTexts[10]}</Select.Option>
                  <Select.Option value="female">{modalTexts[11]}</Select.Option>
                  <Select.Option value="other">{modalTexts[12]}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="dob"
            label={modalTexts[6]}
            rules={[{ required: true, message: `${modalTexts[8]} ${modalTexts[6]?.toLowerCase()}` }]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default HelpPage;
