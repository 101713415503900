import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, InputNumber, notification, Input, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import translateText from '../../constants/translator';

const CVPrices: React.FC = () => {
  const [cvPrices, setCvPrices] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredCvPrices, setFilteredCvPrices] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchCvPrices();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add CV Price',
      'Edit CV Price',
      'Service',
      'CV Count',
      'Price ($)',
      'Action',
      'Duplicate Service Name',
      'A CV price with this service name already exists.',
      'Item deleted successfully',
      'Item updated successfully',
      'Item added successfully',
      'An error occurred while saving the item',
      'Please input the service name!',
      'Please input the CV count!',
      'Please input the price!',
      'Update',
      'Save',
      'Search...',
      'Rows per page:',
      'Edit',
      'Delete'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addCvPrice',
      'editCvPrice',
      'service',
      'cvCount',
      'price',
      'action',
      'duplicateServiceName',
      'duplicateServiceDescription',
      'itemDeleted',
      'itemUpdated',
      'itemAdded',
      'errorSaving',
      'serviceNameRequired',
      'cvCountRequired',
      'priceRequired',
      'update',
      'save',
      'searchPlaceholder',
      'rowsPerPage',
      'edit',
      'delete'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchCvPrices = async () => {
    const snapshot = await getDocs(collection(db, "cvPrices"));
    const list = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCvPrices(list);
    setFilteredCvPrices(list);
  };

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (item: any) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleDelete = async (itemId: string) => {
    await deleteDoc(doc(db, "cvPrices", itemId));
    notification.success({ message: translatedTexts.itemDeleted });
    fetchCvPrices();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      // Check for duplicate service names
      const existingItem = cvPrices.find(item => item.service === values.service && item.id !== (editingItem ? editingItem.id : null));
      if (existingItem) {
        notification.error({ message: translatedTexts.duplicateServiceName, description: translatedTexts.duplicateServiceDescription });
        return;
      }

      if (editingItem) {
        await updateDoc(doc(db, "cvPrices", editingItem.id), values);
        notification.success({ message: translatedTexts.itemUpdated });
      } else {
        await addDoc(collection(db, "cvPrices"), values);
        notification.success({ message: translatedTexts.itemAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchCvPrices();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSaving });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = cvPrices.filter((item) =>
      item.service.toLowerCase().includes(value) ||
      item.cvCount.toString().includes(value) ||
      item.price.toString().includes(value)
    );

    setFilteredCvPrices(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addCvPrice}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredCvPrices}
        columns={[
          { title: translatedTexts.service, dataIndex: 'service', key: 'service' },
          { title: translatedTexts.cvCount, dataIndex: 'cvCount', key: 'cvCount' },
          { title: translatedTexts.price, dataIndex: 'price', key: 'price', render: (price) => `$${price}` },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, item) => (
              <>
                <Button onClick={() => handleEdit(item)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(item.id)}>
                  {translatedTexts.delete}
                </Button>
              </>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingItem ? translatedTexts.editCvPrice : translatedTexts.addCvPrice}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okType="default"
        okText={editingItem ? translatedTexts.update : translatedTexts.save}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="service" label={translatedTexts.service} rules={[{ required: true, message: translatedTexts.serviceNameRequired }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="cvCount" label={translatedTexts.cvCount} rules={[{ required: true, message: translatedTexts.cvCountRequired }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="price" label={translatedTexts.price} rules={[{ required: true, message: translatedTexts.priceRequired }]}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CVPrices;
