import React, { FC } from "react";
import { Layout, Divider } from "antd";
import NavBar from "./common/nav-bar";
import Footer from "./common/footer";
import AdminNavbar from "./common/nav-bar/admin";

const { Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
}

const AdminLayout: FC<LayoutProps> = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: 'white' }}>
      <AdminNavbar />
      <Content style={{ marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center',backgroundColor:'white' }}>
        <div style={{ width: '100%', maxWidth: '1200px' }}>
          {children}
        </div>
        <Divider style={{ width: '80%', marginTop: '40px' }} />
      
      </Content>
    </Layout>
  );
};

export default AdminLayout;
