import React, { useState } from 'react';
import './Sidebar.css';

const Sidebar = ({ currentStep, steps }: any) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="toggle-button" onClick={toggleSidebar}>
        {collapsed ? '→' : '←'}
      </div>
      <div className="steps">
        {steps.map((item: any, index: any) => (
          <div key={index} className={`step ${index <= currentStep ? 'completed' : ''} ${index === currentStep ? 'current' : ''}`}>
            <div className="step-icon" data-tooltip={item.title}>
              {index < currentStep ? '✓' : index + 1}
            </div>
            {!collapsed && <div className="step-title">{item.title}</div>}
            {index < steps.length - 1 && (
              <div
                className={`step-connector ${index < currentStep ? 'solid' : 'dotted'} ${
                  index === currentStep - 1 ? 'current-to-next' : ''
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
