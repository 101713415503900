import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Typography, Space, Spin } from "antd";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../firebase";
import { GET_CVS } from "../../query";
import FindJob from "./find-job/[query2]";
import CVGenerate from "./cv-gen";
import "./salary.css"; // Import the CSS file
import client from "../../applo";

const { Content } = Layout;
const { Title } = Typography;

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const [selectedButton, setSelectedButton] = useState('salaries');
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [showSalaryJob, setShowSalaryJob] = useState(false);
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 600;
  const isDesktop = window.innerWidth >= 992;

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);

    if (buttonName === "salaries") {
      setShowSalaryJob(true);
    } else if (buttonName === "newJobs") {
      setShowSalaryJob(false);
      navigate("/new-jobs");
    }
  };

  useEffect(() => {
    if (load) {
      handleButtonClick("salaries");
      setLoad(false);
    }
  }, [load]);

  const { loading, error, data } = useQuery(GET_CVS, {
    client: client
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(false);
    }, 20000);

    return () => {
      clearTimeout(timeout);
    };
  }, [data]);

  if (loading) return <Spin size="large" />;

  return (
    <div className="homepage-container2">
     
        <FindJob />
      
      <CVGenerate />
    </div>
  );
};

export default HomePage;
