import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth } from "../../firebase";
import { GoogleOutlined, FacebookOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import translateText from "../../constants/translator";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import "./LoginPage.css"; // Import the CSS file
import { PASSWORD_REGEX } from "../../constants/regex";
import { InputField } from "../../utils/auth-types";

const LoginPage: FC = () => {
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        "Login",
        "Welcome back! Sign in to your account",
        "Email",
        "Please enter your email",
        "Enter your Email",
        "Password",
        "Please enter your password",
        "Enter your password",
        "Stay signed in",
        "Login",
        "Sign in with Facebook",
        "Sign in with Google",
        "Forgot your password?",
        "Not registered yet?",
        "Register"
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };

    fetchTranslations();
  }, [langCode]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (checked) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      navigate("/");
      addSnack?.({
        severity: "success",
        message: "You've successfully logged in",
      });
    } catch (error: any) {
      addSnack?.({
        severity: "error",
        message: error.message,
      });
    }
  };

  const signInWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      localStorage.setItem("user", JSON.stringify(user));
      addSnack?.({
        severity: "success",
        message: "You've successfully logged in",
      });
      navigate(`/`);
    } catch (error: any) {
      addSnack?.({
        severity: "error",
        message: error.message,
      });
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <h2 className="login-title">
          {translatedTexts[0]}
        </h2>
        <p className="login-description">
          {translatedTexts[1]}
        </p>
        <form className="login-form" onSubmit={submit}>
          <div className="login-form-item">
            <label htmlFor="email">{translatedTexts[2]}</label>
            <input type="email" id="email" name="email" placeholder={translatedTexts[4]} required />
          </div>
          <div className="login-form-item password-input">
            <label htmlFor="password">{translatedTexts[5]}</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder={translatedTexts[7]}
              required
            />
            <span className="toggle-password" onClick={togglePasswordVisibility}>
              {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          </div>
          <div className="login-checkbox">
            <input type="checkbox" id="stay-signed-in" checked={checked} onChange={() => setChecked(!checked)} />
            <label htmlFor="stay-signed-in">{translatedTexts[8]}</label>
          </div>
          <button style={{
            backgroundColor: '#1F6DB4',
          }} type="submit" className="login-button2">
            {translatedTexts[9]}
          </button>
       
        </form>
       <div className="buttonList">
       <button
          className="social-login-button facebook"
          onClick={() => addSnack?.({
            severity: "info",
            message: "Facebook login is not implemented yet.",
          })}
        >
          <FacebookOutlined className="icon" />
          {translatedTexts[10]}
        </button>
        <button
          className="social-login-button google"
          onClick={signInWithGoogle}
        >
          <GoogleOutlined className="icon" />
          {translatedTexts[11]}
        </button>
       </div>
      
        <Link to="/forgot-password" className="forgot-password-link">
          {translatedTexts[12]}
        </Link>
    
          <span>{translatedTexts[13]}</span>
          <Link to="/register" className="register-link">{translatedTexts[14]}</Link>
        
      </div>
    </div>
  );
};

export default LoginPage;




export const inputs: InputField[] = [
  {
    name: "email",
    validator: (email: any) => true,
    props: {
      placeholder: "Enter your Email",
      label: "Email",
      required: true,
    },
  },
  {
    name: "password",
    validator: (password: any) => PASSWORD_REGEX.test(password),
    props: {
      placeholder: "Enter your password",
      type: "password",
      required: true,
      label: "Password",
    },
  },
];

export const errorHelps: any = {
  email: {
    en: "Please enter a valid email address",
  },
  password: {
    en: "Password must be at least 8 characters long and include a number and a special character",
  },
};