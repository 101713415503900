import React, { FC, useEffect, useState } from 'react';
import { Menu, Layout, Button, Dropdown, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined, DownOutlined } from '@ant-design/icons';

import './AdminNavbar.css';
import { useAuth } from '../../../hooks/useAuth';
import translateText from '../../../constants/translator';
import LanguageSelector from '../../../components/language-selector';

const { Header } = Layout;

const AdminNavbar: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isLoggedIn, logout } = useAuth();
  const navigate = useNavigate();
  const langCode = localStorage.getItem('lanCode') || 'en';
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Profile', 'Logout', 'Login', 'Manage Jobs', 'Social Media',
      'CV Prices', 'Registration Fees', 'Admin Panel', 'Manage Locations', 'Manage Job Types','Manage Salary Guide'
    ];
    const translations = await Promise.all(textsToTranslate.map(text => translateText(text, langCode)));
    const formattedTranslations = textsToTranslate.reduce((acc, text, index) => {
      acc[text] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(formattedTranslations);
  };

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleLogout = () => {
    logout();
    navigate('/admin-login');
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="profile">
        <Link to="/profile">{translatedTexts['Profile']}</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        {translatedTexts['Logout']}
      </Menu.Item>
    </Menu>
  );

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLanguageChange = (lng: string) => {
    localStorage.setItem('lanCode', lng);
    window.location.reload(); // reload the page to apply the new language
  };

  return (
    <Header className="admin-header">
      <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col>
          <div className="logo">
            <Link to="/">
              <img src="/assets/logo.jpg" alt="Logo" />
            </Link>
          </div>
        </Col>
        <Col flex="auto">
          <div className="menu-toggle" onClick={toggleCollapsed}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['1']}
            className={`admin-menu`}
            style={{ lineHeight: '64px', justifyContent: 'flex-end' }}
          >
            <Menu.Item key="1">
              <Link to="/admin/jobs">{translatedTexts['Manage Jobs']}</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/admin/social-media">{translatedTexts['Social Media']}</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/admin/cv-prices">{translatedTexts['CV Prices']}</Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/admin/registration-fees">{translatedTexts['Registration Fees']}</Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/admin/manage-locations">{translatedTexts['Manage Locations']}</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/admin/manage-job-types">{translatedTexts['Manage Job Types']}</Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/admin/manage-salary-guide">
              {
                translatedTexts['Manage Salary Guide']
              }
              
              </Link>
            </Menu.Item>
            <Menu.Item key="8">
              <LanguageSelector onLanguageChange={handleLanguageChange} />
            </Menu.Item>
          </Menu>
        </Col>
        <Col>
          <div className="profile-menu-container">
            {isLoggedIn ? (
              <Dropdown overlay={profileMenu} trigger={['click']}>
                <Button type="link" className="profile-button">
                  {translatedTexts['Profile']} <DownOutlined />
                </Button>
              </Dropdown>
            ) : (
              <Button type="primary" className="login-button">
                <Link to="/admin-login">{translatedTexts['Login']}</Link>
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Header>
  );
};

export default AdminNavbar;
