import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RightPanel from './ExpertTips';
import "./Step5.css";

const { Title, Paragraph } = Typography;

interface Step5Props {
  handleBack: () => void;
  handleNext: () => void;
  summary: string;
  setSummary: React.Dispatch<React.SetStateAction<string>>;
  searchResults: string[];
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addSummaryPart: (part: string) => void;
  handleFieldChange: (changedValues: any, allValues: any) => void;
  fieldsStatus: { [key: string]: boolean };
}

const Step5: React.FC<Step5Props> = ({
  handleBack,
  handleNext,
  summary,
  setSummary,
  searchResults,
  handleSearch,
  addSummaryPart,
  handleFieldChange,
  fieldsStatus
}) => {
  const [editorContent, setEditorContent] = useState<string>(summary);
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const storedData = localStorage.getItem("cvData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const storedSummary = parsedData.summary || "";
      setEditorContent(storedSummary);
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem("cvData") || "{}");
      const storedSummary = updatedData.summary || "";
      setEditorContent(storedSummary);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const updateLocalStorage = (key: string, value: any) => {
    const storedData = localStorage.getItem("cvData");
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {}, summary: "" };
    cvData[key] = value;
    localStorage.setItem("cvData", JSON.stringify(cvData));
    window.dispatchEvent(new Event("storage"));
  };

  const handleBlur = (fieldName: string) => {
    setTouchedFields({
      ...touchedFields,
      [fieldName]: true,
    });
  };

  const addPartToEditor = (part: string) => {
    const updatedContent = `${editorContent}<p>${part}</p>`;
    setEditorContent(updatedContent);
    setSummary(updatedContent);
    updateLocalStorage("summary", updatedContent);
  };

  return (
    <>
      <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleBack} className="back-button">
        Go Back
      </Button>
      <RightPanel step={4} />
      <div className="page-title-wrap">
        <Title level={4} className="page-title">Briefly tell us about your background</Title>
        <Paragraph className="sub-title">Use the samples below and tailor them to fit your experience and the role</Paragraph>
      </div>
      <Row gutter={16}>
        <Col span={24} md={12}>
          <ReactQuill value={editorContent} onChange={(content) => {
            setEditorContent(content);
            setSummary(content);
            updateLocalStorage("summary", content);
          }} />
        </Col>
        <Col span={24} md={12}>
          <Title level={5}>Search by job title or keyword</Title>
          <div className="search-container">
            <Input
              size="middle"
              placeholder="Ex: Cashier"
              onBlur={() => handleBlur('search')}
              onChange={handleSearch}
              className="search-input"
              suffix={fieldsStatus.search && touchedFields.search ? <CheckCircleOutlined className="field-icon" /> : null}
            />
            <Button icon={<SearchOutlined />} />
          </div>
          <div className="search-results-container">
            {searchResults.map((result: string, index: number) => (
              <div key={index} className="search-result-item">
                <Button
                  className="search-result-button"
                  icon={<PlusOutlined className="plus-icon" />}
                  onClick={() => addPartToEditor(result)}
                >
                  {result}
                </Button>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Step5;
