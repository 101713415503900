// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "home": "Home",
        "newJobs": "New Jobs",
        "salaryGuide": "Salary Guide",
        "language": "Language",
        "help":"Help",
        "login": "Login",
        "location": "Location",
        "canada": "Canada",
        "dubai": "Dubai",
        "england": "England",
        "romania": "Romania",
        "newZealand": "New Zealand",
        "jobTypes": "Job Types",
        "cleaner": "Cleaner",
        "careAssistant": "Care Assistant",
        "driver": "Driver",
        "factoryWorker": "Factory Worker",
        "farmWorker": "Farm Worker",
        "remoteWorking": "Remote Working",
        "retail": "Retail",
        "searchYourJob": "Search Your Job",
        "filter":" Filter By Location And Types",
        "apple": "Apple",
        "fullTime": "Full Time",
        "mondayToFriday": "Monday to Friday",
        "drivingLicence": "Driving Licence",
        "easily": "Easily",
        "apply": "Apply",
        "easilyApply": "Easily Apply",
        "hiring": "Hiring",
        "multiple": "multiple",
        "candidates": "candidates",
        "hiringMultipleCandidates": "Hiring multiple candidates",
        "testingMainPoint1": "Tensting Main Point 1",
        "testingMainPoint2": "Tensting Main Point 2",
        "testingMainPoint": "Tensting Main Point",
        "posted": "Posted",
        "days": "days",
        "ago": "ago",
        "applyOnCompanySite": "Apply on company site",
        "back": "Back",
        "next": "Next",
        "howtoApplyForAJob": "How to Apply for a Job",
        "createAwesomeCVUsingOurCVGenerator": "Create awesome CV using our CV generator",
        "theCVThatGetsTheJob": "The Cv that gets the job",
        "buildANewCVIn03": "Build a new Cv in 03",
        "minutesOrImprove": "minutes or Improve",
        "yourExistingOne": "your existing one",
        "buildANewCVIn03MinutesOrImproveYourExistingOne": "Build a new Cv in 03 minutes or Improve your existing one",
        "createYourCV": "Create your CV",
        "howToCreateCVWithin03Steps": "How to Create CV within 03 steps",
        "step01": "Step 01",
        "step02": "Step 02",
        "step03": "Step 03",
        "step01Message": "Choose the sample template of your interest which represents you well among the other candidates by clicking 'View All Templates' button.",
        "step02Message": "Feed your personal, educational, professional, technical, extracurricular and other relevant information to the selected format.",
        "step03Message": "Double check your details and click 'Generate CV' button to automatically create a full sample CV",
        "chooseATemplateForYourCV": "Choose a template for your CV",
        "youCanAlwaysChangeYourTemplateLater": "You can always change your template later",
        "buildYourCV": "Build your CV",
        "hireThePeopleYouNeed": "Hire The People You Need",
        "postYourJobs": "Post Your Jobs",
        "saveWithMultipleJobs": "Save with Multiple Jobs",
        "allYouNeedIsYourEmailAddressToCreate": "All you need is your email address to create",
        "specialLinks": "Special Links",
        "contactUs": "Contact Us",
        "bottomMessage": "jobwomen offers outstanding packages for both candidates and recruiters to find jobs and employees in the most effective way. Keep in touch with us to explore your DREAM CAREER. © 2022 jobwomen. All Rights Reserved.",
        "howToApplyForADreameJobWithin3Steps": "How to Apply for a Dream Job within 3 steps",
        "selectYourDreamJobNow": "Select your Dream job now.",
        "createYourGreatCV": "Create your great CV",
        "sendYourCV":"Send your CV",
        "applyNow":"Apply Now",
        "howMuchDoesACleanerMakeInUAE":"How much does a Cleaner make in UAE?",
        "avarageBaseSalary":"Avarage base salary?",
        "perMonth":"per month",
        "perYear":"per year",
        "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Average base salary Data source tooltip for average base salary.",
        "howMuchDoesACareAssistantMakeInUAE":"How much does a Care Assistant make in UAE?",
        "leaveAMessage":"Leave a Message",
        "title":"Title",
        "firstName":"First Name",
        "lastName":"Last Name",
        "address":"Address",
        "mobile":"Mobile",
        "email":"Email",
        "jobType":"Job Type",
        "companyName":"Company Name",
        "commnetOrQuestion":"Comment or Question",
        "send":"Send",
        "selectTemplate":"Select Template",
        "addInformation":"Add Information",
        "download":"Download",
        "selectThisTemplate":"Select this Template",
        "jobTitle":"Job Title",
        "dateOfBirth":"Date of Birth",
        "personalInfo":"Personal Info",
        "showFewerOption":"Show fewer Option",
        "phone":"Phone",
        "chooseACountry":"Choose a country",
        "postalCode":"Postal Code",
        "doYouHaveDrivingLicence":"Do you have Driving Licence",
        "yes":"Yes",
        "no":"No",
        "professionalInfomation":"Professional Infomation",
        "addAllTheInformationAboutYourCareer": "Add all the information about your Career",
        "company":"Company",
        "from":"From",
        "to":"To",
        "currentlyWorkingHere":"Currently working here",
        "add":"Add",
        "educationalInformation":"Educational Information",
        "addAllTheInformationAboutYourEducationalQualifications":"Add all the information about your Educational qualifications",
        "insitute":"Insitute",
        "currentlyStudinghere":"Currently Studing here",
        "hobbies":"Hobbies",
        "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Give a brief description here of the things you like to do",
        "aboutMe":"About Me",
        "extracurricularActivities":"Extracurricular Activities",
        "description":"Description",
        "skills":"Skills",
        "skill":"Skill",
        "skillLevel":"Skill Level",
        "references":"References",
        "contactPerson":"Contact Person",
        "contactNumber":"Contact Number",
        "saveChanges":"Save Changes",
        "generate":"Generate",
        "birth":"Birth",
        "noDate":"No Date",
        "welcomeBackSignInToYourAccount":"Welcome back. Sign in to your account",
        "password":"Password",
        "staySignedIn":"Stay signed in",
        "signInFacebook":"Sign In Facebook",
        "signInGoogle":"Sign In Google",
        "forgotYourPassword":"Forgot Your Password",
        "notRegisteredYet":"Not Registered Yet",
        "register":"Register",
        "resetPassword":"Reset Password",
        "backToLogin":"Back To Login",
        "displayName":"Display Name",
        "confirmPassword":"Confirm Password",
        "signUpFacebook":"Sign Up Facebook",
        "signUpGoogle":"Sign Up Google",
    "easilyArrangeInterviews":"Easily arrange Interviews"
    
      }
    ,
    },
    ar:{
        translation:{
            "home": "الصفحة الرئيسية",
            "newJobs": "وظائف جديدة",
            "salaryGuide": "دليل الراتب",
            "language": "لغة",
            "help":"مساعدة",
            "login": "تسجيل الدخول",
            "location": "موقعك",
            "canada": "كندا",
            "dubai": "دبي",
            "england": "انجلترا",
            "romania": "رومانيا",
            "newZealand": "نيوزيلندا",
            "jobTypes": "أنواع الوظائف",
            "cleaner": "منظف",
            "filter":" تصفية حسب الموقع والأنواع",
            "careAssistant": "مساعد رعاية",
            "driver": "سائق",
            "factoryWorker": "عامل مصنع",
            "farmWorker": "عامل مزرعة",
            "remoteWorking": "العمل عن بعد",
            "retail": "تجزئة",
            "searchYourJob": "ابحث عن وظيفتك",
            "apple": "تفاحة",
            "fullTime": "وقت كامل",
            "mondayToFriday": "من الاثنين إلى الجمعة",
            "drivingLicence": "رخصة القيادة",
            "easily": "بسهولة",
            "apply": "تطبيق",
            "easilyApply": "تطبيق بسهولة",
            "hiring": "توظيف",
            "multiple": "متعدد",
            "candidates": "المرشحين",
            "hiringMultipleCandidates": "توظيف مرشحين متعددين",
            "testingMainPoint1": "نقطة الاختبار الرئيسية 1",
            "testingMainPoint2": "نقطة الاختبار الرئيسية 2",
            "testingMainPoint": "نقطة الاختبار الرئيسية",
            "posted": "نشرت",
            "days": "أيام",
            "ago": "منذ",
            "applyOnCompanySite": "تطبيق على موقع الشركة",
            "back": "عودة",
            "next": "التالى",
            "howtoApplyForAJob": "كيفية التقدم للحصول على وظيفة",
            "createAwesomeCVUsingOurCVGenerator": "إنشاء سيرة ذاتية رائعة باستخدام مولد السيرة الذاتية الخاص بنا",
            "theCVThatGetsTheJob": "السيرة الذاتية التي تحصل على الوظيفة",
            "buildANewCVIn03": "بناء سيرة ذاتية جديدة في 03",
            "minutesOrImprove": "دقائق أو تحسين",
            "yourExistingOne": "الحالي",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "بناء سيرة ذاتية جديدة في 03 دقائق أو تحسين الحالي",
            "createYourCV": "أنشئ سيرتك الذاتية",
            "howToCreateCVWithin03Steps": "كيفية إنشاء السيرة الذاتية في 03 خطوات",
            "step01": "الخطوة 01",
            "step02": "الخطوة 02",
            "step03": "الخطوة 03",
            "step01Message": "اختر قالب العينة الذي يمثلك بشكل جيد بين المرشحين الآخرين عن طريق النقر فوق زر 'عرض جميع القوالب'.",
            "step02Message": "أدخل معلوماتك الشخصية والتعليمية والمهنية والفنية واللاصقة وغيرها من المعلومات ذات الصلة إلى التنسيق المحدد.",
            "step03Message": "تحقق مرتين من تفاصيلك وانقر فوق زر 'إنشاء السيرة الذاتية' لإنشاء سيرة ذاتية كاملة تلقائيًا",
            "chooseATemplateForYourCV": "اختر قالبًا لسيرتك الذاتية",
            "youCanAlwaysChangeYourTemplateLater": "يمكنك دائمًا تغيير قالبك لاحقًا",
            "buildYourCV": "بناء سيرتك الذاتية",
            "hireThePeopleYouNeed": "توظيف الأشخاص الذين تحتاجهم",
            "postYourJobs": "نشر وظائفك",
            "saveWithMultipleJobs": "حفظ مع وظائف متعددة",
            "allYouNeedIsYourEmailAddressToCreate": "كل ما تحتاجه هو عنوان بريدك الإلكتروني لإنشاء",
            "specialLinks": "روابط خاصة",
            "contactUs": "اتصل بنا",
            "bottomMessage": "تقدم jobwomen حزمًا متميزة لكل من المرشحين وأصحاب العمل للعثور على وظائف وموظفين بأكثر الطرق فعالية. تواصل معنا لاستكشاف وظيفتك الأحلام. © 2022 jobwomen. كل الحقوق محفوظة.",
            "howToApplyForADreameJobWithin3Steps": "كيفية التقدم للحصول على وظيفة أحلامك في 3 خطوات",
            "selectYourDreamJobNow": "حدد وظيفة أحلامك الآن.",
            "createYourGreatCV": "أنشئ سيرتك الذاتية الرائعة",
            "sendYourCV":"أرسل سيرتك الذاتية",
            "applyNow":"قدم الآن",
            "howMuchDoesACleanerMakeInUAE":"كم يكسب منظف في الإمارات العربية المتحدة؟",
            "avarageBaseSalary":"الراتب الأساسي المتوسط؟",
            "perMonth":"كل شهر",
            "perYear":"كل عام",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"مصدر بيانات الراتب الأساسي المتوسط للراتب الأساسي المتوسط.",
            "howMuchDoesACareAssistantMakeInUAE":"كم يكسب مساعد الرعاية في الإمارات العربية المتحدة؟",
            "leaveAMessage":"اترك رسالة",
            "title":"عنوان",
            "firstName":"الاسم الاول",
            "lastName":"الكنية",
            "address":"عنوان",
            "mobile":"التليفون المحمول",
            "email":"البريد الإلكتروني",
            "jobType":"نوع الوظيفة",
            "companyName":"اسم الشركة",
            "commnetOrQuestion":"تعليق أو سؤال",
            "send":"إرسال",
            "selectTemplate":"اختر قالب",
            "addInformation":"أضف معلومات",
            "download":"تحميل",
            "selectThisTemplate":"حدد هذا القالب",
            "jobTitle":"مسمى الوظيفة",
            "dateOfBirth":"تاريخ الولادة",
            "personalInfo":"معلومات شخصية",
            "showFewerOption":"أظهر خيارات أقل",
            "phone":"هاتف",
            "chooseACountry":"اختر دولة",
            "postalCode":"الرمز البريدي",
            "doYouHaveDrivingLicence":"هل لديك رخصة قيادة",
            "yes":"نعم",
            "no":"لا",
            "professionalInfomation":"معلومات مهنية",
            "addAllTheInformationAboutYourCareer": "أضف جميع المعلومات حول حياتك المهنية",
            "company":"شركة",
            "from":"من عند",
            "to":"إلى",
            "currentlyWorkingHere":"أعمل حاليا هنا",
            "add":"أضف",
            "educationalInformation":"معلومات تعليمية",
            "addAllTheInformationAboutYourEducationalQualifications":"أضف جميع المعلومات حول مؤهلاتك التعليمية",
            "insitute":"معهد",
            "currentlyStudinghere":"أدرس حاليا هنا",
            "hobbies":"هوايات",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"أعط وصفا موجزا هنا للأشياء التي تحب القيام بها",
            "aboutMe":"عني",
            "extracurricularActivities":"أنشطة خارجية للمناهج الدراسية",
            "description":"وصف",
            "skills":"مهارات",
            "skill":"مهارة",
            "skillLevel":"مستوى المهارة",
            "references":"مراجع",
            "contactPerson":"شخص الاتصال",
            "contactNumber":"رقم الاتصال",
            "saveChanges":"حفظ التغييرات",
            "generate":"توليد",
            "birth":"ميلاد",
            "noDate":"لا تاريخ",
            "welcomeBackSignInToYourAccount":"مرحبا بعودتك. تسجيل الدخول إلى حسابك",
            "password":"كلمه السر",
            "staySignedIn":"ابقى مسجلا",
            "signInFacebook":"تسجيل الدخول الفيسبوك",
            "signInGoogle":"تسجيل الدخول جوجل",
            "forgotYourPassword":"نسيت كلمة المرور",
            "notRegisteredYet":"لم يتم التسجيل بعد",
            "register":"تسجيل",
            "resetPassword":"إعادة تعيين كلمة المرور",
            "backToLogin":"العودة لتسجيل الدخول",
            "displayName":"اسم العرض",
            "confirmPassword":"تأكيد كلمة المرور",
            "signUpFacebook":"سجل الدخول إلى Facebook",
            "signUpGoogle":"سجل الدخول إلى Google",
            "easilyArrangeInterviews":"ترتيب المقابلات بسهولة"

        }
    },
    hi : {
        translation:{
            "home": "घर",
            "newJobs": "नई नौकरियां",
            "salaryGuide": "वेतन गाइड",
            "language": "भाषा",
            "help":"मदद",
            "login": "लॉग इन करें",
            "location": "स्थान",
            "canada": "कनाडा",
            "dubai": "दुबई",
            "england": "इंग्लैंड",
            "romania": "रोमानिया",
            "newZealand": "न्यूजीलैंड",
            "jobTypes": "नौकरियों के प्रकार",
            "cleaner": "क्लीनर",
            "careAssistant": "केयर असिस्टेंट",
            "driver": "ड्राइवर",
            "factoryWorker": "फैक्ट्री कर्मचारी",
            "farmWorker": "फार्म कार्यकर्ता",
            "filter":" स्थान और प्रकार द्वारा फ़िल्टर करें",
            "remoteWorking": "रिमोट वर्किंग",
            "retail": "खुदरा",
            "searchYourJob": "अपनी नौकरी खोजें",
            "apple": "सेब",
            "fullTime": "पूर्ण समय",
            "mondayToFriday": "सोमवार से शुक्रवार",
            "drivingLicence": "ड्राइविंग लाइसेंस",
            "easily": "आसानी से",
            "apply": "लागू करें",
            "easilyApply": "आसानी से लागू करें ",
            "hiring": "भर्ती",
            "multiple": "एकाधिक",
            "candidates": "उम्मीदवार",
            "hiringMultipleCandidates": "एकाधिक उम्मीदवार भर्ती",
            "testingMainPoint1": "टेस्टिंग मुख्य बिंदु 1",
            "testingMainPoint2": "टेस्टिंग मुख्य बिंदु 2",
            "testingMainPoint": "टेस्टिंग मुख्य बिंदु",
            "posted": "पोस्ट किया गया",
            "days": "दिन",
            "ago": "पहले",
            "applyOnCompanySite": "कंपनी की साइट पर लागू करें",
            "back": "वापस",
            "next": "आगामी",
            "howtoApplyForAJob": "नौकरी के लिए आवेदन कैसे करें",
            "createAwesomeCVUsingOurCVGenerator": "हमारे सीवी जेनरेटर का उपयोग करके अद्भुत सीवी बनाएं",
            "theCVThatGetsTheJob": "नौकरी पाने वाली सीवी",
            "buildANewCVIn03": "03 में एक नया सीवी बनाएं",
            "minutesOrImprove": "मिनट या सुधारें",
            "yourExistingOne": "आपका मौजूदा",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "03 मिनट में एक नया सीवी बनाएं या अपना मौजूदा सुधारें",
            "createYourCV": "अपना सीवी बनाएं",
            "howToCreateCVWithin03Steps": "03 कदमों में सीवी कैसे बनाएं",
            "step01": "चरण 01",
            "step02": "चरण 02",
            "step03": "चरण 03",
            "step01Message": "चयनित फॉर्मेट में अपनी व्यक्तिगत, शैक्षिक, पेशेवर, तकनीकी, अतिरिक्त और अन्य संबंधित जानकारी दर्ज करें।",
            "step02Message": "चयनित फॉर्मेट में अपनी व्यक्तिगत, शैक्षिक, पेशेवर, तकनीकी, अतिरिक्त और अन्य संबंधित जानकारी दर्ज करें।",
            "step03Message": "अपना विवरण दोहरी जांचें और 'सीवी जेनरेट करें' बटन पर क्लिक करें ताकि स्वचालित रूप से एक पूर्ण नमूना सीवी बनाई जा सके",
            "chooseATemplateForYourCV": "अपने सीवी के लिए एक टेम्पलेट चुनें",
            "youCanAlwaysChangeYourTemplateLater": "आप अपना टेम्पलेट बाद में हमेशा बदल सकते हैं",
            "buildYourCV": "अपना सीवी बनाएं",
            "hireThePeopleYouNeed": "आपको जरूरत है लोगों को किराए पर लेना",
            "postYourJobs": "अपनी नौकरियों को पोस्ट करें",
            "saveWithMultipleJobs": "एकाधिक नौकरियों के साथ बचाओ",
            "allYouNeedIsYourEmailAddressToCreate": "सीखने के लिए आपको अपना ईमेल पता चाहिए",
            "specialLinks": "विशेष लिंक",
            "contactUs": "संपर्क करें",
            "bottomMessage": "jobwomen उम्मीदवारों और भर्तीकर्ताओं दोनों के लिए नौकरियों और कर्मचारियों को सबसे प्रभावी तरीके से खोजने के लिए उत्कृष्ट पैकेज प्रदान करता है। अपने सपने करियर की खोज के लिए हमसे संपर्क में रहें। © 2022 jobwomen। सर्वाधिकार सुरक्षित।",
            "howToApplyForADreameJobWithin3Steps": "3 कदमों में एक सपने की नौकरी के लिए आवेदन कैसे करें",
            "selectYourDreamJobNow": "अपनी सपने की नौकरी अब चुनें।",
            "createYourGreatCV": "अपनी शानदार सीवी बनाएं",
            "sendYourCV":"अपनी सीवी भेजें",
            "applyNow":"अभी आवेदन करें",
            "howMuchDoesACleanerMakeInUAE":"एक क्लीनर अरब अमीरात में कितना कमाता है?",
            "avarageBaseSalary":"औसत बेस वेतन?",
            "perMonth":"प्रति माह",
            "perYear":"प्रति वर्ष",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"औसत बेस वेतन के लिए औसत बेस वेतन डेटा स्रोत टूलटिप।",
            "howMuchDoesACareAssistantMakeInUAE":"एक केयर असिस्टेंट अरब अमीरात में कितना कमाता है?",
            "leaveAMessage":"संदेश छोड़ें",
            "title":"शीर्षक",
            "firstName":"पहला नाम",
            "lastName":"उपनाम",
            "address":"पता",
            "mobile":"मोबाइल",
            "email":"ईमेल",
            "jobType":"नौकरी का प्रकार",
            "companyName":"कंपनी का नाम",
            "commnetOrQuestion":"टिप्पणी या प्रश्न",
            "send":"भेजें",
            "selectTemplate":"टेम्पलेट चुनें",
            "addInformation":"जानकारी जोड़ें",
            "download":"डाउनलोड",
            "selectThisTemplate":"इस टेम्पलेट का चयन करें",
            "jobTitle":"नौकरी का शीर्षक",
            "dateOfBirth":"जन्म की तारीख",
            "personalInfo":"व्यक्तिगत जानकारी",
            "showFewerOption":"कम विकल्प दिखाएं",
            "phone":"फ़ोन",
            "chooseACountry":"एक देश चुनें",
            "postalCode":"पोस्टल कोड",
            "doYouHaveDrivingLicence":"क्या आपके पास ड्राइविंग लाइसेंस है",
            "yes":"हाँ",
            "no":"नहीं",
            "professionalInfomation":"पेशेवर जानकारी",
            "addAllTheInformationAboutYourCareer": "अपने करियर के बारे में सभी जानकारी जोड़ें",
            "company":"कंपनी",
            "from":"से",
            "to":"सेवा में",
            "currentlyWorkingHere":"वर्तमान में यहां काम कर रहा हूँ",
            "add":"जोड़ना",
            "educationalInformation":"शैक्षणिक जानकारी",
            "addAllTheInformationAboutYourEducationalQualifications":"अपनी शैक्षणिक योग्यता के बारे में सभी जानकारी जोड़ें",
            "insitute":"संस्थान",
            "currentlyStudinghere":"वर्तमान में यहां अध्ययन कर रहा हूँ",
            "hobbies":"शौक",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"जिन चीजों को आप करना पसंद करते हैं, उनका एक संक्षिप्त विवरण यहां दें",
            "aboutMe":"मेरे बारे में",
            "extracurricularActivities":"बाहरी गतिविधियाँ",
            "description":"विवरण",
            "skills":"कौशल",
            "skill":"कौशल",
            "skillLevel":"कौशल स्तर",
            "references":"संदर्भ",
            "contactPerson":"संपर्क व्यक्ति",
            "contactNumber":"संपर्क नंबर",
            "saveChanges":"परिवर्तनों को सुरक्षित करें",
            "generate":"उत्पन्न करना",
            "birth":"जन्म",
            "noDate":"कोई तिथि नहीं",
            "welcomeBackSignInToYourAccount":"वापसी पर स्वागत है। अपने खाते में साइन इन करें",
            "password":"कुंजिका",
            "staySignedIn":"साइन इन रहो",
            "signInFacebook":"फेसबुक पर साइन इन करें",
            "signInGoogle":"Google पर साइन इन करें",
            "forgotYourPassword":"क्या आपने अपना पासवर्ड भूल गए",
            "notRegisteredYet":"अभी तक पंजीकृत नहीं",
            "register":"रजिस्टर",
            "resetPassword":"पासवर्ड रीसेट करें",
            "backToLogin":"लॉगिन पर वापस जाएं",
            "displayName":"प्रदर्शन नाम",
            "confirmPassword":"पासवर्ड की पुष्टि कीजिये",
            "signUpFacebook":"फेसबुक पर साइन अप करें",
            "signUpGoogle":"Google पर साइन अप करें",
            "easilyArrangeInterviews":"साक्षात्कार आसानी से व्यवस्थित करें"










        }
        ,
        
       
    },
    ur:{
        translation:{
            "home": "گھر",
            "newJobs": "نئی نوکریاں",
            "salaryGuide": "تنخواہ کی گائیڈ",
            "language": "زبان",
            "help":"مدد",
            "login": "لاگ ان کریں",
            "filter":" فلٹر کریں",
            "location": "مقام",
            "canada": "کینیڈا",
            "dubai": "دبئی",
            "england": "انگلینڈ",
            "romania": "رومانیہ",
            "newZealand": "نیوزی لینڈ",
            "jobTypes": "نوکریوں کی اقسام",
            "cleaner": "صاف کرنے والا",
            "careAssistant": "محنت کش اسسٹنٹ",
            "driver": "ڈرائیور",
            "factoryWorker": "فیکٹری کا کارکن",
            "farmWorker": "فارم ورکر",
            "remoteWorking": "ریموٹ ورکنگ",
            "retail": "خوردہ فروشی",
            "searchYourJob": "اپنی نوکری تلاش کریں",
            "apple": "سیب",
            "fullTime": "مکمل وقت",
            "mondayToFriday": "پیر سے جمعرات",
            "drivingLicence": "ڈرائیونگ لائسنس",
            "easily": "آسانی سے",
            "apply": "لگائیں",
            "easilyApply": "آسانی سے لگائیں",
            "hiring": "اجرت پر لینا",
            "multiple": "متعدد",
            "candidates": "امیدواروں",
            "hiringMultipleCandidates": "متعدد امیدواروں کی تعیناتی",
            "testingMainPoint1": "ٹیسٹنگ مین پوائنٹ 1",
            "testingMainPoint2": "ٹیسٹنگ مین پوائنٹ 2",
            "testingMainPoint": "ٹیسٹنگ مین پوائنٹ",
            "posted": "پوسٹ کیا گیا",
            "days": "دن",
            "ago": "پہلے",
            "applyOnCompanySite": "کمپنی کی سائٹ پر لاگو کریں",
            "back": "واپس",
            "next": "اگلا",
            "howtoApplyForAJob": "نوکری کے لئے درخواست کیسے دیں",
            "createAwesomeCVUsingOurCVGenerator": "ہمارے سی وی جنریٹر کا استعمال کرتے ہوئے شاندار سی وی بنائیں",
            "theCVThatGetsTheJob": "نوکری حاصل کرنے والی سی وی",
            "buildANewCVIn03": "03 میں ایک نیا سی وی بنائیں",
            "minutesOrImprove": "منٹ یا بہتر کریں",
            "yourExistingOne": "آپ کا موجودہ",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "03 منٹ میں ایک نیا سی وی بنائیں یا اپنا موجودہ بہتر کریں",
            "createYourCV": "اپنا سی وی بنائیں",
            "howToCreateCVWithin03Steps": "03 قدموں میں سی وی کیسے بنائیں",
            "step01": "قدم 01",
            "step02": "قدم 02",
            "step03": "قدم 03",
            "step01Message": "مندرجہ ذیل فارمیٹ میں اپنی ذاتی، تعلیمی، پیشہ ورانہ، ٹیکنیکل، اضافی اور دیگر متعلقہ معلومات شامل کریں۔",
            "step02Message": "مندرجہ ذیل فارمیٹ میں اپنی ذاتی، تعلیمی، پیشہ ورانہ، ٹیکنیکل، اضافی اور دیگر متعلقہ معلومات شامل کریں۔",
            "step03Message": "اپنی تفصیلات دوبارہ دیکھیں اور 'سی وی جنریٹ کریں' بٹن پر کلک کریں تاکہ آپکی سی وی خود کار مکمل سی وی بنا سکے",
            "chooseATemplateForYourCV": "اپنی سی وی کے لئے ایک ٹیمپلیٹ منتخب کریں",
            "youCanAlwaysChangeYourTemplateLater": "آپ بعد میں ہمیشہ اپنا ٹیمپلیٹ تبدیل کرسکتے ہیں",
            "buildYourCV": "اپنی سی وی بنائیں",
            "hireThePeopleYouNeed": "آپ کو ضرورت ہے لوگوں کو کرایہ پر لینا",
            "postYourJobs": "اپنی نوکریاں پوسٹ کریں",
            "saveWithMultipleJobs": "متعدد نوکریوں کے ساتھ بچائو",
            "allYouNeedIsYourEmailAddressToCreate": "آپ کو اپنا ای میل ایڈریس بنانے کی ضرورت ہے",
            "specialLinks": "خصوصی لنکس",
            "contactUs": "ہم سے رابطہ کریں",
            "bottomMessage": "jobwomen امیدواروں اور بھرتی کنندگان دونوں کے لئے نوکریاں اور ملازمین کو سب سے زیادہ موثر طریقے سے تلاش کرنے کے لئے بہترین پیکیجز فراہم کرتا ہے۔ اپنی خوابوں کی کیریئر کی تلاش کے لئے ہم سے رابطہ کریں۔ © 2022 jobwomen۔ تمام حقوق محفوظ ہیں۔",
            "howToApplyForADreameJobWithin3Steps": "3 قدموں میں ایک خواب کی نوکری کے لئے درخواست کیسے دیں",
            "selectYourDreamJobNow": "اب اپنی خواب کی نوکری منتخب کریں۔",
            "createYourGreatCV": "اپنی شاندار سی وی بنائیں",
            "sendYourCV":"اپنی سی وی بھیجیں",
            "applyNow":"اب درخواست دیں",
            "howMuchDoesACleanerMakeInUAE":"ایک صاف کرنے والے کو امارات میں کتنا معاش ملتا ہے؟",
            "avarageBaseSalary":"اوسط بیس تنخواہ؟",
            "perMonth":"فی ماہ",
            "perYear":"فی سال",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"اوسط بیس تنخواہ کے لئے اوسط بیس تنخواہ ڈیٹا سورس ٹول ٹپ۔",
            "howMuchDoesACareAssistantMakeInUAE":"ایک کیر اسسٹنٹ کو امارات میں کتنا معاش ملتا ہے؟",
            "leaveAMessage":"ایک پیغام چھوڑیں",
            "title":"عنوان",
            "firstName":"پہلا نام",
            "lastName":"آخری نام",
            "address":"پتہ",
            "mobile":"موبائل",
            "email":"ای میل",
            "jobType":"نوکری کی قسم",

            "companyName":"کمپنی کا نام",
            "commnetOrQuestion":"تبصرہ یا سوال",
            "send":"بھیجیں",
            "selectTemplate":"ٹیمپلیٹ منتخب کریں",
            "addInformation":"معلومات شامل کریں",
            "download":"ڈاؤن لوڈ",
            "selectThisTemplate":"اس ٹیمپلیٹ کو منتخب کریں",
            "jobTitle":"نوکری کا عنوان",
            "dateOfBirth":"تاریخ پیدائش",

            "personalInfo":"ذاتی معلومات",
            "showFewerOption":"کم اختیارات دکھائیں",
            "phone":"فون",
            "chooseACountry":"ایک ملک منتخب کریں",
            "postalCode":"پوسٹل کوڈ",
            "doYouHaveDrivingLicence":"کیا آپ کے پاس ڈرائیونگ لائسنس ہے",
            "yes":"جی ہاں",
            "no":"نہیں",
            "professionalInfomation":"پیشہ ورانہ معلومات",
            "addAllTheInformationAboutYourCareer": "اپنے کیریئر کے بارے میں تمام معلومات شامل کریں",
            "company":"کمپنی",
            "from":"سے",
            "to":"تک",
            "currentlyWorkingHere":"موجودہ طور پر یہاں کام کر رہا ہوں",
            "add":"شامل کریں",
            "educationalInformation":"تعلیمی معلومات",
            "addAllTheInformationAboutYourEducationalQualifications":"اپنی تعلیمی صلاحیت کے بارے میں تمام معلومات شامل کریں",
            "insitute":"انسٹیٹیوٹ",
            "currentlyStudinghere":"موجودہ طور پر یہاں پڑھ رہا ہوں",
            "hobbies":"شوق",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"وہ چیزیں جو آپ کرنا پسند کرتے ہیں ان کی ایک مختصر تفصیل یہاں دیں",
            "aboutMe":"میرے بارے میں",
            "extracurricularActivities":"بیرونی محاذ کاری",
            "description":"تفصیل",
            "skills":"مہارتیں",
            "skill":"مہارت",
            "skillLevel":"مہارت کا سطح",
            "references":"حوالہ جات",
            "contactPerson":"رابطہ شخص",
            "contactNumber":"رابطہ نمبر",
            "saveChanges":"تبدیلیاں محفوظ کریں",
            "generate":"پیدا کرنا",
            "birth":"پیدائش",
            "noDate":"کوئی تاریخ نہیں",
            "welcomeBackSignInToYourAccount":"واپس خوش آمدید۔ اپنے اکاؤنٹ میں سائن ان کریں",
            "password":"پاس ورڈ",
            "staySignedIn":"سائن ان میں رہو",
            "signInFacebook":"فیس بک میں سائن ان کریں",
            "signInGoogle":"گوگل میں سائن ان کریں",
            "forgotYourPassword":"کیا آپ اپنا پاس ورڈ بھول گئے",
            "notRegisteredYet":"ابھی تک رجسٹر نہیں ہوا",
            "register":"رجسٹر کریں",
            "resetPassword":"پاس ورڈ دوبارہ ترتیب دیں",
            "backToLogin":"لوگ ان پر واپس جائیں",
            "displayName":"نمائشی نام",
            "confirmPassword":"پاس ورڈ کی تصدیق کریں",
            "signUpFacebook":"فیس بک پر سائن اپ کریں",
            "signUpGoogle":"گوگل پر سائن اپ کریں",
            "easilyArrangeInterviews":"آسانی سے انٹرویو کی تقریبات کا انتظام کریں"
            


        }
    },
    zh:{
        translation:{
            "home": "家",
            "newJobs": "新工作",
            "salaryGuide": "薪水指南",
            "language": "语言",
            "help":"帮帮我",
            "login": "登录",
            "filter":"过滤",
            "location": "地点",
            "canada": "加拿大",
            "dubai": "迪拜",
            "england": "英国",
            "romania": "罗马尼亚",
            "newZealand": "新西兰",
            "jobTypes": "工作类型",
            "cleaner": "清洁工",
            "careAssistant": "护理助理",
            "driver": "司机",
            "factoryWorker": "工厂工人",
            "farmWorker": "农场工人",
            "remoteWorking": "远程工作",
            "retail": "零售",
            "searchYourJob": "搜索您的工作",
            "apple": "苹果",
            "fullTime": "全职",
            "mondayToFriday": "星期一至星期五",
            "drivingLicence": "驾驶执照",
            "easily": "容易地",
            "apply": "应用",
            "easilyApply": "轻松申请",
            "hiring": "雇用",
            "multiple": "多个",
            "candidates": "候选人",
            "hiringMultipleCandidates": "雇用多个候选人",
            "testingMainPoint1": "测试主要点1",
            "testingMainPoint2": "测试主要点2",
            "testingMainPoint": "测试主要点",
            "posted": "发表",
            "days": "天",
            "ago": "前",
            "applyOnCompanySite": "在公司网站上申请",
            "back": "背部",
            "next": "下一个",
            "howtoApplyForAJob": "如何申请工作",
            "createAwesomeCVUsingOurCVGenerator": "使用我们的简历生成器 创建令人敬畏的简历",
            "theCVThatGetsTheJob": "得到工作的简历",
            "buildANewCVIn03": "在03中建立新的简历",
            "minutesOrImprove": "分钟或改进",
            "yourExistingOne": "您现有的",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "在03分钟内建立新的简历或改进现有的简历",
            "createYourCV": "创建您的简历",
            "howToCreateCVWithin03Steps": "如何在03步骤内创建简历",
            "step01": "步骤01",
            "step02": "步骤02",
            "step03": "步骤03",
            "step01Message": "在所选格式中输入有关您的个人，教育，职业，技术，额外和其他相关信息。",
            "step02Message": "在所选格式中输入有关您的个人，教育，职业，技术，额外和其他相关信息。",
            "step03Message": "仔细检查您的详细信息，然后单击“生成简历”按钮，以便自动生成完整的简历",
            "chooseATemplateForYourCV": "为您的简历选择模板",
            "youCanAlwaysChangeYourTemplateLater": "您随时可以稍后更改模板",
            "buildYourCV": "建立您的简历",
            "hireThePeopleYouNeed": "雇用您需要的人",
            "postYourJobs": "发布您的工作",
            "saveWithMultipleJobs": "与多个工作一起保存",
            "allYouNeedIsYourEmailAddressToCreate": "您所需的只是您的电子邮件地址",
            "specialLinks": "特别链接",
            "contactUs": "联系我们",
            "bottomMessage": "jobwomen为候选人和雇主提供了最有效的方式来查找工作和雇员。 与我们联系以寻找您的梦想职业。 © 2022 jobwomen。 版权所有。",
            "howToApplyForADreameJobWithin3Steps": "如何在3步内申请梦想工作",
            "selectYourDreamJobNow": "现在选择您的梦想工作。",
            "createYourGreatCV": "创建您的伟大简历",
            "sendYourCV":"发送您的简历",
            "applyNow":"现在申请",
            "howMuchDoesACleanerMakeInUAE":"清洁工在阿联酋赚多少钱？",
            "avarageBaseSalary":"平均基本工资？",
            "perMonth":"每月",
            "perYear":"每年",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"平均基本工资的平均基本工资数据源工具提示。",
            "howMuchDoesACareAssistantMakeInUAE":"护理助理在阿联酋赚多少钱？",
            "leaveAMessage":"留言",
            "title":"标题",
            "firstName":"名字",
            "lastName":"姓",
            "address":"地址",
            "mobile":"移动",
            "email":"电子邮件",
            "jobType":"工作类型",
            "companyName":"公司名",
            "commnetOrQuestion":"评论或问题",
            "send":"发送",
            "selectTemplate":"选择模板",
            "addInformation":"添加信息",
            "download":"下载",
            "selectThisTemplate":"选择此模板",
            "jobTitle":"职位",
            "dateOfBirth":"出生日期",
            "personalInfo":"个人信息",
            "showFewerOption":"显示更少选项",
            "phone":"电话",
            "chooseACountry":"选择一个国家",
            "postalCode":"邮政编码",
            "doYouHaveDrivingLicence":"你有驾照吗",
            "yes":"是",
            "no":"没有",
            "professionalInfomation":"专业信息",
            "addAllTheInformationAboutYourCareer": "添加有关您职业生涯的所有信息",
            "company":"公司",
            "from":"从",
            "to":"至",
            "currentlyWorkingHere":"目前在这里工作",
            "add":"加",
            "educationalInformation":"教育信息",
            "addAllTheInformationAboutYourEducationalQualifications":"添加有关您的教育资格的所有信息",
            "insitute":"学院",
            "currentlyStudinghere":"目前在这里学习",
            "hobbies":"爱好",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"在此处简要描述您喜欢做的事情",
            "aboutMe":"关于我",
            "extracurricularActivities":"课外活动",
            "description":"描述",
            "skills":"技能",
            "skill":"技能",
            "skillLevel":"技能水平",
            "references":"参考",
            "contactPerson":"联系人",
            "contactNumber":"联系电话",
            "saveChanges":"保存更改",
            "generate":"生成",
            "birth":"出生",
            "noDate":"没有日期",
            "welcomeBackSignInToYourAccount":"欢迎回来。 登录您的帐户",
            "password":"密码",
            "staySignedIn":"保持登录",
            "signInFacebook":"登录Facebook",
            "signInGoogle":"登录Google",
            "forgotYourPassword":"忘记密码",
            "notRegisteredYet":"还没有注册",
            "register":"寄存器",
            "resetPassword":"重设密码",
            "backToLogin":"回到登录",
            "displayName":"显示名称",
            "confirmPassword":"确认密码",
            "signUpFacebook":"在Facebook上注册",
            "signUpGoogle":"在Google上注册",
            "easilyArrangeInterviews":"轻松安排面试"


    }
    },
    de:{

        translation:{

            "home": "Zuhause",
            "newJobs": "Neue Jobs",
            "salaryGuide": "Gehaltsführer",
            "language": "Sprache",
            "help":"Hilfe",
            "login": "Anmeldung",
            "filter":"Filter",
            "location": "Ort",
            "canada": "Kanada",
            "dubai": "Dubai",
            "england": "England",
            "romania": "Rumänien",
            "newZealand": "Neuseeland",
            "jobTypes": "Jobtypen",
            "cleaner": "Reiniger",
            "careAssistant": "Pflegeassistent",
            "driver": "Fahrer",
            "factoryWorker": "Fabrikarbeiter",
            "farmWorker": "Farmarbeiter",
            "remoteWorking": "Fernarbeit",
            "retail": "Einzelhandel",
            "searchYourJob": "Suchen Sie Ihren Job",
            "apple": "Apfel",
            "fullTime": "Vollzeit",
            "mondayToFriday": "Montag bis Freitag",
            "drivingLicence": "Führerschein",
            "easily": "Leicht",
            "apply": "Anwenden",
            "easilyApply": "Einfach bewerben",
            "hiring": "Einstellung",
            "multiple": "Mehrere",
            "candidates": "Kandidaten",
            "hiringMultipleCandidates": "Einstellung mehrerer Kandidaten",
            "testingMainPoint1": "Testen Sie den Hauptpunkt 1",
            "testingMainPoint2": "Testen Sie den Hauptpunkt 2",
            "testingMainPoint": "Testen Sie den Hauptpunkt",
            "posted": "Gepostet",
            "days": "Tage",
            "ago": "Vor",
            "applyOnCompanySite": "Bewerben Sie sich auf der Unternehmenswebsite",
            "back": "Zurück",
            "next": "Nächster",
            "howtoApplyForAJob": "Wie bewerbe ich mich um einen Job",
            "createAwesomeCVUsingOurCVGenerator": "Erstellen Sie mit unserem Lebenslauf-Generator einen großartigen Lebenslauf ",
            "theCVThatGetsTheJob": "Der Lebenslauf, der den Job bekommt",
            "buildANewCVIn03": "Erstellen Sie in 03 einen neuen Lebenslauf",
            "minutesOrImprove": "Minuten oder verbessern",
            "yourExistingOne": "Ihr bestehendes",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "Erstellen Sie in 03 Minuten einen neuen Lebenslauf oder verbessern Sie Ihren vorhandenen Lebenslauf",
            "createYourCV": "Erstellen Sie Ihren Lebenslauf",
            "howToCreateCVWithin03Steps": "So erstellen Sie einen Lebenslauf in 03 Schritten",
            "step01": "Schritt 01",
            "step02": "Schritt 02",
            "step03": "Schritt 03",
            "step01Message": "Geben Sie Ihre persönlichen, Bildungs-, Berufs-, technischen, zusätzlichen und anderen relevanten Informationen in das ausgewählte Format ein.",
            "step02Message": "Geben Sie Ihre persönlichen, Bildungs-, Berufs-, technischen, zusätzlichen und anderen relevanten Informationen in das ausgewählte Format ein.",
            "step03Message": "Überprüfen Sie Ihre Details sorgfältig und klicken Sie auf die Schaltfläche 'Lebenslauf generieren', damit Ihr Lebenslauf automatisch einen vollständigen Lebenslauf generieren kann",
            "chooseATemplateForYourCV": "Wählen Sie eine Vorlage für Ihren Lebenslauf",
            "youCanAlwaysChangeYourTemplateLater": "Sie können Ihre Vorlage jederzeit später ändern",
            "buildYourCV": "Erstellen Sie Ihren Lebenslauf",
            "hireThePeopleYouNeed": "Stellen Sie die Leute ein, die Sie brauchen",
            "postYourJobs": "Veröffentlichen Sie Ihre Jobs",
            "saveWithMultipleJobs": "Mit mehreren Jobs speichern",
            "allYouNeedIsYourEmailAddressToCreate": "Alles, was Sie brauchen, ist Ihre E-Mail-Adresse",
            "specialLinks": "Spezielle Links",
            "contactUs": "Kontaktiere uns",
            "bottomMessage": "jobwomen bietet sowohl Kandidaten als auch Arbeitgebern die effektivste Möglichkeit, Jobs und Mitarbeiter zu finden. Kontaktieren Sie uns, um Ihren Traumjob zu finden. © 2022 jobwomen. Alle Rechte vorbehalten.",
            "howToApplyForADreameJobWithin3Steps": "So bewerben Sie sich in 3 Schritten um einen Traumjob",
            "selectYourDreamJobNow": "Wählen Sie jetzt Ihren Traumjob.",
            "createYourGreatCV": "Erstellen Sie Ihren großartigen Lebenslauf",
            "sendYourCV":"Senden Sie Ihren Lebenslauf",
            "applyNow":"Jetzt bewerben",
            "howMuchDoesACleanerMakeInUAE":"Wie viel verdient ein Reiniger in den VAE?",
            "avarageBaseSalary":"Durchschnittliches Grundgehalt?",
            "perMonth":"Pro Monat",
            "perYear":"Pro Jahr",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Durchschnittliches Grundgehalt Datenquellen-Tooltip für durchschnittliches Grundgehalt.",
            "howMuchDoesACareAssistantMakeInUAE":"Wie viel verdient ein Pflegeassistent in den VAE?",
            "leaveAMessage":"Hinterlasse eine Nachricht",
            "title":"Titel",
            "firstName":"Vorname",
            "lastName":"Familienname",
            "address":"Adresse",
            "mobile":"Handy, Mobiltelefon",
            "email":"Email",
            "jobType":"Jobtyp",
            "companyName":"Firmenname",
            "commnetOrQuestion":"Kommentar oder Frage",
            "send":"Senden",
            "selectTemplate":"Vorlage auswählen",
            "addInformation":"Informationen hinzufügen",
            "download":"Herunterladen",
            "selectThisTemplate":"Diese Vorlage auswählen",
            "jobTitle":"Berufsbezeichnung",
            "dateOfBirth":"Geburtsdatum",
            "personalInfo":"Persönliche Informationen",
            "showFewerOption":"Weniger Optionen anzeigen",
            "phone":"Telefon",
            "chooseACountry":"Wähle ein Land",
            "postalCode":"Postleitzahl",
            "doYouHaveDrivingLicence":"Hast du einen Führerschein",
            "yes":"Ja",
            "no":"Nein",
            "professionalInfomation":"Berufsinformation",
            "addAllTheInformationAboutYourCareer": "Fügen Sie alle Informationen zu Ihrer Karriere hinzu",
            "company":"Unternehmen",
            "from":"Von",
            "to":"Zu",
            "currentlyWorkingHere":"Derzeit hier arbeiten",
            "add":"Hinzufügen",
            "educationalInformation":"Bildungsinformation",
            "addAllTheInformationAboutYourEducationalQualifications":"Fügen Sie alle Informationen zu Ihren Bildungsqualifikationen hinzu",
            "insitute":"Institut",
            "currentlyStudinghere":"Derzeit hier studieren",
            "hobbies":"Hobbys",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Geben Sie hier eine kurze Beschreibung der Dinge ein, die Sie gerne tun",
            "aboutMe":"Über mich",
            "extracurricularActivities":"Außerschulische Aktivitäten",
            "description":"Beschreibung",
            "skills":"Fähigkeiten",
            "skill":"Fähigkeit",
            "skillLevel":"Fähigkeitslevel",
            "references":"Referenzen",
            "contactPerson":"Kontaktperson",
            "contactNumber":"Kontakt Nummer",
            "saveChanges":"Änderungen speichern",
            "generate":"Generieren",
            "birth":"Geburt",
            "noDate":"Kein Datum",
            "welcomeBackSignInToYourAccount":"Willkommen zurück. Melden Sie sich bei Ihrem Konto an",
            "password":"Passwort",
            "staySignedIn":"Angemeldet bleiben",
            "signInFacebook":"Mit Facebook anmelden",
            "signInGoogle":"Mit Google anmelden",
            "forgotYourPassword":"Haben Sie Ihr Passwort vergessen",
            "notRegisteredYet":"Noch nicht registriert",
            "register":"Registrieren",
            "resetPassword":"Passwort zurücksetzen",
            "backToLogin":"Zurück zur Anmeldung",
            "displayName":"Anzeigename",
            "confirmPassword":"Bestätige das Passwort",
            "signUpFacebook":"Bei Facebook anmelden",
            "signUpGoogle":"Bei Google anmelden",
            "easilyArrangeInterviews":"Vereinbaren Sie problemlos Interviews"
        }
    },
    it:{
        translation:{
            "home": "Casa",
            "newJobs": "Nuovi lavori",
            "salaryGuide": "Guida agli stipendi",
            "language": "linguaggio",
            "help":"Aiuto",
            "login": "Accesso",
            "filter":"Filtro",
            "location": "Posizione",
            "canada": "Canada",
            "dubai": "Dubai",
            "england": "Inghilterra",
            "romania": "Romania",
            "newZealand": "Nuova Zelanda",
            "jobTypes": "Tipi di lavoro",
            "cleaner": "Pulitore",
            "careAssistant": "Assistente di cura",
            "driver": "Autista",
            "factoryWorker": "Operaio di fabbrica",
            "farmWorker": "Operaio agricolo",
            "remoteWorking": "Lavoro a distanza",
            "retail": "Vendita al dettaglio",
            "searchYourJob": "Cerca il tuo lavoro",
            "apple": "Mela",
            "fullTime": "Tempo pieno",
            "mondayToFriday": "Dal lunedì al venerdì",
            "drivingLicence": "Patente di guida",
            "easily": "Facilmente",
            "apply": "Applicare",
            "easilyApply": "Applica facilmente",
            "hiring": "Assunzione",
            "multiple": "Molteplice",
            "candidates": "Candidati",
            "hiringMultipleCandidates": "Assunzione di più candidati",
            "testingMainPoint1": "Punto principale di prova 1",
            "testingMainPoint2": "Punto principale di prova 2",
            "testingMainPoint": "Punto principale di prova",
            "posted": "Postato",
            "days": "Giorni",
            "ago": "fa",
            "applyOnCompanySite": "Applica sul sito aziendale",
            "back": "Indietro",
            "next": "Il prossimo",
            "howtoApplyForAJob": "Come candidarsi per un lavoro",
            "createAwesomeCVUsingOurCVGenerator": "Crea un curriculum fantastico utilizzando il nostro generatore di CV",
            "theCVThatGetsTheJob": "Il CV che ottiene il lavoro",
            "buildANewCVIn03": "Costruisci un nuovo CV in 03",
            "minutesOrImprove": "minuti o migliorare",
            "yourExistingOne": "Il tuo esistente",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "Costruisci un nuovo CV in 03 minuti o migliora il tuo CV esistente",
            "createYourCV": "Crea il tuo CV",
            "howToCreateCVWithin03Steps": "Come creare un CV in 03 passaggi",
                "step01": "Passaggio 01",
                "step02": "Passaggio 02",
                "step03": "Passaggio 03",
                "step01Message": "Inserisci le tue informazioni personali, educative, professionali, tecniche, aggiuntive e altre pertinenti nel formato selezionato.",
                "step02Message": "Inserisci le tue informazioni personali, educative, professionali, tecniche, aggiuntive e altre pertinenti nel formato selezionato.",
                "step03Message": "Controlla attentamente i tuoi dettagli e fai clic sul pulsante 'Genera CV' in modo che il tuo CV possa generare automaticamente un CV completo",
                "chooseATemplateForYourCV": "Scegli un modello per il tuo CV",
                "youCanAlwaysChangeYourTemplateLater": "Puoi sempre cambiare il tuo modello in un secondo momento",
                "buildYourCV": "Costruisci il tuo CV",
                "hireThePeopleYouNeed": "Assumi le persone di cui hai bisogno",
                "postYourJobs": "Pubblica i tuoi lavori",
                "saveWithMultipleJobs": "Salva con più lavori",
                "allYouNeedIsYourEmailAddressToCreate": "Tutto ciò di cui hai bisogno è il tuo indirizzo email",
                "specialLinks": "Link speciali",
                "contactUs": "Contattaci",
                "bottomMessage": "jobwomen offre ai candidati e ai datori di lavoro il modo più efficace per trovare lavoro e dipendenti. Contattaci per trovare il tuo lavoro dei sogni. © 2022 jobwomen. Tutti i diritti riservati.",
                "howToApplyForADreameJobWithin3Steps": "Come candidarsi per un lavoro dei sogni in 3 passaggi",
                "selectYourDreamJobNow": "Seleziona ora il tuo lavoro dei sogni.",
                "createYourGreatCV": "Crea il tuo grande CV",
                "sendYourCV":"Invia il tuo CV",
                "applyNow":"Applica ora",
                "howMuchDoesACleanerMakeInUAE":"Quanto guadagna un pulitore negli Emirati Arabi Uniti?",
                "avarageBaseSalary":"Stipendio base medio?",
                "perMonth":"Al mese",
                "perYear":"All'anno",
                "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Tooltip della fonte dati del salario base medio per il salario base medio.",
                "howMuchDoesACareAssistantMakeInUAE":"Quanto guadagna un assistente di cura negli Emirati Arabi Uniti?",
                "leaveAMessage":"Lascia un messaggio",
                "title":"Titolo",
                "firstName":"Nome di battesimo",
                "lastName":"Cognome",
                "address":"Indirizzo",
                "mobile":"Mobile",
                "email":"E-mail",
                "jobType":"Tipo di lavoro",
                "companyName":"Nome della ditta",
                "commnetOrQuestion":"Commento o domanda",
                "send":"Spedire",
                "selectTemplate":"Seleziona modello",
                "addInformation":"Aggiungi informazioni",
                "download":"Scaricare",
                "selectThisTemplate":"Seleziona questo modello",
                "jobTitle":"Titolo di lavoro",
                "dateOfBirth":"Data di nascita",
                "personalInfo":"Informazioni personali",
                "showFewerOption":"Mostra meno opzioni",
                "phone":"Telefono",
                "chooseACountry":"Scegli un paese",
                "postalCode":"Codice postale",
                "doYouHaveDrivingLicence":"Hai la patente di guida",
                "yes":"sì",
                "no":"No",
                "professionalInfomation":"Informazioni professionali",
                "addAllTheInformationAboutYourCareer": "Aggiungi tutte le informazioni sulla tua carriera",
                "company":"Azienda",
                "from":"Da",
                "to":"A",
                "currentlyWorkingHere":"Attualmente lavorando qui",
                "add":"Inserisci",
                "educationalInformation":"Informazioni educative",
                "addAllTheInformationAboutYourEducationalQualifications":"Aggiungi tutte le informazioni sulle tue qualifiche educative",
                "insitute":"Istituto",
                "currentlyStudinghere":"Attualmente studiando qui",
                "hobbies":"Hobby",
                "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Fornisci una breve descrizione qui delle cose che ti piace fare",
                "aboutMe":"Riguardo a me",
                "extracurricularActivities":"Attività extracurriculari",
                "description":"Descrizione",
                "skills":"Abilità",
                "skill":"Abilità",
                "skillLevel":"Livello di abilità",
                "references":"Riferimenti",
                "contactPerson":"Persona di contatto",
                "contactNumber":"Numero di contatto",
                "saveChanges":"Salva modifiche",
                "generate":"Generare",
                "birth":"Nascita",
                "noDate":"Nessuna data",
                "welcomeBackSignInToYourAccount":"Bentornato. Accedi al tuo account",
                "password":"Parola d'ordine",
                "staySignedIn":"Rimani connesso",
                "signInFacebook":"Accedi con Facebook",
                "signInGoogle":"Accedi con Google",
                "forgotYourPassword":"Ha dimenticato la password",
                "notRegisteredYet":"Non ancora registrato",
                "register":"Registrati",
                "resetPassword":"Resetta la password",
                "backToLogin":"Torna al login",
                "displayName":"Nome da visualizzare",
                "confirmPassword":"Conferma password",
                "signUpFacebook":"Iscriviti a Facebook",
                "signUpGoogle":"Iscriviti a Google",
                "easilyArrangeInterviews":"Organizza facilmente colloqui"

        }
    },
    es:{
        translation:{
            "home": "Casa",
            "newJobs": "Nuevos trabajos",
            "salaryGuide": "Guía salarial",
            "language": "Idioma",
            "help":"Ayuda",
            "login": "Acceso",
            "filter":"Filtrar",
            "location": "Ubicación",
            "canada": "Canadá",
            "dubai": "Dubai",
            "england": "Inglaterra",
            "romania": "Rumania",
            "newZealand": "Nueva Zelanda",
            "jobTypes": "Tipos de trabajo",
            "cleaner": "Limpiador",
            "careAssistant": "Asistente de cuidado",
            "driver": "Conductor",
            "factoryWorker": "Trabajador de fábrica",
            "farmWorker": "Trabajador agrícola",
            "remoteWorking": "Trabajo remoto",
            "retail": "Venta al por menor",
            "searchYourJob": "Busca tu trabajo",
            "apple": "Manzana",
            "fullTime": "Tiempo completo",
            "mondayToFriday": "De lunes a viernes",
            "drivingLicence": "Licencia de conducir",
            "easily": "Fácilmente",
            "apply": "Aplicar",
            "easilyApply": "Aplica fácilmente",
            "hiring": "Contratación",
            "multiple": "Múltiple",
            "candidates": "Candidatos",
            "hiringMultipleCandidates": "Contratación de múltiples candidatos",
            "testingMainPoint1": "Punto principal de prueba 1",
            "testingMainPoint2": "Punto principal de prueba 2",
            "testingMainPoint": "Punto principal de prueba",
            "posted": "Publicado",
            "days": "Días",
            "ago": "Hace",
            "applyOnCompanySite": "Aplicar en el sitio de la empresa",
            "back": "Espalda",
            "next": "Próximo",
            "howtoApplyForAJob": "Cómo solicitar un trabajo",
            "createAwesomeCVUsingOurCVGenerator": "Crea un CV increíble usando nuestro generador de CV",
            "theCVThatGetsTheJob": "El CV que consigue el trabajo",
            "buildANewCVIn03": "Construye un nuevo CV en 03",
            "minutesOrImprove": "minutos o mejorar",
            "yourExistingOne": "El tuyo existente",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "Construye un nuevo CV en 03 minutos o mejora tu CV existente",
            "createYourCV": "Crea tu CV",
            "howToCreateCVWithin03Steps": "Cómo crear un CV en 03 pasos",
            "step01": "Paso 01",
            "step02": "Paso 02",
            "step03": "Paso 03",
            "step01Message": "Ingrese su información personal, educativa, profesional, técnica, adicional y otra relevante en el formato seleccionado.",
            "step02Message": "Ingrese su información personal, educativa, profesional, técnica, adicional y otra relevante en el formato seleccionado.",
            "step03Message": "Revise sus detalles cuidadosamente y haga clic en el botón 'Generar CV' para que su CV pueda generar automáticamente un CV completo",
            "chooseATemplateForYourCV": "Elija una plantilla para su CV",
            "youCanAlwaysChangeYourTemplateLater": "Siempre puede cambiar su plantilla más tarde",
            "buildYourCV": "Construye tu CV",
            "hireThePeopleYouNeed": "Contrata a las personas que necesitas",
            "postYourJobs": "Publica tus trabajos",
            "saveWithMultipleJobs": "Guardar con múltiples trabajos",
            "allYouNeedIsYourEmailAddressToCreate": "Todo lo que necesitas es tu dirección de correo electrónico",
            "specialLinks": "Enlaces especiales",
            "contactUs": "Contáctenos",
            "bottomMessage": "jobwomen ofrece a los candidatos y empleadores la forma más efectiva de encontrar trabajo y empleados. Contáctenos para encontrar su trabajo soñado. © 2022 jobwomen. Todos los derechos reservados.",
            "howToApplyForADreameJobWithin3Steps": "Cómo solicitar un trabajo de ensueño en 3 pasos",
            "selectYourDreamJobNow": "Seleccione su trabajo soñado ahora.",
            "createYourGreatCV": "Crea tu gran CV",
            "sendYourCV":"Envía tu CV",
            "applyNow":"Aplica ahora",
            "howMuchDoesACleanerMakeInUAE":"¿Cuánto gana un limpiador en los EAU?",
            "avarageBaseSalary":"Salario base promedio?",
            "perMonth":"Por mes",
            "perYear":"Por año",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Tooltip de la fuente de datos del salario base promedio para el salario base promedio.",
            "howMuchDoesACareAssistantMakeInUAE":"¿Cuánto gana un asistente de atención en los EAU?",
            "leaveAMessage":"Deja un mensaje",
            "title":"Título",
            "firstName":"Nombre de pila",
            "lastName":"Apellido",
            "address":"Dirección",
            "mobile":"Móvil",
            "email":"Correo electrónico",
            "jobType":"Tipo de trabajo",
            "companyName":"Nombre de empresa",
            "commnetOrQuestion":"Comentario o pregunta",
            "send":"Enviar",
            "selectTemplate":"Seleccionar plantilla",
            "addInformation":"Agregar información",
            "download":"Descargar",
            "selectThisTemplate":"Seleccione esta plantilla",
            "jobTitle":"Título del trabajo",
            "dateOfBirth":"Fecha de nacimiento",
            "personalInfo":"Información personal",
            "showFewerOption":"Mostrar menos opciones",
            "phone":"Teléfono",
            "chooseACountry":"Elige un país",
            "postalCode":"Código postal",
            "doYouHaveDrivingLicence":"¿Tienes licencia de conducir",
            "yes":"Si",
            "no":"No",
            "professionalInfomation":"Información profesional",
            "addAllTheInformationAboutYourCareer": "Agregue toda la información sobre su carrera",
            "company":"Empresa",
            "from":"De",
            "to":"A",
            "currentlyWorkingHere":"Actualmente trabajando aquí",
            "add":"Agregar",
            "educationalInformation":"Información educativa",
            "addAllTheInformationAboutYourEducationalQualifications":"Agregue toda la información sobre sus calificaciones educativas",
            "insitute":"Instituto",
            "currentlyStudinghere":"Actualmente estudiando aquí",
            "hobbies":"Pasatiempos",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Proporcione una breve descripción aquí de las cosas que le gusta hacer",
            "aboutMe":"Sobre mi",
            "extracurricularActivities":"Actividades extracurriculares",
            "description":"Descripción",
            "skills":"Habilidades",
            "skill":"Habilidad",
            "skillLevel":"Nivel de habilidad",
            "references":"Referencias",
            "contactPerson":"Persona de contacto",
            "contactNumber":"Número de contacto",
            "saveChanges":"Guardar cambios",
            "generate":"Generar",
            "birth":"Nacimiento",
            "noDate":"Sin fecha",
            "welcomeBackSignInToYourAccount":"Bienvenido de nuevo. Inicia sesión en tu cuenta",
            "password":"Contraseña",
            "staySignedIn":"Mantenerse conectado",
            "signInFacebook":"Inicia sesión con Facebook",
            "signInGoogle":"Inicia sesión con Google",
            "forgotYourPassword":"¿Olvidaste tu contraseña",
            "notRegisteredYet":"Todavía no registrado",
            "register":"Registrarse",
            "resetPassword":"Restablecer la contraseña",
            "backToLogin":"Volver al inicio de sesión",
            "displayName":"Nombre para mostrar",
            "confirmPassword":"Confirmar contraseña",
            "signUpFacebook":"Regístrate en Facebook",
            "signUpGoogle":"Regístrate en Google",
            "easilyArrangeInterviews":"Organice fácilmente entrevistas"
        }
    },
    bn:{
        translation:{
            "home": "বাড়ি",
            "newJobs": "নতুন চাকরি",
            "salaryGuide": "বেতন নির্দেশিকা",
            "language": "ভাষা",
            "help":"সাহায্য",
            "login": "প্রবেশ করুন",
            "filter":"ছাঁকনি",
            "location": "অবস্থান",
            "canada": "কানাডা",
            "dubai": "দুবাই",
            "england": "ইংল্যান্ড",
            "romania": "রোমানিয়া",
            "newZealand": "নিউজিল্যান্ড",
            "jobTypes": "চাকরির ধরণ",
            "cleaner": "পরিষ্কারক",
            "careAssistant": "যত্নশীল সহকারী",
            "driver": "চালক",
            "factoryWorker": "কারখানা কর্মী",
            "farmWorker": "ফার্ম কর্মী",
            "remoteWorking": "দূরবর্তী কাজ",
            "retail": "খুদরা",
            "searchYourJob": "আপনার কাজ অনুসন্ধান করুন",
            "apple": "আপেল",
            "fullTime": "পূর্ণ সময়",
            "mondayToFriday": "সোমবার থেকে শুক্রবার",
            "drivingLicence": "ড্রাইভিং লাইসেন্স",
            "easily": "সহজে",
            "apply": "প্রয়োগ করুন",
            "easilyApply": "সহজে আবেদন করুন",
            "hiring": "ভর্তি",
            "multiple": "বহুল",
            "candidates": "প্রার্থী",
            "hiringMultipleCandidates": "বহুল প্রার্থী নিয়োগ",
            "testingMainPoint1": "পরীক্ষার মূল পয়েন্ট 1",
            "testingMainPoint2": "পরীক্ষার মূল পয়েন্ট 2",
            "testingMainPoint": "পরীক্ষার মূল পয়েন্ট",
            "posted": "পোস্ট করা হয়েছে",
            "days": "দিন",
            "ago": "আগে",
            "applyOnCompanySite": "কোম্পানির সাইটে আবেদন করুন",
            "back": "পিছনে",
            "next": "পরবর্তী",
            "howtoApplyForAJob": "কিভাবে চাকরির জন্য আবেদন করবেন",
            "createAwesomeCVUsingOurCVGenerator": "আমাদের সিভি জেনারেটর ব্যবহার করে আপনার সিভি তৈরি করুন",
            "theCVThatGetsTheJob": "কাজ পান সিভি",
            "buildANewCVIn03": "03 মিনিটে নতুন সিভি তৈরি করুন",
            "minutesOrImprove": "মিনিট বা উন্নতি",
            "yourExistingOne": "আপনার বিদ্যমান",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "03 মিনিটে নতুন সিভি তৈরি করুন বা আপনার বিদ্যমান সিভি উন্নত করুন",
            "createYourCV": "আপনার সিভি তৈরি করুন",
            "howToCreateCVWithin03Steps": "03 ধাপে সিভি তৈরির পদ্ধতি",
            "step01": "ধাপ 01",
            "step02": "ধাপ 02",
            "step03": "ধাপ 03",
            "step01Message": "নির্বাচিত ফর্ম্যাটে আপনার ব্যক্তিগত, শিক্ষাগত, পেশাগত, প্রযুক্তিগত, অতিরিক্ত এবং অন্যান্য সম্পর্কিত তথ্য প্রবেশ করুন।",
            "step02Message": "নির্বাচিত ফর্ম্যাটে আপনার ব্যক্তিগত, শিক্ষাগত, পেশাগত, প্রযুক্তিগত, অতিরিক্ত এবং অন্যান্য সম্পর্কিত তথ্য প্রবেশ করুন।",
            "step03Message": "আপনার বিস্তারিত যথাযথভাবে পরীক্ষা করুন এবং আপনার সিভি সম্পূর্ণ সিভি তৈরি করতে 'সিভি তৈরি করুন' বোতামে ক্লিক করুন",
            "chooseATemplateForYourCV": "আপনার সিভির জন্য একটি টেমপ্লেট চয়ন করুন",
            "youCanAlwaysChangeYourTemplateLater": "আপনি পরে সবসময় আপনার টেমপ্লেট পরিবর্তন করতে পারেন",
            "buildYourCV": "আপনার সিভি তৈরি করুন",
            "hireThePeopleYouNeed": "আপনি যারা দরকার তাদের নিয়োগ করুন",
            "postYourJobs": "আপনার চাকরি পোস্ট করুন",
            "saveWithMultipleJobs": "একাধিক চাকরি সংরক্ষণ করুন",
            "allYouNeedIsYourEmailAddressToCreate": "সিভি তৈরি করতে আপনার ইমেল ঠিকানা দরকার",
            "specialLinks": "বিশেষ লিঙ্ক",
            "contactUs": "যোগাযোগ করুন",
            "bottomMessage": "জবওমেন প্রার্থীদের এবং কর্মকর্তাদের জন্য সবচেয়ে দক্ষ উপায়ে কাজ এবং কর্মী খুঁজে পেতে সহায়ক। আপনার স্বপ্নের কাজ খুঁজতে যোগাযোগ করুন। © 2022 জবওমেন। সমস্ত অধিকার সংরক্ষিত।",
            "howToApplyForADreameJobWithin3Steps": "3 ধাপে স্বপ্ন কাজের জন্য কিভাবে আবেদন করবেন",
            "selectYourDreamJobNow": "এখন আপনার স্বপ্ন কাজ নির্বাচন করুন।",
            "createYourGreatCV": "আপনার মহান সিভি তৈরি করুন",
            "sendYourCV":"আপনার সিভি পাঠান",
            "applyNow":"এখন আবেদন করুন",
            "howMuchDoesACleanerMakeInUAE":"একজন ক্লিনার কত টাকা উপার্জন করেন ইউএইতে?",
            "avarageBaseSalary":"গড় বেতন?",
            "perMonth":"প্রতি মাসে",
            "perYear":"প্রতি বছর",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"গড় বেতনের জন্য গড় বেতনের ডেটা উৎস টুলটিপ।",
            "howMuchDoesACareAssistantMakeInUAE":"একজন যত্নশীল সহকারী কত টাকা উপার্জন করেন ইউএইতে?",
            "leaveAMessage":"একটি বার্তা ছেড়ে দিন",
            "title":"খেতাব",
            "firstName":"প্রথম নাম",
            "lastName":"নামের শেষাংশ",
            "address":"ঠিকানা",
            "mobile":"মোবাইল",
            "email":"ইমেল",
            "jobType":"কাজের ধরণ",
            "companyName":"কোম্পানির নাম",
            "commnetOrQuestion":"মন্তব্য বা প্রশ্ন",
            "send":"পাঠান",
            "selectTemplate":"টেমপ্লেট নির্বাচন করুন",
            "addInformation":"তথ্য যোগ করুন",

            "download":"ডাউনলোড",
            "selectThisTemplate":"এই টেমপ্লেট নির্বাচন করুন",
            "jobTitle":"চাকরির শিরোনাম",
            "dateOfBirth":"জন্ম তারিখ",
            "personalInfo":"ব্যক্তিগত তথ্য",
            "showFewerOption":"কম অপশন দেখান",
            "phone":"ফোন",
            "chooseACountry":"একটি দেশ চয়ন করুন",
            "postalCode":"পোস্টাল কোড",
            "doYouHaveDrivingLicence":"আপনার কি ড্রাইভিং লাইসেন্স আছে",
            "yes":"হ্যাঁ",
            "no":"না",
            "professionalInfomation":"পেশাদার তথ্য",
            "addAllTheInformationAboutYourCareer": "আপনার ক্যারিয়ার সম্পর্কিত সমস্ত তথ্য যোগ করুন",
            "company":"প্রতিষ্ঠান",
            "from":"থেকে",
            "to":"প্রতি",
            "currentlyWorkingHere":"বর্তমানে এখানে কাজ করছেন",
            "add":"যোগ করুন",
            "educationalInformation":"শিক্ষাগত তথ্য",
            "addAllTheInformationAboutYourEducationalQualifications":"আপনার শিক্ষাগত যোগ্যতা সম্পর্কিত সমস্ত তথ্য যোগ করুন",
            "insitute":"প্রতিষ্ঠান",
            "currentlyStudinghere":"বর্তমানে এখানে অধ্যয়ন করছেন",
            "hobbies":"শখ",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"আপনি যে কাজগুলি করতে ভালবাসেন তা সংক্ষিপ্তভাবে এখানে বর্ণনা দিন",
            "aboutMe":"আমার সম্পর্কে",
            "extracurricularActivities":"অতিরিক্ত কার্যক্রম",
            "description":"বর্ণনা",
            "skills":"দক্ষতা",
            "skill":"দক্ষতা",
            "skillLevel":"দক্ষতা স্তর",
            "references":"প্রমাণ",
            "contactPerson":"যোগাযোগ ব্যক্তি",
            "contactNumber":"যোগাযোগ নম্বর",
            "saveChanges":"পরিবর্তন সংরক্ষণ করুন",
            "generate":"উত্পন্ন করা",
            "birth":"জন্ম",
            "noDate":"কোন তারিখ নেই",
            "welcomeBackSignInToYourAccount":"স্বাগতম ফিরে আসার জন্য। আপনার অ্যাকাউন্টে সাইন ইন করুন",
            "password":"পাসওয়ার্ড",
            "staySignedIn":"সাইন ইন থাকুন",
            "signInFacebook":"ফেসবুকে সাইন ইন করুন",
            "signInGoogle":"গুগলে সাইন ইন করুন",
            "forgotYourPassword":"আপনি আপনার পাসওয়ার্ড ভুলে গেছেন",
            "notRegisteredYet":"এখনও নিবন্ধিত নয়",
            "register":"নিবন্ধন করুন",
            "resetPassword":"পাসওয়ার্ড রিসেট করুন",   
            "backToLogin":"লগইনে ফিরে যান",
            "displayName":"প্রদর্শনীর নাম",
            "confirmPassword":"পাসওয়ার্ড নিশ্চিত করুন",
            "signUpFacebook":"ফেসবুকে সাইন আপ করুন",
            "signUpGoogle":"গুগলে সাইন আপ করুন",
            "easilyArrangeInterviews":"সহজেই সাক্ষাৎকার সাজানো যায়"
        }
    },
    pt:{
        translation:{
            "home": "Casa",
            "newJobs": "Novos empregos",
            "salaryGuide": "Guia salarial",
            "language": "Língua",
            "help":"Socorro",
            "login": "Conecte-se",
            "filter":"Filtro",
            "location": "Localização",
            "canada": "Canadá",
            "dubai": "Dubai",
            "england": "Inglaterra",
            "romania": "Romênia",
            "newZealand": "Nova Zelândia",
            "jobTypes": "Tipos de trabalho",
            "cleaner": "Limpeza",
            "careAssistant": "Assistente de cuidados",
            "driver": "Motorista",
            "factoryWorker": "Trabalhador de fábrica",
            "farmWorker": "Trabalhador agrícola",
            "remoteWorking": "Trabalho remoto",
            "retail": "Varejo",
            "searchYourJob": "Procure seu emprego",
            "apple": "Maçã",
            "fullTime": "Tempo integral",
            "mondayToFriday": "De segunda a sexta",
            "drivingLicence": "Carteira de motorista",
            "easily": "Facilmente",
            "apply": "Aplique",
            "easilyApply": "Aplique facilmente",
            "hiring": "Contratação",
            "multiple": "Múltiplo",
            "candidates": "Candidatos",
            "hiringMultipleCandidates": "Contratação de múltiplos candidatos",
            "testingMainPoint1": "Ponto principal de teste 1",
            "testingMainPoint2": "Ponto principal de teste 2",
            "testingMainPoint": "Ponto principal de teste",
            "posted": "Postado",
            "days": "Dias",
            "ago": "atrás",
            "applyOnCompanySite": "Inscreva-se no site da empresa",
            "back": "Costas",
            "next": "Próximo",
            "howtoApplyForAJob": "Como se candidatar a um emprego",
            "createAwesomeCVUsingOurCVGenerator":
                "Crie um currículo incrível usando nosso gerador de currículo",
            "theCVThatGetsTheJob": "O CV que consegue o emprego",
            "buildANewCVIn03": "Construa um novo CV em 03",
            "minutesOrImprove": "minutos ou melhorar",
            "yourExistingOne": "O seu existente",
            "buildANewCVIn03MinutesOrImproveYourExistingOne":
                "Construa um novo CV em 03 minutos ou melhore seu CV existente",
            "createYourCV": "Crie seu CV",
            "howToCreateCVWithin03Steps": "Como criar um CV em 03 etapas",
            "step01": "Passo 01",
            "step02": "Passo 02",
            "step03": "Passo 03",
            "step01Message":
                "Insira suas informações pessoais, educacionais, profissionais, técnicas, adicionais e outras relevantes no formato selecionado.",
            "step02Message":
                "Insira suas informações pessoais, educacionais, profissionais, técnicas, adicionais e outras relevantes no formato selecionado.",
            "step03Message":
                "Revise seus detalhes com cuidado e clique no botão 'Gerar CV' para que seu CV possa gerar automaticamente um CV completo",
            "chooseATemplateForYourCV": "Escolha um modelo para o seu CV",
            "youCanAlwaysChangeYourTemplateLater":
                "Você sempre pode alterar seu modelo posteriormente",
            "buildYourCV": "Construa seu CV",
            "hireThePeopleYouNeed": "Contrate as pessoas de que você precisa",
            "postYourJobs": "Publique seus trabalhos",
            "saveWithMultipleJobs": "Salve com vários empregos",
            "allYouNeedIsYourEmailAddressToCreate":
                "Tudo que você precisa é do seu endereço de e-mail",
            "specialLinks": "Links especiais",
            "contactUs": "Contate-Nos",
            "bottomMessage":    "jobwomen oferece aos candidatos e empregadores a maneira mais eficaz de encontrar empregos e funcionários. Entre em contato conosco para encontrar seu emprego dos sonhos. © 2022 jobwomen. Todos os direitos reservados.",
            "howToApplyForADreameJobWithin3Steps":
                "Como se candidatar a um emprego dos sonhos em 3 etapas",
            "selectYourDreamJobNow": "Selecione seu emprego dos sonhos agora.",
            "createYourGreatCV": "Crie seu ótimo CV",
            "sendYourCV":"Envie seu CV",
            "applyNow":"Aplique agora",
            "howMuchDoesACleanerMakeInUAE":"Quanto um limpador ganha nos Emirados Árabes Unidos?",
            "avarageBaseSalary":"Salário base médio?",
            "perMonth":"Por mês",
            "perYear":"Por ano",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Tooltip da fonte de dados do salário base médio para o salário base médio.",
            "howMuchDoesACareAssistantMakeInUAE":"Quanto um assistente de cuidados ganha nos Emirados Árabes Unidos?",
            "leaveAMessage":"Deixe uma mensagem",
            "title":"Título",
            "firstName":"Primeiro nome",
            "lastName":"Último nome",
            "address":"Endereço",
            "mobile":"Móvel",
            "email":"O email",
            "jobType":"Tipo de trabalho",
            "companyName":"Nome da empresa",
            "commnetOrQuestion":"Comentário ou pergunta",
            "send":"Mandar",
            "selectTemplate":"Selecione um modelo",
            "addInformation":"Adicionar informação",
            "download":"Baixar",
            "selectThisTemplate":"Selecione este modelo",
            "jobTitle":"Título do trabalho",
            "dateOfBirth":"Data de nascimento",
            "personalInfo":"Informação pessoal",
            "showFewerOption":"Mostrar menos opção",
            "phone":"Telefone",
            "chooseACountry":"Escolha um país",
            "postalCode":"Código postal",
            "doYouHaveDrivingLicence":"Você tem carteira de motorista",
            "yes":"sim",
            "no":"Não",
            "professionalInfomation":"Informação profissional",
            "addAllTheInformationAboutYourCareer": "Adicione todas as informações sobre sua carreira",
            "company":"Empresa",
            "from":"De",
            "to":"Para",
            "currentlyWorkingHere":"Atualmente trabalhando aqui",
            "add":"Adicionar",
            "educationalInformation":"Informação educacional",
            "addAllTheInformationAboutYourEducationalQualifications":"Adicione todas as informações sobre suas qualificações educacionais",
            "insitute":"Instituto",
            "currentlyStudinghere":"Atualmente estudando aqui",
            "hobbies":"Passatempos",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Dê uma breve descrição aqui das coisas que você gosta de fazer",
            "aboutMe":"Sobre mim",
            "extracurricularActivities":"Atividades extracurriculares",
            "description":"Descrição",
            "skills":"Habilidades",
            "skill":"Habilidade",
            "skillLevel":"Nível de habilidade",
            "references":"Referências",
            "contactPerson":"Pessoa de contato",
            "contactNumber":"Número de contato",
            "saveChanges":"Salvar alterações",
            "generate":"Gerar",
            "birth":"Nascimento",
            "noDate":"Sem data",
            "welcomeBackSignInToYourAccount":"Bem-vindo de volta. Faça login na sua conta",
            "password":"Senha",
            "staySignedIn":"Fique conectado",
            "signInFacebook":"Faça login com o Facebook",
            "signInGoogle":"Faça login com o Google",
            "forgotYourPassword":"Esqueceu sua senha",
            "notRegisteredYet":"Ainda não registrado",
            "register":"Registro",
            "resetPassword":"Redefinir senha",
            "backToLogin":"Voltar para o login",
            "displayName":"Nome de exibição",
            "confirmPassword":"Confirme a Senha",
            "signUpFacebook":"Inscreva-se no Facebook",
            "signUpGoogle":"Inscreva-se no Google",
            "easilyArrangeInterviews":"Organize facilmente entrevistas"

        }
    },
    lah:{
        translation:{
            "home": "گھر",
            "newJobs": "نئی نوکریاں",
            "salaryGuide": "تنخواہ کی گائیڈ",
            "language": "زبان",
            "help":"مدد",
            "login": "لاگ ان کریں",
            "filter":"فلٹر",
            "location": "مقام",
            "canada": "کینیڈا",
            "dubai": "دبئی",
            "england": "انگلینڈ",
            "romania": "رومانیہ",
            "newZealand": "نیوزی لینڈ",
            "jobTypes": "نوکری کی اقسام",
            "cleaner": "صفائی کار",
            "careAssistant": "مدد کار",
            "driver": "ڈرائیور",
            "factoryWorker": "فیکٹری کا کارکن",
            "farmWorker": "فارم ورکر",
            "remoteWorking": "ریموٹ ورکنگ",
            "retail": "خوردہ فروشی",
            "searchYourJob": "اپنی نوکری تلاش کریں",
            "apple": "سیب",
            "fullTime": "مکمل وقت",
            "mondayToFriday": "پیر سے جمعرات",
            "drivingLicence": "ڈرائیونگ لائسنس",
            "easily": "آسانی سے",
            "apply": "درخواست دیں",
            "easilyApply": "آسانی سے درخواست دیں",
            "hiring": "استخدام",
            "multiple": "متعدد",
            "candidates": "امیدواروں",
            "hiringMultipleCandidates": "متعدد ام  امیدواروں کی بھرتی",
            "testingMainPoint1": "ٹیسٹنگ مین پوائنٹ 1",
            "testingMainPoint2": "ٹیسٹنگ مین پوائنٹ 2",
            "testingMainPoint": "ٹیسٹنگ مین پوائنٹ",
            "posted": "پوسٹ کیا گیا",
            "days": "دن",
            "ago": "پہلے",
            "applyOnCompanySite": "کمپنی کی سائٹ پر درخواست دیں",
            "back": "پیچھے",
            "next": "اگلے",
            "howtoApplyForAJob": "نوکری کے لئے درخواست کیسے دیں",
            "createAwesomeCVUsingOurCVGenerator": "ہمارے CV جنریٹر کا استعمال کرتے ہوئے ایک شاندار CV بنائیں",
            "theCVThatGetsTheJob": "نوکری حاصل کرنے والا CV",
            "buildANewCVIn03": "03 میں ایک نیا CV بنائیں",
            "minutesOrImprove": "منٹ یا بہتر کریں",
            "yourExistingOne": "آپ کا موجودہ",
            "buildANewCVIn03MinutesOrImproveYourExistingOne": "03 منٹ میں ایک نیا CV بنائیں یا اپنا موجودہ CV بہتر کریں",
            "createYourCV": "اپنا CV بنائیں",
            "howToCreateCVWithin03Steps": "03 قدموں میں CV کیسے بنائیں",
            "step01": "قدم 01",
            "step02": "قدم 02",
            "step03": "قدم 03",
            "step01Message": "منتخب فارمیٹ میں اپنی شخصی، تعلیمی، پیشہ ورانہ، ٹیکنیکل، اضافی اور دیگر متعلقہ معلومات درج کریں۔",
            "step02Message": "منتخب فارمیٹ میں اپنی شخصی، تعلیمی، پیشہ ورانہ، ٹیکنیکل، اضافی اور دیگر متعلقہ معلومات درج کریں۔",
            "step03Message": "اپنی تفصیلات کو دھیان سے جانچیں اور 'CV بنائیں' کے بٹن پر کلک کریں تاکہ آپ کا CV خود کار CV کو مکمل کر سکے",
            "chooseATemplateForYourCV": "اپنے CV کے لئے ایک ٹیمپلیٹ منتخب کریں",
            "youCanAlwaysChangeYourTemplateLater": "آپ بعد میں ہمیشہ اپنا ٹیمپلیٹ تبدیل کرسکتے ہیں",
            "buildYourCV": "اپنا CV بنائیں",
            "hireThePeopleYouNeed": "آپ کو ضرورت ہے لوگوں کو کرائیں",
            "postYourJobs": "اپنی نوکریاں پوسٹ کریں",
            "saveWithMultipleJobs": "متعدد نوکریوں کے ساتھ بچائیں",
            "allYouNeedIsYourEmailAddressToCreate": "آپ کو اپنا ای میل ایڈریس چاہئے",
            "specialLinks": "خصوصی لنکس",
            "contactUs": "ہم سے رابطہ کریں",
            "bottomMessage": "jobwomen امیدواروں اور کارکنوں کو نوکریاں اور ملازمین تلاش کرنے کا سب سے کار آمد طریقہ فراہم کرتا ہے۔ اپنی خوابوں کی نوکری تلاش کرنے کے لئے ہم سے رابطہ کریں۔ © 2022 jobwomen۔ تمام حقوق محفوظ ہیں۔",
            "howToApplyForADreameJobWithin3Steps": "3 قدموں میں ایک خواب کی نوکری کے لئے کیسے درخواست دیں",
            "selectYourDreamJobNow": "اب اپنی خواب کی نوکری منتخب کریں۔",
            "createYourGreatCV": "اپنا عظیم CV بنائیں",
            "sendYourCV":"اپنا CV بھیجیں",
            "applyNow":"اب درخواست دیں",
            "howMuchDoesACleanerMakeInUAE":"ایک صفائی کار کتنا کماتا ہے؟",
            "avarageBaseSalary":"اوسط بنیادی تنخواہ؟",
            "perMonth":"فی ماہ",
            "perYear":"فی سال",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"اوسط بنیادی تنخواہ کے لئے اوسط بنیادی تنخواہ کے ڈیٹا سورس ٹول ٹپ۔",
            "howMuchDoesACareAssistantMakeInUAE":"ایک مدد کار کتنا کماتا ہے؟",
            "leaveAMessage":"ایک پیغام چھوڑ دو",
            "title":"عنوان",
            "firstName":"پہلا نام",
            "lastName":"آخری نام",
            "address":"پتہ",
            "mobile":"موبائل",
            "email":"ای میل",
            "jobType":"نوکری کی قسم",
            "companyName":"کمپنی کا نام",
            "commnetOrQuestion":"تبصرہ یا سوال",
            "send":"بھیجیں",
            "selectTemplate":"ایک ٹیمپلیٹ منتخب کریں",
            "addInformation":"معلومات شامل کریں",
            "download":"ڈاؤن لوڈ کریں",
            "selectThisTemplate":"اس ٹیمپلیٹ کو منتخب کریں",
            "jobTitle":"نوکری کا عنوان",
            "dateOfBirth":"تاریخ پیدائش",
            "personalInfo":"ذاتی معلومات",
            "showFewerOption":"کم اختیار دکھائیں",
            "phone":"فون",
            "chooseACountry":"ایک ملک منتخب کریں",
            "postalCode":"پوسٹل کوڈ",
            "doYouHaveDrivingLicence":"کیا آپ کے پاس ڈرائیونگ لائسنس ہے",
            "yes":"جی ہاں",
            "no":"نہیں",
            "professionalInfomation":"پیشہ ورانہ معلومات",
            "addAllTheInformationAboutYourCareer": "اپنے کیریئر کے بارے میں تمام معلومات شامل کریں",
            "company":"کمپنی",
            "from":"سے",
            "to":"کو",
            "currentlyWorkingHere":"موجودہ طور پر یہاں کام کر رہے ہیں",
            "add":"شامل کریں",
            "educationalInformation":"تعلیمی معلومات",
            "addAllTheInformationAboutYourEducationalQualifications":"اپنی تعلیمی قابلیتوں کے بارے میں تمام معلومات شامل کریں",
            "insitute":"انسٹیٹیوٹ",
            "currentlyStudinghere":"موجودہ طور پر یہاں پڑھ رہے ہیں",
            "hobbies":"سرگرمیاں",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"وہ کام جو آپ کرنا پسند کرتے ہیں ان کی ایک مختصر تفصیل یہاں دیں",
            "aboutMe":"میرے بارے میں",
            "extracurricularActivities":"بیرونی محاذ",
            "description":"تفصیل",
            "skills":"مہارتیں",
            "skill":"مہارت",
            "skillLevel":"مہارت کا سطح",
            "references":"حوالہ جات",
            "contactPerson":"رابطہ شخص",
            "contactNumber":"رابطہ نمبر",
            "saveChanges":"تبدیلیاں محفوظ کریں",
            "generate":"پیدا کریں",
            "birth":"پیدائش",
            "noDate":"کوئی تاریخ نہیں",
            "welcomeBackSignInToYourAccount":"واپسی آپ کے اکاؤنٹ میں سائن ان کریں",
            "password":"پاس ورڈ",
            "staySignedIn":"سائن ان میں رہیں",
            "signInFacebook":"فیس بک پر سائن ان کریں",
            "signInGoogle":"گوگل پر سائن ان کریں",
            "forgotYourPassword":"آپ نے اپنا پاس ورڈ بھول گئے",
            "notRegisteredYet":"ابھی تک رجسٹر نہیں ہوا",
            "register":"رجسٹر کریں",
            "resetPassword":"پاس ورڈ دوبارہ ترتیب دیں",
            "backToLogin":"لاگ ان پر واپس جائیں",
            "displayName":"نام کا عنوان",
            "confirmPassword":"پاس ورڈ کی تصدیق کریں",
            "signUpFacebook":"فیس بک پر سائن اپ کریں",
            "signUpGoogle":"گوگل پر سائن اپ کریں",
            "easilyArrangeInterviews":"آسانی سے انٹرویو کا انتظام کریں"
        }
    },
    ru:{
        translation:{
            "home": "Главная",
            "newJobs": "Новые работы",
            "salaryGuide": "Руководство по зарплате",
            "language": "Язык",
            "help":"Помогите",
            "login": "Авторизоваться",
            "filter":"Фильтр",
            "location": "Место нахождения",
            "canada": "Канада",
            "dubai": "Дубай",
            "england": "Англия",
            "romania": "Румыния",
            "newZealand": "Новая Зеландия",
            "jobTypes": "Типы работ",
            "cleaner": "Уборщик",
            "careAssistant": "Помощник по уходу",
            "driver": "Водитель",
            "factoryWorker": "Рабочий завода",
            "farmWorker": "Фермер",
            "remoteWorking": "Удаленная работа",
            "retail": "Розничная торговля",
            "searchYourJob": "Ищите свою работу",
            "apple": "Яблоко",
            "fullTime": "Полный рабочий день",
            "mondayToFriday": "Понедельник по пятницу",
            "drivingLicence": "Водительские права",
            "easily": "Легко",
            "apply": "Подать заявление",
            "easilyApply": "Легко подать заявление",
            "hiring": "Найм",
            "multiple": "Множественный",
            "candidates": "Кандидаты",
            "hiringMultipleCandidates": "Найм нескольких кандидатов",
            "testingMainPoint1": "Тестовая основная точка 1",
            "testingMainPoint2": "Тестовая основная точка 2",
            "testingMainPoint": "Тестовая основная точка",
            "posted": "Размещено",
            "days": "Дни",
            "ago": "назад",
            "applyOnCompanySite": "Подать заявление на сайте компании",
            "back": "назад",
            "next": "следующий",
            "howtoApplyForAJob": "Как подать заявление на работу",
            "createAwesomeCVUsingOurCVGenerator":
                "Создайте потрясающее резюме с помощью нашего генератора резюме",
            "theCVThatGetsTheJob": "Резюме, которое получает работу",
            "buildANewCVIn03": "Постройте новое резюме в 03",
            "minutesOrImprove": "минут или улучшить",
            "yourExistingOne": "Ваш существующий",
            "buildANewCVIn03MinutesOrImproveYourExistingOne":
                "Постройте новое резюме в 03 минуты или улучшите свое существующее",
            "createYourCV": "Создайте свое резюме",
            "howToCreateCVWithin03Steps": "Как создать резюме за 03 шага",
            "step01": "Шаг 01",
            "step02": "Шаг 02",
            "step03": "Шаг 03",
            "step01Message":
                "Введите свою личную, образовательную, профессиональную, техническую, дополнительную и другую соответствующую информацию в выбранном формате.",
            "step02Message":
                "Введите свою личную, образовательную, профессиональную, техническую, дополнительную и другую соответствующую информацию в выбранном формате.",
            "step03Message":
                "Внимательно проверьте свои данные и нажмите кнопку «Создать резюме», чтобы ваше резюме могло автоматически создать полное резюме",
            "chooseATemplateForYourCV": "Выберите шаблон для своего резюме",
            "youCanAlwaysChangeYourTemplateLater":
                "Вы всегда можете изменить свой шаблон позже",
            "buildYourCV": "Постройте свое резюме",
            "hireThePeopleYouNeed": "Наймите нужных вам людей",
            "postYourJobs": "Разместите свои вакансии",
            "saveWithMultipleJobs": "Сохранить с несколькими работами",
            "allYouNeedIsYourEmailAddressToCreate":
                "Все, что вам нужно, это ваш адрес электронной почты",
            "specialLinks": "Специальные ссылки",
            "contactUs": "Свяжитесь с нами",
            "bottomMessage":    "jobwomen предлагает кандидатам и работодателям наиболее эффективный способ поиска работы и сотрудников. Свяжитесь с нами, чтобы найти свою работу мечты. © 2022 jobwomen. Все права защищены.",
            "howToApplyForADreameJobWithin3Steps":
                "Как подать заявление на работу мечты за 3 шага",
            "selectYourDreamJobNow": "Выберите свою работу мечты сейчас.",
            "createYourGreatCV": "Создайте свое отличное резюме",
            "sendYourCV":"Отправить свое резюме",
            "applyNow":"Подать заявление сейчас",
            "howMuchDoesACleanerMakeInUAE":"Сколько зарабатывает уборщик в ОАЭ?",
            "avarageBaseSalary":"Средняя базовая зарплата?",
            "perMonth":"В месяц",
            "perYear":"В год",
            "averageBaseSalaryDataSourceTooltipForAverageBaseSalary":"Подсказка источника данных средней базовой зарплаты для средней базовой зарплаты.",   
            "howMuchDoesACareAssistantMakeInUAE":"Сколько зарабатывает помощник по уходу в ОАЭ?",
            "leaveAMessage":"Оставить сообщение",
            "title":"заглавие",
            "firstName":"Имя",
            "lastName":"Фамилия",
            "address":"Адрес",
            "mobile":"мобильный",
            "email":"Эл. адрес",
            "jobType":"Тип работы",
            "companyName":"название компании",
            "commnetOrQuestion":"Комментарий или вопрос",
            "send":"послать",
            "selectTemplate":"Выберите шаблон",
            "addInformation":"Добавить информацию",
            "download":"Скачать",
            "selectThisTemplate":"Выберите этот шаблон",
            "jobTitle":"Название работы",
            "dateOfBirth":"Дата рождения",
            "personalInfo":"Личная информация",
            "showFewerOption":"Показать меньше вариантов",
            "phone":"Телефон",
            "chooseACountry":"Выберите страну",
            "postalCode":"Почтовый индекс",
            "doYouHaveDrivingLicence":"У вас есть водительские права",
            "yes":"да",
            "no":"Нет",
            "professionalInfomation":"Профессиональная информация",
            "addAllTheInformationAboutYourCareer": "Добавьте всю информацию о своей карьере",
            "company":"Компания",
            "from":"Из",
            "to":"К",
            "currentlyWorkingHere":"В настоящее время работает здесь",
            "add":"Добавить",
            "educationalInformation":"Образовательная информация",
            "addAllTheInformationAboutYourEducationalQualifications":"Добавьте всю информацию о своих образовательных квалификациях",
            "insitute":"Институт",
            "currentlyStudinghere":"В настоящее время учусь здесь",
            "hobbies":"Хобби",
            "giveABriefDescriptionHereOfTheThingsYouLikeToDo":"Дайте краткое описание того, что вам нравится делать",
            "aboutMe":"Обо мне",
            "extracurricularActivities":"Внеклассные мероприятия",
            "description":"Описание",
            "skills":"Навыки",
            "skill":"Умение",
            "skillLevel":"Уровень навыка",
            "references":"Ссылки",
            "contactPerson":"Контактное лицо",
            "contactNumber":"Контактный номер",
            "saveChanges":"Сохранить изменения",
            "generate":"Генерировать",
            "birth":"Рождение",
            "noDate":"Нет даты",
            "welcomeBackSignInToYourAccount":"Добро пожаловать назад. Войдите в свою учетную запись",
            "password":"пароль",
            "staySignedIn":"Оставаться в системе",
            "signInFacebook":"Войти через Facebook",
            "signInGoogle":"Войти через Google",
            "forgotYourPassword":"Забыли пароль",
            "notRegisteredYet":"Еще не зарегистрирован",
            "register":"регистр",
            "resetPassword":"Сброс пароля",
            "backToLogin":"Вернуться к входу",
            "displayName":"Отображаемое имя",
            "confirmPassword":"Подтвердите Пароль",
            "signUpFacebook":"Зарегистрироваться в Facebook",
            "signUpGoogle":"Зарегистрироваться в Google",
            "easilyArrangeInterviews":"Легко организовывать интервью"
        }
    }

    // Add more language translations as needed
  },
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
