import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, notification, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import translateText from '../../constants/translator';
import './ManageJobTypes.css'; // Import the external CSS file

const ManageJobTypes: React.FC = () => {
  const [jobTypes, setJobTypes] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredJobTypes, setFilteredJobTypes] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchJobTypes();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add Job Category',
      'Edit Job Category',
      'Name',
      'Action',
      'Duplicate Name',
      'A job Category with this name already exists.',
      'Job Category deleted successfully',
      'Job Category updated successfully',
      'Job Category added successfully',
      'An error occurred while saving the Job Category',
      'Please input the name!',
      'Update',
      'Save',
      'Edit',
      'Delete',
      'Search...',
      'Rows per page:'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addJobType',
      'editJobType',
      'name',
      'action',
      'duplicateName',
      'duplicateDescription',
      'itemDeleted',
      'itemUpdated',
      'itemAdded',
      'errorSaving',
      'nameRequired',
      'update',
      'save',
      'edit',
      'delete',
      'searchPlaceholder',
      'rowsPerPage'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchJobTypes = async () => {
    const snapshot = await getDocs(collection(db, "jobTypes"));
    const list = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobTypes(list);
    setFilteredJobTypes(list);
  };

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (item: any) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleDelete = async (itemId: string) => {
    await deleteDoc(doc(db, "jobTypes", itemId));
    notification.success({ message: translatedTexts.itemDeleted });
    fetchJobTypes();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      // Check for duplicate names
      const q = query(collection(db, "jobTypes"), where("name", "==", values.name));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty && (!editingItem || querySnapshot.docs[0].id !== editingItem.id)) {
        notification.error({ message: translatedTexts.duplicateName, description: translatedTexts.duplicateDescription });
        return;
      }

      if (editingItem) {
        await updateDoc(doc(db, "jobTypes", editingItem.id), values);
        notification.success({ message: translatedTexts.itemUpdated });
      } else {
        await addDoc(collection(db, "jobTypes"), values);
        notification.success({ message: translatedTexts.itemAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchJobTypes();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSaving });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = jobTypes.filter((item) =>
      item.name.toLowerCase().includes(value)
    );

    setFilteredJobTypes(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addJobType}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredJobTypes}
        columns={[
          { title: translatedTexts.name, dataIndex: 'name', key: 'name' },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, item) => (
              <div className="action-buttons">
                <Button onClick={() => handleEdit(item)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(item.id)}>
                  {translatedTexts.delete}
                </Button>
              </div>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingItem ? translatedTexts.editJobType : translatedTexts.addJobType}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okText={editingItem ? translatedTexts.update : translatedTexts.save}
        okType="default"
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label={translatedTexts.name} rules={[{ required: true, message: translatedTexts.nameRequired }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageJobTypes;
