import React, { FC, useState, useEffect } from "react";
import { Card, Typography, Row, Col, Tag, Modal, Upload, message, Button, Image, Descriptions, Divider, Space } from "antd";
import { StarOutlined, EnvironmentOutlined, UploadOutlined, DollarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import translateText from "../../constants/translator";
import axios from "axios";

const { Title, Paragraph } = Typography;

const sendEmail = async (formData: any, email: any) => {
  try {
    const response = await axios.post('https://<your-cloud-function-url>/sendEmail', {
      to: email,
      subject: 'New Job Application',
      text: 'Please find the attached CV.',
      attachment: formData,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

const JobCard: FC<{ card: any }> = ({ card }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [user, setUser] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const [translatedCard, setTranslatedCard] = useState<any>({});
  const langCode = localStorage.getItem('lanCode') || 'en';
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        "Job Details",
        "Please upload your CV.",
        "Your CV has been sent successfully!",
        "There was an error sending your CV. Please try again.",
        "Cancel",
        "Upload CV",
        "Please log in to upload your CV.",
        "Company",
        "Location",
        "Vacancies",
        "Job Type",
        "Salary",
        "Description",
        "Opportunity"
      ];

      const cardTextsToTranslate = [
        card.jobTitle,
        card.company,
        card.jobLocation,
        card.jobDescription
      ];

      const [translations, cardTranslations] = await Promise.all([
        translateText(textsToTranslate, langCode),
        translateText(cardTextsToTranslate, langCode)
      ]);

      setTranslatedTexts(translations);
      setTranslatedCard({
        jobTitle: cardTranslations[0],
        company: cardTranslations[1],
        jobLocation: cardTranslations[2],
        jobDescription: cardTranslations[3]
      });
    };

    fetchTranslations();
    const storedUser = JSON.parse(localStorage.getItem('user') || 'null');
    setUser(storedUser);
  }, [langCode, card]);

  const handleCardClick = () => {
    setIsModalOpen(true);
  };

  const handleModalOk = async () => {
    if (fileList.length === 0) {
      message.error(translatedTexts[1]);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("file", fileList[0]);

      await sendEmail(formData, card.email);
      message.success(translatedTexts[2]);
      setIsModalOpen(false);
      setFileList([]);
    } catch (error) {
      message.error(translatedTexts[3]);
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = ({ fileList }: any) => setFileList(fileList);

  return (
    <>
      <Card
        style={{
          marginBottom: 16,
          width: isMobile ? "100%" : "100%",
          height: '500px',
          borderRadius: 10,
          overflow: "hidden",
          cursor: "pointer",
          transition: "transform 0.3s",
          position: "relative",
        }}
        onClick={handleCardClick}
        hoverable
      >
        {card.tags?.includes("opportunity") && (
          <div style={{
            position: "absolute",
            top: "18px",
            right: "-35px",
            transform: "rotate(45deg)",
            backgroundColor: "#0070B0",
            color: "#fff",
            padding: "10px 40px", // Increased padding for better visibility
            fontWeight: "bold",
            fontSize: '18px', // Increased font size for better readability
            zIndex: 1,
            whiteSpace: "nowrap" // Ensure the text does not wrap
          }}>
            {translatedTexts[13]}
          </div>
        )}
        <Row gutter={[16, 16]}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {card.tags?.includes("newJob") && <Tag color="green" style={{ marginRight: 8 }}>New</Tag>}
              {card.tags?.includes("featured") && <Tag color="gold" style={{ marginRight: 8 }}>Featured</Tag>}
              {card.tags?.includes("urgent") && <Tag color="red" style={{ marginRight: 8 }}>Urgent</Tag>}
            </div>
          </Col>
          <Col span={24}>
            <Title level={5} style={{ marginBottom: 0 }}>{translatedCard.jobTitle}</Title>
            <Paragraph style={{ marginBottom: 8 }}>{translatedCard.company}</Paragraph>
          </Col>
          <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <StarOutlined style={{ marginRight: 8 }} />
            <Paragraph style={{ margin: 0 }}>{card.vacancies}</Paragraph>
          </Col>
          <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
            <EnvironmentOutlined style={{ marginRight: 8 }} />
            <Paragraph style={{ margin: 0 }}>{translatedCard.jobLocation}</Paragraph>
          </Col>
          <Col span={12} style={{ marginBottom: 8 }}>
            <Tag color="blue">{card.jobType}</Tag>
          </Col>
          <Col span={12} style={{ marginBottom: 8 }}>
            <Tag color="blue">£{card.jobSalary} / {card.salaryFrequency}</Tag>
          </Col>
          <Col span={24}>
            <div style={{ marginTop: 8, textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: translatedCard.jobDescription }} />
          </Col>
        </Row>
      </Card>

      <Modal
        title={<Title level={3} style={{ color: '#0070B0' }}>{translatedTexts[0]}</Title>}
        visible={isModalOpen}
        onOk={user ? handleModalOk : handleModalCancel}
        onCancel={handleModalCancel}
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            {translatedTexts[4]}
          </Button>,
          user && (
            <Button key="submit" type="primary" onClick={handleModalOk} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
              {translatedTexts[5]}
            </Button>
          )
        ]}
        bodyStyle={{ padding: '20px 40px', textAlign: 'left' }}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Image
            width={200}
            src={card.companyImage}
            alt="Company Logo"
            style={{ borderRadius: '10px', marginBottom: 16, display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <Descriptions bordered column={1}>
            <Descriptions.Item label={<span><InfoCircleOutlined /> {translatedTexts[7]}</span>}>{translatedCard.company}</Descriptions.Item>
            <Descriptions.Item label={<span><EnvironmentOutlined /> {translatedTexts[8]}</span>}>{translatedCard.jobLocation}</Descriptions.Item>
            <Descriptions.Item label={<span><StarOutlined /> {translatedTexts[9]}</span>}>{card.vacancies}</Descriptions.Item>
            <Descriptions.Item label={<span><InfoCircleOutlined /> {translatedTexts[10]}</span>}>{card.jobType}</Descriptions.Item>
            <Descriptions.Item label={<span><DollarOutlined /> {translatedTexts[11]}</span>}>£{card.jobSalary} / {card.salaryFrequency}</Descriptions.Item>
            <Descriptions.Item label={<span><InfoCircleOutlined /> {translatedTexts[12]}</span>}>
              <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: translatedCard.jobDescription }} />
            </Descriptions.Item>
          </Descriptions>
          {user && (
            <>
              <Divider />
              <Paragraph>{translatedTexts[1]}</Paragraph>
              <Upload
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleFileChange}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
                  {translatedTexts[5]}
                </Button>
              </Upload>
            </>
          )}
        </Space>
      </Modal>
    </>
  );
};

export default JobCard;
