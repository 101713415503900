import React, { FC, Fragment, useState } from "react";
import "./CookieWarn.css"; // Importing the styles

interface CookieWarnProps {}

const CookieWarn: FC<CookieWarnProps> = () => {
  const [accepted, setAccepted] = useState(() => {
    const raw = localStorage.getItem("@cookie_accepted");
    return raw ? !!JSON.parse(raw) : false;
  });
  const [showModal, setShowModal] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
    functional: false,
  });

  const acceptAll = () => {
    setCookiePreferences({
      necessary: true,
      analytics: true,
      marketing: true,
      functional: true,
    });
    setAccepted(true);
    localStorage.setItem("@cookie_accepted", "true");
    localStorage.setItem("@cookie_preferences", JSON.stringify(cookiePreferences));
  };

  const blockAll = () => {
    setCookiePreferences({
      necessary: true,
      analytics: false,
      marketing: false,
      functional: false,
    });
    setAccepted(true);
    localStorage.setItem("@cookie_accepted", "false");
    localStorage.setItem("@cookie_preferences", JSON.stringify(cookiePreferences));
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handlePreferenceChange = (type: string) => {
    setCookiePreferences((prevPreferences:any) => ({
      ...prevPreferences,
      [type]: !prevPreferences[type],
    }));
  };

  const savePreferences = () => {
    setAccepted(true);
    localStorage.setItem("@cookie_accepted", "true");
    localStorage.setItem("@cookie_preferences", JSON.stringify(cookiePreferences));
    closeModal();
  };

  return (
    <Fragment>
      {!accepted && (
        <div className="cookie-backdrop">
          <div className="cookie-container">
            <div className="cookie-content">
              <h2 className="cookie-heading"><i className="fas fa-shield-alt"></i> We use website cookies</h2>
              <p className="cookie-text">
                We use cookies to ensure that we give you the best experience on
                our website. By continuing to use our site, you consent to the
                use of cookies.
              </p>
            </div>
            <div className="cookie-actions">
              <button className="cookie-button accept-all" onClick={acceptAll}>
                Accept All
              </button>
              <button className="cookie-button block-all" onClick={blockAll}>
                Block All
              </button>
              <button className="cookie-button selected-cookies" onClick={openModal}>
                Selected Cookies
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal-backdrop">
          <div className="modal-container">
            <h3 className="modal-heading">Manage Cookie Preferences</h3>
            <div className="modal-content">
              <label>
                <input
                  type="checkbox"
                  checked={cookiePreferences.necessary}
                  onChange={() => handlePreferenceChange("necessary")}
                  disabled
                />{" "}
                Necessary Cookies (Always Enabled)
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={cookiePreferences.analytics}
                  onChange={() => handlePreferenceChange("analytics")}
                />{" "}
                Analytics Cookies
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={cookiePreferences.marketing}
                  onChange={() => handlePreferenceChange("marketing")}
                />{" "}
                Marketing Cookies
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={cookiePreferences.functional}
                  onChange={() => handlePreferenceChange("functional")}
                />{" "}
                Functional Cookies
              </label>
            </div>
            <div className="modal-actions">
              <button className="modal-button" onClick={savePreferences}>
                Save Preferences
              </button>
              <button className="modal-button cancel-button" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CookieWarn;
