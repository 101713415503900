import { Button, Typography } from "@mui/material";
import React, { FC } from "react";
import { useAuthentication } from "../store/providers/auth.provider";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { Link, useNavigate } from "react-router-dom";

interface LogoutProps {}

const Logout: FC<LogoutProps> = () => {
  const navigate = useNavigate();
  const signOutUser = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");

      console.log('User signed out successfully');
      navigate("/login")
      
    } catch (error:any) {
      console.error('Error signing out:', error.message);
    }
  };
  return (
    <Link to={''} onClick={signOutUser}  >
      <Typography paddingX={2} color="secondary">
        Logout
      </Typography>
    </Link>
  );
};

export default Logout;
