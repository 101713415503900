import React from 'react';
import { Divider } from 'antd';
import { MailOutlined, PhoneOutlined, EnvironmentOutlined, LinkedinOutlined, GithubOutlined } from '@ant-design/icons';
import './CVTemplate3.css';

interface CVTemplate3Props {
  savedData?: {
    credentials?: {
      firstName?: string;
      lastName?: string;
      title?: string;
      email?: string;
      phone?: string;
      address?: string;
      linkedIn?: string;
      github?: string;
      website?: string;
      avatar?: string;
      city?: string;
      county?: string;
      postcode?: string;
    };
    workExperience?: {
      jobTitle: string;
      employer: string;
      startDate: string;
      endDate: string;
      description: string;
      currentlyWorkHere: boolean;
      city?: string;
      county?: string;
    }[];
    educationHistory?: {
      institution: string;
      qualification: string;
      fieldOfStudy?: string;
      honours?: string;
      city?: string;
      startDate: string;
      endDate?: string;
      currentlyStudyingHere?: boolean;
      description?: string;
    }[];
    skills?: string[];
    summary?: string;
    picture?: string | null;
    references?: boolean;
    languages?: {
      name: string;
      level: string;
    }[];
  };
  pageIndex: number;
  styles?: any;
}

const capitalize = (str: string | undefined) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const CVTemplate3: React.FC<CVTemplate3Props> = ({ savedData, pageIndex, styles }) => {
  const fullName = `${capitalize(savedData?.credentials?.firstName ?? '')} ${capitalize(savedData?.credentials?.lastName ?? '')}`;
  const avatarUrl = savedData?.credentials?.avatar;

  return (
    <div className="cv-template3" style={styles}>
      <div className="cv-left-column">
        <div className="cv-header3">
          {avatarUrl && <img src={avatarUrl} alt="Profile" className="cv-picture3" />}
          <div className="header-text3">
            <h1>{fullName}</h1>
            <h2>{savedData?.credentials?.title}</h2>
          </div>
        </div>

        <div className="contact-section3">
          <h2>Contact</h2>
          <div className="contact-item3">
            <MailOutlined /> <p>{savedData?.credentials?.email}</p>
          </div>
          <div className="contact-item3">
            <PhoneOutlined /> <p>{savedData?.credentials?.phone}</p>
          </div>
          <div className="contact-item3">
            <EnvironmentOutlined /> 
            <p>{[savedData?.credentials?.address, savedData?.credentials?.city, savedData?.credentials?.county, savedData?.credentials?.postcode].filter(Boolean).join(', ')}</p>
          </div>
          {savedData?.credentials?.linkedIn && (
            <div className="contact-item3">
              <LinkedinOutlined /> <p>{savedData.credentials.linkedIn}</p>
            </div>
          )}
          {savedData?.credentials?.github && (
            <div className="contact-item3">
              <GithubOutlined /> <p>{savedData.credentials.github}</p>
            </div>
          )}
        </div>

        <Divider />

        <div className="skills-section3">
          <h2>Skills</h2>
          <ul>
            {savedData?.skills?.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>

        <Divider />

        <div className="education-section3">
          <h2>Education</h2>
          {savedData?.educationHistory?.map((education, index) => (
            <div key={index} className="education-item3">
              <h3>{education.qualification}</h3>
              <p>{education.institution}</p>
              {education.fieldOfStudy && <p>{education.fieldOfStudy}</p>}
              {education.honours && <p>{education.honours}</p>}
              <p>{education.startDate} - {education.currentlyStudyingHere ? 'Present' : education.endDate}</p>
            </div>
          ))}
        </div>

        <Divider />

        <div className="languages-section3">
          <h2>Languages</h2>
          <ul>
            {savedData?.languages?.map((language, index) => (
              <li key={index}>{language.name} - {language.level}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="cv-right-column">
        <div className="about-section3">
          <h2>About Me</h2>
          <div className="about-description3" dangerouslySetInnerHTML={{ __html: savedData?.summary ?? "" }} />
        </div>

        <Divider />

        <div className="experience-section3">
          <h2>Professional Experience</h2>
          {savedData?.workExperience?.map((work, index) => (
            <div key={index} className="experience-item3">
              <h3>{work.jobTitle}</h3>
              <p>{work.employer}</p>
              <p>{work.city && work.county ? `${work.city}, ${work.county}` : ''}</p>
              <p>{work.startDate} - {work.currentlyWorkHere ? 'Present' : work.endDate}</p>
              <div className="experience-description3" dangerouslySetInnerHTML={{ __html: work.description }} />
            </div>
          ))}
        </div>

        <Divider />

        <div className="achievements-section3">
          <h2>References</h2>
          <p>{savedData?.references ? 'Available upon request' : 'Not available'}</p>
        </div>
      </div>
    </div>
  );
};

export default CVTemplate3;
