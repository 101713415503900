import React, { FC, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Row, Typography, Layout } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useQuery } from "@apollo/client";
import client from "../../applo"; // Adjust path as necessary
import { GET_CVS } from "../../query"; // Adjust path as necessary
import FindJob from "./find-job/[query]"; // Adjust path as necessary
import NewCVGenerate from "./cv-gen/newJobsCv"; // Adjust path as necessary
import { getCVTemplate } from "../../services/profile-service"; // Adjust path as necessary
import { useSnackbar } from "../../store/providers/snackbar.provider"; // Adjust path as necessary
import translateText from "../../constants/translator"; // Adjust path as necessary
import "./HomePage.css"; // Ensure this imports your CSS file

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const HomePage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CVS, { client });
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleTranslate = async () => {
      const textsToTranslate = [
        'Why is it essential to have a good CV?',
        'How to Apply for a Job',
        'Choose a template for your CV',
        'You can always change your template later',
        'Build your CV'
      ];
      const translations = await translateText(textsToTranslate, localStorage.getItem('lanCode') || 'es');
      setTranslatedTexts(translations);
    };
    handleTranslate();
  }, []);

  const [images, setImages] = useState<any[]>([]);

  useEffect(() => {
    const fetchImages = async () => {
      setImages([
        { path: 'cvtemp1.jpeg', id: 1 },
        { path: 'cvtemp2.jpeg', id: 2 },
        { path: 'cvtemp3.jpeg', id: 3 },
        { path: 'cvtemp4.jpeg', id: 4 },
        { path: 'cvtemp5.jpeg', id: 5 },
      ]);
    };
    fetchImages();
  }, []);

  const scrollLeft = () => {
    carouselRef.current?.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    carouselRef.current?.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <>
      <FindJob />
      <div className="banner">
        <Title level={2}>{translatedTexts[1]}</Title>
      </div>
      <Row className="content2">
        <NewCVGenerate />
      </Row>
      <Row className="content5">
      
          <Title level={2} className="title-margin2">{translatedTexts[2]}</Title>
          <Paragraph className="card-paragraph">{translatedTexts[3]}</Paragraph>
          <div className="carousel-container" ref={carouselRef} style={{margin:30}}>
            <LeftOutlined className="arrow left" onClick={scrollLeft} />
            {images.map((img, index) => (
              <div key={index} className="image-container">
                <Link to={`/cv-gen/${img.id}`}>
                  <img src={getCVTemplate(img.path)} alt={img.label ?? "CV template"} draggable={false} />
                </Link>
              </div>
            ))}
            <RightOutlined className="arrow right" onClick={scrollRight} />
          </div>
          <Row style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }} justify="center">
            <Button className="button-large" onClick={() => navigate('/cv-gen/onboarding')}>
              {translatedTexts[4]}
            </Button>
          </Row>
     
      </Row>
      <Row className="content5">
        <Card className="banner-card" onClick={() => navigate('/contactUs')} style={{ backgroundImage: `url('/assets/hire.webp')` }}>
          {/* Other contents of the Card */}
        </Card>
      </Row>
    </>
  );
};

export default HomePage;
