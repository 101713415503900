import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Input, Pagination, Layout, AutoComplete, Tag } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import JobCard from "../job-cards/horizontal";
import translateText from "../../constants/translator";
import "./index.css"; // Import the external CSS file

const { Option } = AutoComplete;

interface Props {
  type: any;
  navigateLink: (query: string) => string;
  query?: string;
  url: string;
}

interface JobData {
  id: string;
  jobTitle: string;
  jobDescription: string;
  jobLocation: string;
  [key: string]: any;
}

interface FilterOption {
  value: string;
  label: string;
}

const FindUsers: FC<Props> = ({ type, query = "", navigateLink, url }) => {
  const [showFilterTags, setShowFilterTags] = useState(false);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [newQuery, setNewQuery] = useState(query);
  const [params] = useSearchParams();
  const page = params.get("page") ?? "1";
  const [newPage, setNewPage] = useState<number>(parseInt(page));
  const [jobData, setJobData] = useState<JobData[]>([]);
  const [refJobData, setRefJobData] = useState<JobData[]>([]);
  const { t } = useTranslation();
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [locationOptions, setLocationOptions] = useState<FilterOption[]>([]);
  const [jobTitleOptions, setJobTitleOptions] = useState<FilterOption[]>([]);
  const [locationValue, setLocationValue] = useState<string | undefined>(undefined);
  const [jobTitleValue, setJobTitleValue] = useState<string | undefined>(undefined);

  const navigate = useNavigate();

  const getPageSize = () => (isMobile ? 1 : 3);

  useEffect(() => {
    const fetchTranslations = async () => {
      const langCode = localStorage.getItem('lanCode') || 'en';
      const textsToTranslate = [
        'Search job',
        'Per hour',
        'Per month',
        'Per year',
        'Average base salary',
        'Location',
        'Job Titles',
        'All',
        'Opportunity'
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };
    fetchTranslations();
  }, []);

  const fetchJobs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "confirmedjobs"));
      const jobsData: JobData[] = [];
      querySnapshot.forEach((doc) => {
        jobsData.push({ id: doc.id, ...doc.data() } as JobData);
      });
      setRefJobData(jobsData);
      setJobData(jobsData.slice(0, getPageSize()));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchLocations = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const locationsData = querySnapshot.docs.map(doc => doc.data().name);
      const langCode = localStorage.getItem('lanCode') || 'en';
      const translatedLocations = await translateText(locationsData, langCode);
      setLocationOptions(translatedLocations.map((label:any, index:any) => ({ value: locationsData[index], label })));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchJobTitles = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "jobTypes"));
      const jobTitlesData = querySnapshot.docs.map(doc => doc.data().name);
      const langCode = localStorage.getItem('lanCode') || 'en';
      const translatedJobTitles = await translateText(jobTitlesData, langCode);
      setJobTitleOptions(translatedJobTitles.map((label:any, index:any) => ({ value: jobTitlesData[index], label })));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchJobs();
    fetchLocations();
    fetchJobTitles();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const onChange = (value: string) => {
    setNewQuery(value);
    if (value.length === 0) {
      setJobData(refJobData.slice(0, getPageSize()));
    } else {
      const newData = refJobData.filter((item) =>
        item.jobTitle.toLowerCase().includes(value.toLowerCase()) ||
        item.jobDescription.toLowerCase().includes(value.toLowerCase())
      );
      setJobData(newData.slice(0, getPageSize()));
    }
  };

  const handleSearch = (value: string) => {
    setNewQuery(value);
    const newData = refJobData.filter((item) =>
      item.jobTitle.toLowerCase().includes(newQuery.toLowerCase()) ||
      item.jobDescription.toLowerCase().includes(newQuery.toLowerCase())
    );
    setJobData(newData.slice(0, getPageSize()));
    setNewPage(1);
  };

  const redirectToPage = (page: number) => {
    setNewPage(page);
    const startIndex = (page - 1) * getPageSize();
    setJobData(refJobData.slice(startIndex, startIndex + getPageSize()));
  };

  const handleFilterChange = (filterType: string) => (value: string) => {
    if (value === "") {
      setJobData(refJobData.slice(0, getPageSize()));
      return;
    }
    let updatedData = refJobData;
    if (filterType === "location") {
      setLocationValue(value);
      updatedData = refJobData.filter((item) => item.jobLocation === value);
    } else if (filterType === "jobTitle") {
      setJobTitleValue(value);
      updatedData = refJobData.filter((item) => item.jobTitle === value);
    }
    setJobData(updatedData.slice(0, getPageSize()));
    setNewPage(1);  // Reset to first page when filter changes
  };

  const handleTagClick = (filterType: string) => {
    setActiveFilter(filterType === activeFilter ? null : filterType);
  };

  const resetData = () => {
    setJobData(refJobData.slice(0, getPageSize()));
    setActiveFilter(null);
    setLocationValue(undefined);
    setJobTitleValue(undefined);
    setNewPage(1);  // Reset to first page when filter changes
  };

  const renderFilterDropdown = (filterType: string) => (
    <AutoComplete
      placeholder={translatedTexts[filterType === "location" ? 5 : 6]}
      style={{ flex: 1, minWidth: '150px', border: 'none' }}
      onChange={handleFilterChange(filterType)}
      value={filterType === "location" ? locationValue : jobTitleValue}
      options={filterType === "location" ? locationOptions : jobTitleOptions}
    >
      <Input
        suffix={
          (filterType === "location" ? locationValue : jobTitleValue) ? (
            <CloseOutlined
              onClick={() => {
                if (filterType === "location") {
                  setLocationValue(undefined);
                } else {
                  setJobTitleValue(undefined);
                }
                resetData();
              }}
            />
          ) : null
        }
      />
    </AutoComplete>
  );

  return (
 
      <div className="content-container">
        <div className="filter-row">
          <div className="search-group">
            {renderFilterDropdown("location")}
            {renderFilterDropdown("jobTitle")}
            <AutoComplete
              value={newQuery}
              options={refJobData.map(job => ({ value: job.jobTitle }))}
              onChange={onChange}
              onSearch={handleSearch}
              placeholder={translatedTexts[0]}
              style={{ flex: 3, border: 'none' }}
            >
              <Input
                suffix={
                  newQuery ? (
                    <CloseOutlined
                      onClick={() => {
                        setNewQuery("");
                        setJobData(refJobData.slice(0, getPageSize()));
                      }}
                    />
                  ) : (
                    <SearchOutlined style={{ color: '#0072BF' }} />
                  )
                }
                allowClear
              />
            </AutoComplete>
          </div>
        </div>

        {isMobile && showFilterTags && (
          <div className="filter-tags-row">
            <div className="filter-tag-col">
              <Tag
                color="blue"
                onClick={() => handleTagClick("location")}
                className="filter-tag"
              >
                {translatedTexts[5]}
              </Tag>
              {activeFilter === "location" && renderFilterDropdown("location")}
            </div>
            <div className="filter-tag-col">
              <Tag
                color="blue"
                onClick={() => handleTagClick("jobTitle")}
                className="filter-tag"
              >
                {translatedTexts[6]}
              </Tag>
              {activeFilter === "jobTitle" && renderFilterDropdown("jobTitle")}
            </div>
          </div>
        )}

        <div className="job-cards-container">
          {jobData.map((card) => (
            <div
              key={card.id}
              className="job-card-col"
            >
              <JobCard card={card} />
            </div>
          ))}
        </div>

        <Pagination
          total={refJobData.length}
          pageSize={getPageSize()}
          current={newPage}
          onChange={redirectToPage}
          className="pagination"
        />
      </div>
 
  );
};

export default FindUsers;
