import React, { useState } from 'react';
import './RightPanel.css';

interface RightPanelProps {
  step: number;
}

const stepTips: { [key: number]: string[] } = {
  0: ["Provide your personal details accurately."],
  1: [
    "Use action verbs to describe your responsibilities.",
    "Quantify your achievements where possible.",
    "List your experiences in reverse chronological order."
  ],
  2: [
    "Include relevant education and training.",
    "Mention any honors or awards received.",
    "Focus on the most recent and relevant education."
  ],
  3: [
    "List your skills relevant to the job you're applying for.",
    "Include both hard and soft skills.",
    "Use bullet points to make it easy to read."
  ],
  4: [
    "Write a brief summary of your professional background.",
    "Mention your career goals and what you bring to the role.",
    "Keep it concise and impactful."
  ],
  5: [
    "Double-check all your information for accuracy.",
    "Ensure your CV is tailored to the job description.",
    "Review your CV one final time before submitting."
  ]
};

const RightPanel: React.FC<RightPanelProps> = ({ step }) => {
  const [showTip, setShowTip] = useState<boolean>(false);
  const [currentTipIndex, setCurrentTipIndex] = useState<number>(0);

  const tips = stepTips[step] || [];

  const handleToggleTip = () => {
    setShowTip(!showTip);
  };

  const handleDotClick = (index: number) => {
    setCurrentTipIndex(index);
  };

  const handleGotIt = () => {
    setShowTip(false);
  };

  return (
    <div className="right-panel">
      <div className="expert-tips" onClick={handleToggleTip}>
        <span role="img" aria-label="lightbulb">💡</span> Expert Tips
      </div>

      {showTip && tips.length > 0 && (
        <div className="tooltip">
          <div className="tooltip-content">
            <h4>Tip {currentTipIndex + 1}</h4>
            <p>{tips[currentTipIndex]}</p>
          </div>
          <div className="tooltip-footer">
            <div className="dot-container">
              {tips.map((_, index) => (
                <span 
                  key={index} 
                  className={`dot ${index === currentTipIndex ? 'active' : ''}`} 
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
            <div className="got-it" onClick={handleGotIt}>Got it!</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightPanel;
