import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Button, Typography, Space, Spin } from "antd";
import { useQuery } from "@apollo/client";
import translateText from "../../constants/translator";
import { GET_CVS } from "../../query";
import SalaryJob from "./salary-job";
import "./SalaryGuide.css"; // Import the CSS file
import client from "../../applo";

const { Content } = Layout;
const { Title } = Typography;

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const [selectedButton, setSelectedButton] = useState('salaries');
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [showSalaryJob, setShowSalaryJob] = useState(false);
  const [translatedTexts, setTranslatedTexts] = useState({ salaryGuide: '', newJobs: '' });
  const isMobile = window.innerWidth <= 600;

  const handleButtonClick = (buttonName: string) => {
    setSelectedButton(buttonName);
    if (buttonName === "salaries") {
      setShowSalaryJob(true);
    } else if (buttonName === "newJobs") {
      setShowSalaryJob(false);
      navigate("/new-jobs");
    }
  };

  useEffect(() => {
    const langCode = localStorage.getItem('lanCode') || 'en';
    const fetchTranslations = async () => {
      const translations = await translateText(['Salary Guide', 'New Jobs'], langCode);
      setTranslatedTexts({ salaryGuide: translations[0], newJobs: translations[1] });
    };
    fetchTranslations();
  }, []);

  useEffect(() => {
    if (load) {
      handleButtonClick("salaries");
      setLoad(false);
    }
  }, [load]);

  const { loading, data } = useQuery(GET_CVS, { client });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoad(false);
    }, 20000);
    return () => {
      clearTimeout(timeout);
    };
  }, [data]);

  if (loading) return <Spin size="large" className="homepage-spin" />;

  return (
    <div className={`homepage-container ${isMobile ? 'homepage-container-mobile' : ''}`}>
      {showSalaryJob && <SalaryJob />}
    </div>
  );
};

export default HomePage;
