import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Layout, Form, Input, Button, Typography, Row, Col, notification } from "antd";
import { OTP_REGEX, PASSWORD_REGEX } from "../../constants/regex";
import { DATA_USER_TYPES } from "../../constants/user-types";
import * as authService from "../../services/auth-service";
import { useSnackbar } from "../../store/providers/snackbar.provider";
import { Credentials, ErrorHelps, InputField } from "../../utils/auth-types";
import { Errors } from "../../utils/errors";
import translateText from "../../constants/translator";

const { Content } = Layout;
const { Title } = Typography;

const ResetPasswordPage: FC = () => {
  const [credentials, setCredentials] = useState<Credentials>({
    type: DATA_USER_TYPES[0].id,
  });
  const [translatedTexts, setTranslatedTexts] = useState<string[]>([]);
  const langCode = localStorage.getItem('lanCode') || 'en';
  const { addSnack } = useSnackbar();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const number = params.get("number");

  useEffect(() => {
    const fetchTranslations = async () => {
      const textsToTranslate = [
        "Reset Password",
        "Enter OTP code",
        "OTP",
        "Enter your password",
        "Password",
        "Enter your password again",
        "Confirm Password",
        "You've successfully reset your password",
        "Invalid Type",
        "Entered Number is Invalid",
        "Password need to be at least 8 characters long with a Number and a special character",
      ];
      const translations = await translateText(textsToTranslate, langCode);
      setTranslatedTexts(translations);
    };

    fetchTranslations();
  }, [langCode]);

  const updateCredentials =
    (name: string, validate: InputField["validator"]) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCredentials((prev) => ({
        ...prev,
        [name]: validate?.(value) ? value : Errors.INPUT_ERROR,
      }));
    };

  const submit = async () => {
    try {
      if (!number) throw new Error("No mobile number found");
      const response = await authService.resetPassword({
        ...credentials,
        number,
      });
      if (response.success) {
        addSnack?.({
          severity: "success",
          message: translatedTexts[7],
        });
        navigate("/login");
      }
    } catch (error: any) {
      addSnack?.({
        severity: "error",
        message: error.message,
      });
    }
  };

  return (
    <Layout style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <Content style={{ padding: '50px 20px', marginTop: 64 }}>
        <Row justify="center">
          <Col xs={24} md={12}>
            <Title level={2} style={{ textAlign: 'center', paddingTop: '90px' }}>
              {translatedTexts[0]}
            </Title>
            <Form layout="vertical" onFinish={submit}>
              {inputs.map((input1) => {
                const hasError = credentials?.[input1.name] === Errors.INPUT_ERROR;
                return (
                  <Form.Item
                    key={input1.name}
                    label={translatedTexts[inputs.indexOf(input1) * 2 + 2]}
                    validateStatus={hasError ? "error" : ""}
                    help={hasError ? translatedTexts[10] : ""}
                  >
                    <Input
                    
                      size="middle"
                      value={credentials[input1.name]}
                      
                      onChange={updateCredentials(input1.name, input1.validator)}
                      style={{ marginBottom: 20, fontSize: '16px' }}
                    />
                  </Form.Item>
                );
              })}
              <Form.Item>
                <Button type="primary" htmlType="submit" block style={{ fontSize: '16px' }}>
                  {translatedTexts[0]}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export const inputs: InputField[] = [
  {
    name: "token",
    validator: (password) => OTP_REGEX.test(password),
    props: {
      placeholder: "Enter OTP code",
      required: true,
      label: "OTP",
    },
  },
  {
    name: "password",
    validator: (password) => PASSWORD_REGEX.test(password),
    props: {
      placeholder: "Enter your password",
      type: "password",
      required: true,
      label: "Password",
    },
  },
  {
    name: "confirm_password",
    validator: (password) => PASSWORD_REGEX.test(password),
    props: {
      placeholder: "Enter your password again",
      type: "password",
      required: true,
      label: "Confirm Password",
    },
  },
];

const errorHelps: ErrorHelps = {
  type: {
    en: "Invalid Type",
  },
  number: {
    en: "Entered Number is Invalid",
  },
  password: {
    en: "Password need to be at least 8 characters long with a Number and a special character",
  },
};

export default ResetPasswordPage;
