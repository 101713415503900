import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, notification, Row, Col } from 'antd';
import { getDocs, addDoc, updateDoc, deleteDoc, doc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import translateText from '../../constants/translator';
import './SocialMedia.css'; // Import the external CSS file

const SocialMedia: React.FC = () => {
  const [socialMedia, setSocialMedia] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [translatedTexts, setTranslatedTexts] = useState<{ [key: string]: string }>({});
  const [searchText, setSearchText] = useState('');
  const [filteredSocialMedia, setFilteredSocialMedia] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);

  const langCode = localStorage.getItem('lanCode') || 'en';

  useEffect(() => {
    fetchSocialMedia();
  }, []);

  useEffect(() => {
    handleTranslate();
  }, [langCode]);

  const handleTranslate = async () => {
    const textsToTranslate = [
      'Add Social Media',
      'Edit Social Media',
      'Name',
      'Link',
      'Action',
      'Duplicate Name',
      'A social media item with this name already exists.',
      'Item deleted successfully',
      'Item updated successfully',
      'Item added successfully',
      'An error occurred while saving the item',
      'Please input the name!',
      'Please input the URL!',
      'Update',
      'Save',
      'Search...',
      'Rows per page:',
      'Edit',
      'Delete'
    ];
    const translations = await translateText(textsToTranslate, langCode);
    const keys = [
      'addSocialMedia',
      'editSocialMedia',
      'name',
      'link',
      'action',
      'duplicateName',
      'duplicateDescription',
      'itemDeleted',
      'itemUpdated',
      'itemAdded',
      'errorSaving',
      'nameRequired',
      'urlRequired',
      'update',
      'save',
      'searchPlaceholder',
      'rowsPerPage',
      'edit',
      'delete'
    ];
    const translated = keys.reduce((acc, key, index) => {
      acc[key] = translations[index];
      return acc;
    }, {} as { [key: string]: string });
    setTranslatedTexts(translated);
  };

  const fetchSocialMedia = async () => {
    const snapshot = await getDocs(collection(db, "socialMedia"));
    const list = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setSocialMedia(list);
    setFilteredSocialMedia(list);
  };

  const handleAdd = () => {
    setEditingItem(null);
    setIsModalVisible(true);
    form.resetFields();
  };

  const handleEdit = (item: any) => {
    setEditingItem(item);
    setIsModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleDelete = async (itemId: string) => {
    await deleteDoc(doc(db, "socialMedia", itemId));
    notification.success({ message: translatedTexts.itemDeleted });
    fetchSocialMedia();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      // Check for duplicate names
      const existingItem = socialMedia.find(item => item.name === values.name && item.id !== (editingItem ? editingItem.id : null));
      if (existingItem) {
        notification.error({ message: translatedTexts.duplicateName, description: translatedTexts.duplicateDescription });
        return;
      }

      if (editingItem) {
        await updateDoc(doc(db, "socialMedia", editingItem.id), values);
        notification.success({ message: translatedTexts.itemUpdated });
      } else {
        await addDoc(collection(db, "socialMedia"), values);
        notification.success({ message: translatedTexts.itemAdded });
      }
      setIsModalVisible(false);
      form.resetFields();
      fetchSocialMedia();
    } catch (error) {
      notification.error({ message: translatedTexts.errorSaving });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = socialMedia.filter((item) =>
      item.name.toLowerCase().includes(value) ||
      item.link.toLowerCase().includes(value)
    );

    setFilteredSocialMedia(filteredData);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  return (
    <div style={{ width: '100%' }}>
      <Row justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Input
            placeholder={translatedTexts.searchPlaceholder}
            value={searchText}
            onChange={handleSearch}
            style={{ width: 200 }}
          />
        </Col>
        <Col>
          <Button type="default" onClick={handleAdd} style={{ backgroundColor: '#0070B0', color: '#fff' }}>
            {translatedTexts.addSocialMedia}
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={filteredSocialMedia}
        columns={[
          { title: translatedTexts.name, dataIndex: 'name', key: 'name' },
          { title: translatedTexts.link, dataIndex: 'link', key: 'link' },
          {
            title: translatedTexts.action,
            key: 'action',
            render: (_, item) => (
              <div className="action-buttons">
                <Button onClick={() => handleEdit(item)} style={{ marginRight: 8 }}>
                  {translatedTexts.edit}
                </Button>
                <Button danger onClick={() => handleDelete(item.id)}>
                  {translatedTexts.delete}
                </Button>
              </div>
            ),
          },
        ]}
        rowKey="id"
        pagination={{
          pageSize,
          showSizeChanger: true,
          onShowSizeChange: handlePageSizeChange,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        style={{ width: '100%' }}
      />

      <Modal
        title={editingItem ? translatedTexts.editSocialMedia : translatedTexts.addSocialMedia}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleSubmit}
        okText={editingItem ? translatedTexts.update : translatedTexts.save}
        okType="default"
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="name" label={translatedTexts.name} rules={[{ required: true, message: translatedTexts.nameRequired }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="link" label={translatedTexts.link} rules={[{ required: true, message: translatedTexts.urlRequired }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default SocialMedia;
