import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Upload, Row, Col, Typography, Tooltip, message } from 'antd';
import { UploadOutlined, InfoCircleOutlined, PlusCircleOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import RightPanel from './ExpertTips';
import './Step1.css'; // Import the CSS file for styling

const { Title, Paragraph } = Typography;

const Step1: React.FC = () => {
  const [form] = Form.useForm();
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: boolean }>({});
  const [fieldsStatus, setFieldsStatus] = useState<{ [key: string]: boolean }>({});
  const [additionalFields, setAdditionalFields] = useState<{ [key: string]: string }>({});
  const [image, setImage] = useState<string | null>(null);

  useEffect(() => {
    // Load data from localStorage
    const storedData = localStorage.getItem('cvData');
    
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      if (parsedData && parsedData.credentials) {
        form.setFieldsValue({
          firstName: parsedData.credentials?.firstName || '',
          lastName: parsedData.credentials?.lastName || '',
          address: parsedData.credentials?.address || '',
          city: parsedData.credentials?.city || '',
          county: parsedData.credentials?.county || '',
          postcode: parsedData.credentials?.postcode || '',
          phone: parsedData.credentials?.phone || '',
          email: parsedData.credentials?.email || '',
          ...parsedData.credentials
        });
        if (parsedData.credentials?.avatar) {
          setImage(parsedData.credentials.avatar);
        }
      } else {
        form.resetFields();  // Clear the form if cvData doesn't have credentials
        setImage(null);      // Clear the image if cvData doesn't have credentials
      }
    } else {
      form.resetFields();  // Clear the form if cvData doesn't exist
      setImage(null);      // Clear the image if cvData doesn't exist
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      form.setFieldsValue({
        firstName: updatedData.credentials?.firstName || '',
        lastName: updatedData.credentials?.lastName || '',
        address: updatedData.credentials?.address || '',
        city: updatedData.credentials?.city || '',
        county: updatedData.credentials?.county || '',
        postcode: updatedData.credentials?.postcode || '',
        phone: updatedData.credentials?.phone || '',
        email: updatedData.credentials?.email || '',
        ...updatedData.credentials
      });
      if (updatedData.credentials?.avatar) {
        setImage(updatedData.credentials.avatar);
      } else {
        setImage(null);  // Clear the image if avatar is removed
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [form]);

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const field = Object.keys(changedValues)[0];
    setFieldsStatus((prevStatus) => ({ ...prevStatus, [field]: !!allValues[field] }));

    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {} };
    cvData.credentials = { ...cvData.credentials, ...allValues };
    localStorage.setItem('cvData', JSON.stringify(cvData));

    // Dispatch a storage event to trigger listeners
    window.dispatchEvent(new Event('storage'));
  };

  const handleBlur = (fieldName: string) => {
    setTouchedFields({
      ...touchedFields,
      [fieldName]: true,
    });
  };

  const handleAddField = (fieldName: string) => {
    setAdditionalFields((prevFields) => ({ ...prevFields, [fieldName]: '' }));
  };

  const handleRemoveField = (fieldName: string) => {
    setAdditionalFields((prevFields) => {
      let newFields = { ...prevFields };
      delete newFields[fieldName];
      return newFields;
    });

    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {} };
    delete cvData.credentials[fieldName];
    localStorage.setItem('cvData', JSON.stringify(cvData));

    // Dispatch a storage event to trigger listeners
    window.dispatchEvent(new Event('storage'));
  };

  const handleAdditionalFieldChange = (fieldName: string, value: string) => {
    setAdditionalFields((prevFields) => ({ ...prevFields, [fieldName]: value }));

    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {} };
    cvData.credentials = { ...cvData.credentials, [fieldName]: value };
    localStorage.setItem('cvData', JSON.stringify(cvData));

    // Dispatch a storage event to trigger listeners
    window.dispatchEvent(new Event('storage'));
  };

  const handleImageUpload = ({ file }: { file: any }) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const imageUrl = e.target?.result as string;
      setImage(imageUrl);

      const storedData = localStorage.getItem('cvData');
      const cvData = storedData ? JSON.parse(storedData) : { credentials: {} };
      cvData.credentials.avatar = imageUrl;
      localStorage.setItem('cvData', JSON.stringify(cvData));

      // Dispatch a storage event to trigger listeners
      window.dispatchEvent(new Event('storage'));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {} };
    cvData.credentials = values;
    localStorage.setItem('cvData', JSON.stringify(cvData));
    message.success('Profile updated successfully!');

    // Dispatch a storage event to trigger listeners
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <>
      <RightPanel step={0} />
      <div className="form-header">
        <a href="#" className="go-back-link">Go Back</a>
        <Title level={3}>What's the best way for employers to contact you?</Title>
        <Paragraph>We suggest including an email and phone number</Paragraph>
      </div>
      <Form form={form} layout="vertical" className="custom-form" onValuesChange={handleFieldChange} onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={6} className="upload-photo">
            <Form.Item
              name="avatar"
              valuePropName="file"
              getValueFromEvent={(e: any) => (Array.isArray(e) ? e[0] : e.file)}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                beforeUpload={() => false}
                onChange={handleImageUpload}
                showUploadList={false}
              >
                {image ? (
                  <img src={image} alt="avatar" style={{ width: '100%' }} />
                ) : (
                  <div>
                    <UploadOutlined />
                    <div className="ant-upload-text">Upload photo</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="firstName" label="First name" className="form-item">
                  <Input
                    placeholder="e.g. John"
                    className="custom-input"
                    suffix={touchedFields.firstName ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('firstName')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="lastName" label="Surname" className="form-item">
                  <Input
                    placeholder="e.g. Doe"
                    className="custom-input"
                    suffix={touchedFields.lastName ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('lastName')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="address" label="Street address" className="form-item">
                  <Input
                    placeholder="123 Main St"
                    className="custom-input"
                    suffix={touchedFields.address ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('address')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="city" label="City/Town" className="form-item">
                  <Input
                    placeholder="Anytown"
                    className="custom-input"
                    suffix={touchedFields.city ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('city')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="county" label="County" className="form-item">
                  <Input
                    placeholder="County"
                    className="custom-input"
                    suffix={touchedFields.county ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('county')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="postcode" label="Postcode" className="form-item">
                  <Input
                    placeholder="12345"
                    className="custom-input"
                    suffix={touchedFields.postcode ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('postcode')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="phone" label="Phone" className="form-item">
                  <Input
                    placeholder="(123) 456-7890"
                    className="custom-input"
                    suffix={touchedFields.phone ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('phone')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email" label="Email address" className="form-item">
                  <Input
                    placeholder="example@gmail.com"
                    className="custom-input"
                    suffix={touchedFields.email ? <CheckCircleOutlined className="field-icon" /> : null}
                    onBlur={() => handleBlur('email')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        {Object.keys(additionalFields).map((fieldName) => (
          <Row gutter={16} key={fieldName}>
            <Col span={24}>
              <Form.Item name={fieldName} label={fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} className="form-item">
                <Input
                  placeholder={`e.g. ${fieldName}.com/in/johnsmith`}
                  value={additionalFields[fieldName]}
                  onChange={(e) => handleAdditionalFieldChange(fieldName, e.target.value)}
                  className="custom-input"
                  suffix={<DeleteOutlined onClick={() => handleRemoveField(fieldName)} />}
                  onBlur={() => handleBlur(fieldName)}
                />
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Row gutter={16}>
          <Col span={24}>
            <Title level={5}>
              Add additional information to your CV (optional) 
              <Tooltip title="You can add links to your LinkedIn profile, personal website, driving licence details, or nationality.">
                <InfoCircleOutlined />
              </Tooltip>
            </Title>
          </Col>
          <Col span={24}>
            {!additionalFields.linkedin && (
              <Button icon={<PlusCircleOutlined />} className="additional-info-button" onClick={() => handleAddField('linkedin')}>LinkedIn</Button>
            )}
            {!additionalFields.website && (
              <Button icon={<PlusCircleOutlined />} className="additional-info-button" onClick={() => handleAddField('website')}>Website</Button>
            )}
            {!additionalFields.drivingLicence && (
              <Button icon={<PlusCircleOutlined />} className="additional-info-button" onClick={() => handleAddField('drivingLicence')}>Driving licence</Button>
            )}
            {!additionalFields.nationality && (
              <Button icon={<PlusCircleOutlined />} className="additional-info-button" onClick={() => handleAddField('nationality')}>Nationality</Button>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Step1;
