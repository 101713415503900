import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Row, Col, Typography, Checkbox, Card, message } from 'antd';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles for ReactQuill
import './Step2.css';
import RightPanel from './ExpertTips';

const { Title, Paragraph } = Typography;

interface Step3Props {
  handleBack: () => void;
  navigateSubSteps: (step: number) => void;
  educationSubStep: number;
  results: string[];
}

interface Education {
  institution: string;
  qualification: string;
  fieldOfStudy: string;
  honours?: string;
  city: string;
  startDate: string;
  endDate?: string;
  currentlyStudyingHere?: boolean;
  description: string;
  grade?: string;
}

const Step3: React.FC<Step3Props> = ({ handleBack, navigateSubSteps, educationSubStep, results }) => {
  const [form] = Form.useForm();
  const [currentEducation, setCurrentEducation] = useState<Education | null>(null);
  const [editorContent, setEditorContent] = useState('');
  const [educationHistory, setEducationHistory] = useState<Education[]>([]);
  const [searchResults, setSearchResults] = useState<string[]>(results);

  useEffect(() => {
    const storedData = localStorage.getItem('cvData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const educationArray = Array.isArray(parsedData.educationHistory) ? parsedData.educationHistory : [];
      setEducationHistory(educationArray);
      setSearchResults(results);
    }

    const handleStorageChange = () => {
      const updatedData = JSON.parse(localStorage.getItem('cvData') || '{}');
      const educationArray = Array.isArray(updatedData.educationHistory) ? updatedData.educationHistory : [];
      setEducationHistory(educationArray);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [results]);

  const updateLocalStorage = (key: string, value: any) => {
    const storedData = localStorage.getItem('cvData');
    const cvData = storedData ? JSON.parse(storedData) : { credentials: {}, educationHistory: [] };
    cvData[key] = value;
    localStorage.setItem('cvData', JSON.stringify(cvData));
    window.dispatchEvent(new Event('storage'));
  };

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const updatedCurrentEducation = { ...currentEducation, ...allValues, description: editorContent };
    setCurrentEducation(updatedCurrentEducation);

    const updatedEducationHistory = [...educationHistory];
    const index = updatedEducationHistory.findIndex(
      (education) => education.institution === currentEducation?.institution && education.qualification === currentEducation?.qualification
    );
    if (index !== -1) {
      updatedEducationHistory[index] = updatedCurrentEducation;
    } else {
      updatedEducationHistory.push(updatedCurrentEducation);
    }

    setEducationHistory(updatedEducationHistory);
    updateLocalStorage('educationHistory', updatedEducationHistory);
  };

  const handleDateChange = (e: any, fieldName: string) => {
    const formattedDate = e.target.value;
    form.setFieldsValue({ [fieldName]: formattedDate });
    const updatedCurrentEducation:any = { ...currentEducation, [fieldName]: formattedDate };
    setCurrentEducation(updatedCurrentEducation);

    const updatedEducationHistory = [...educationHistory];
    const index = updatedEducationHistory.findIndex(
      (education) => education.institution === currentEducation?.institution && education.qualification === currentEducation?.qualification
    );
    if (index !== -1) {
      updatedEducationHistory[index] = updatedCurrentEducation;
    } else {
      updatedEducationHistory.push(updatedCurrentEducation);
    }

    setEducationHistory(updatedEducationHistory);
    updateLocalStorage('educationHistory', updatedEducationHistory);
  };

  const handleCurrentlyStudyingHereChange = (e: any) => {
    form.setFieldsValue({ endDate: null });
    handleFieldChange({ currentlyStudyingHere: e.target.checked }, { ...form.getFieldsValue(), currentlyStudyingHere: e.target.checked });
  };

  const handleEditorChange = (content: string) => {
    setEditorContent(content);

    if (currentEducation) {
      const updatedCurrentEducation = { ...currentEducation, description: `<ul>${content}</ul>` };
      setCurrentEducation(updatedCurrentEducation);

      const updatedEducationHistory = [...educationHistory];
      const index = updatedEducationHistory.findIndex(
        (education) => education.institution === currentEducation.institution && education.qualification === currentEducation.qualification
      );
      if (index !== -1) {
        updatedEducationHistory[index] = updatedCurrentEducation;
      } else {
        updatedEducationHistory.push(updatedCurrentEducation);
      }

      setEducationHistory(updatedEducationHistory);
      updateLocalStorage('educationHistory', updatedEducationHistory);
    }
  };

  const handleEducationSubmit = () => {
    if (!form.getFieldValue('institution') || !form.getFieldValue('qualification') || !form.getFieldValue('city') || !form.getFieldValue('startDate') || !editorContent.trim()) {
      message.error('Please fill in all required fields and add a description.');
      return;
    }

    const newEducation = {
      ...form.getFieldsValue(),
      description: `<ul>${editorContent}</ul>`,
      startDate: form.getFieldValue('startDate'),
      endDate: form.getFieldValue('endDate'),
    };

    const updatedEducationHistory = [...educationHistory];
    if (currentEducation) {
      const index = updatedEducationHistory.findIndex(
        (education) => education.institution === currentEducation.institution && education.qualification === currentEducation.qualification
      );
      if (index !== -1) {
        updatedEducationHistory[index] = newEducation;
      } else {
        updatedEducationHistory.push(newEducation);
      }
    } else {
      updatedEducationHistory.push(newEducation);
    }

    setEducationHistory(updatedEducationHistory);
    updateLocalStorage('educationHistory', updatedEducationHistory);

    setCurrentEducation(null);
    form.resetFields();
    setEditorContent('');
    navigateSubSteps(1); // Navigate to summary step after submitting education
  };

  const editEducationHistory = (index: number) => {
    const education = educationHistory[index];
    if (education) {
      form.setFieldsValue({
        ...education,
        startDate: education.startDate,
        endDate: education.currentlyStudyingHere ? null : education.endDate,
      });
      setEditorContent(education.description ? education.description.replace(/<\/?ul>/g, '') : ''); // Remove <ul> tags for editing
      setCurrentEducation(education);
      navigateSubSteps(0); // Navigate to the first sub-step
    }
  };

  const deleteEducationHistory = (index: number) => {
    const updatedEducationHistory = educationHistory.filter((_, i) => i !== index);
    setEducationHistory(updatedEducationHistory);
    updateLocalStorage('educationHistory', updatedEducationHistory);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    const filteredResults = results.filter((result) => result.toLowerCase().includes(query));
    setSearchResults(filteredResults);
  };

  const addResponsibilityToEditor = (responsibility: string) => {
    const newContent = `${editorContent}<li>${responsibility}</li>`;
    setEditorContent(newContent);
    handleEditorChange(newContent); // Call handleEditorChange with the updated content
  };

  const clearForm = () => {
    form.resetFields();
    setEditorContent('');
    setCurrentEducation(null);
  };

  return (
    <>
      {educationSubStep === 0 && (
        <>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={handleBack} className="back-button">
            Go Back
          </Button>
          <RightPanel step={2} />
          <Form form={form} layout="vertical" className="custom-form" onFinish={handleEducationSubmit} onValuesChange={handleFieldChange}>
            <Title level={4}>Tell us about your education</Title>
            <Paragraph>List universities, colleges, or institutions where you studied. If you didn't attend further education, then list your school or any other place of training, particularly if it corresponds to the position sought.</Paragraph>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="institution" label="Institution Name" className="form-item">
                  <Input
                    placeholder="e.g. University of Example"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="qualification" label="Qualification" className="form-item">
                  <Input
                    placeholder="e.g. Bachelor of Arts"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="fieldOfStudy" label="Field of Study" className="form-item">
                  <Input
                    placeholder="e.g. Economics"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="honours" label="Honours (optional)" className="form-item">
                  <Input
                    placeholder="e.g. Merit, Distinction, Honours"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" label="City/Country" className="form-item">
                  <Input
                    placeholder="e.g. Liverpool, United Kingdom"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="startDate" label="Start date" className="form-item">
                  <Input
                    type="date"
                    placeholder="e.g. Start date"
                    className="custom-input"
                    onChange={(e) => handleDateChange(e, 'startDate')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="endDate" label="End date" className="form-item">
                  <Input
                    type="date"
                    placeholder="e.g. End date"
                    className="custom-input"
                    onChange={(e) => handleDateChange(e, 'endDate')}
                    disabled={form.getFieldValue('currentlyStudyingHere')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="currentlyStudyingHere" valuePropName="checked" className="form-item">
                  <Checkbox onChange={handleCurrentlyStudyingHereChange}>
                    I am currently studying here
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <div className="fixed-section">
              <div className="fixed-section-header">
                <Title level={4}>Add a description to your education</Title>
                <Paragraph>You can highlight relevant academic achievements (courses, grades, awards) or write a short paragraph on extracurricular activities.</Paragraph>
              </div>
              <div className="fixed-section-content">
                <Row gutter={16}>
                  <Col span={12}>
                    <ReactQuill 
                      value={editorContent} 
                      onChange={handleEditorChange} 
                      className="react-quill"
                      formats={['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link']}
                      modules={{
                        toolbar: [
                          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                          [{size: []}],
                          ['bold', 'italic', 'underline', 'strike'],
                          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                          ['link'],
                        ]
                      }}
                    />
                  </Col>
                  <Col span={12} className="search-panel">
                    <div className="search-header">
                      <Title level={5}>Search by degree or keyword</Title>
                      <div className="search-input">
                        <Input
                          size="middle"
                          placeholder="Ex: Cashier"
                          onChange={handleSearch}
                          style={{ flex: 1, marginRight: 8 }}
                        />
                        <Button icon={<SearchOutlined />} />
                      </div>
                      <Paragraph className="search-tip">Check your spelling or try the popular pre-written examples below. We did not recognize www as a job title.</Paragraph>
                    </div>
                    <div className="search-results">
                      {searchResults.map((result, index) => (
                        <div key={index} className="search-result-item">
                          <Button
                            className="search-result-button"
                            icon={<PlusOutlined style={{ color: "white", marginRight: 8, backgroundColor: '#0070B8' }} />}
                            onClick={() => addResponsibilityToEditor(result)}
                          >
                            {result}
                          </Button>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            
          </Form>
        </>
      )}
      {educationSubStep === 1 && (
        <>
          <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => {
            navigateSubSteps(0);
            form.resetFields();
            setCurrentEducation(null);
          }} className="back-button">
            Go Back
          </Button>
          <RightPanel step={2} />
          <Title level={4}>Education history summary</Title>
          <Row gutter={16}>
            {educationHistory.map((education, index) => (
              <Col span={24} key={index} className="work-summary-col">
                <Card className="work-summary-card">
                  <div className="work-summary-content">
                    <div className="work-summary-header">
                      <div className="work-summary-info">
                        <span className="work-summary-index">{index + 1}</span>
                        <span className="work-summary-title">{education.qualification}, {education.institution}</span>
                        <span className="work-summary-location">{education.city} | {education.startDate} - {education.currentlyStudyingHere ? 'Present' : education.endDate}</span>
                      </div>
                      <div className="work-summary-actions">
                        <Button icon={<EditOutlined />} onClick={() => editEducationHistory(index)} />
                        <Button icon={<DeleteOutlined />} onClick={() => deleteEducationHistory(index)} />
                      </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: education.description }}></div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Button type="dashed" onClick={() => {
            navigateSubSteps(0);
            form.resetFields();
            setCurrentEducation(null);
          }}>+ Add another education</Button>
        </>
      )}
    </>
  );
};

export default Step3;
