import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FacebookFilled, InstagramFilled, LinkedinFilled, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { db } from "../../../firebase"; // Ensure your Firebase configuration is correctly set up
import translateText from "../../../constants/translator";
import "./Footer.css"; // Import the external CSS file
import { collection, getDocs } from "firebase/firestore";

const Footer: FC = () => {
  const [translatedTexts, setTranslatedTexts] = useState({
    bottomMessage: '',
    specialLinks: '',
    contactUs: '',
    home: '',
    contactUsLink: '',
    newJobs: '',
    salaryGuide: ''
  });

  const [socialLinks, setSocialLinks] = useState({
    facebook: '',
   email: '',
    phone: ''
  });

  useEffect(() => {
    const fetchTranslations = async () => {
      const language = localStorage.getItem('lanCode') || 'es';
      const translations = await translateText([
        'Jobwomen Offers Outstanding Packages For Both Candidates And Recruiters To Find Jobs And Employees In The Most Effective Way. Keep In Touch With Us To Explore Your Dream Career. © 2022 Jobwomen. All Rights Reserved.',
        'Special Links',
        'Contact Us',
        'Home',
        'Contact Us Link',
        'New Jobs',
        'Salary Guide'
      ], language);
      setTranslatedTexts({
        bottomMessage: translations[0],
        specialLinks: translations[1],
        contactUs: translations[2],
        home: translations[3],
        contactUsLink: translations[4],
        newJobs: translations[5],
        salaryGuide: translations[6]
      });
    };

    const fetchSocialLinks = async () => {
      try {
        const doc = await getDocs(collection(db,"socialMedia"));
        
      

        doc.docs.forEach((doc) => {
          setSocialLinks((prev) => ({
            ...prev,
            [doc.data().name]: doc.data().link,
          }));
        }
        );


      } catch (error) {
        console.error("Error fetching social links:", error);
      }
    };

    fetchTranslations();
    fetchSocialLinks();
  }, []);
  console.log(socialLinks)
  const links = [
    { text: translatedTexts.home, link: "/" },
    { text: translatedTexts.contactUsLink, link: "/contact-us" },
    { text: translatedTexts.newJobs, link: "/new-jobs" },
    { text: translatedTexts.salaryGuide, link: "/salary-guide" },
  ];

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <Link to="/">
            <img
              src="/assets/logo.jpg"
              alt="Jobwomen logo"
              className="footer-logo"
            />
          </Link>
          <p className="footer-text">
            {translatedTexts.bottomMessage}
          </p>
        </div>
        <div className="footer-section">
          <h2 className="footer-special-links">{translatedTexts.specialLinks}</h2>
          <ul className="footer-links">
            {links.map((link) => (
              <li key={link.link} className="footer-link-item">
                <Link to={link.link} className="footer-link">
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-section">
          <h2 className="footer-contact-us">{translatedTexts.contactUs}</h2>
          <ul className="footer-contact">
           {
              socialLinks.phone && (
                <li className="footer-contact-item">
                  <PhoneOutlined />
                  <span style={{ marginLeft: '8px' }}>{socialLinks.phone}</span>
                </li>
              )
           }
            {
              socialLinks.email && (
                <li className="footer-contact-item">
                  <MailOutlined />
                  <a href={`mailto:${socialLinks.email}`} style={{ marginLeft: '8px' }}>{socialLinks.email}</a>
                </li>
              )
            }
            {socialLinks.facebook && (
              <li className="footer-contact-item">
                <FacebookFilled />
                <a href={socialLinks.facebook} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '8px', color: '#1890ff' }}>Facebook</a>
              </li>
            )}
            
           
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
